import React from 'react';

import './Footer.scss';

const Footer : React.FC = (props) => {
    return (
        <footer className="py-5">
            <div className="container">
                <div className="row align-items-center justify-content-xl-between">
                    <div className="col-xl-6">
                        <div className="copyright text-center text-xl-left text-muted">
                            © 2019-2021 <a href="https://www.halen.io" className="font-weight-bold ml-1"
                                      target="_blank" rel="noopener noreferrer">Halen</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
