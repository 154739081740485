export const INVOICES_LIST_REQUEST = 'INVOICES_LIST_REQUEST';
export function invoicesListRequest() {
    return {
        type: INVOICES_LIST_REQUEST,
    };
}

export const INVOICES_LIST_RECEIVED = 'INVOICES_LIST_RECEIVED';
export function invoicesListReceived(payload:any) {
    return {
        type: INVOICES_LIST_RECEIVED,
        payload,
    };
}

export const INVOICES_LIST_ERROR = 'INVOICES_LIST_ERROR';
export function invoicesListError(error:string) {
    return {
        type: INVOICES_LIST_ERROR,
        payload: error
    };
}
