import NotFound from "../pages/NotFound/NotFound";
import {Statistics} from "../pages/Dashboard/Admin/Statistics";
import {Users} from "../pages/Dashboard/Admin/Users";
import {Invoices} from "../pages/Dashboard/Admin/Invoices";
import {CodesList} from "../pages/Dashboard/Admin/Codes/List";
import {Roles} from "../pages/Dashboard/Admin/Roles";
import {IngredientsList} from "../pages/Dashboard/Admin/Ingredients/List";
import {CodesSKUProfiles} from "../pages/Dashboard/Admin/Codes/CodesSKUProfiles";
import {SKUItems} from "../pages/Dashboard/Admin/Codes/SKUItems";
import NutritionistsList from "../pages/Dashboard/Admin/Nutritionists/List/NutritionistsList";
import {NutritionistProfile} from "../pages/Dashboard/Admin/Nutritionists/Profile";
import {EditIngredient} from "../pages/Dashboard/Admin/Ingredients/Edit";
import {AllergiesList} from "../pages/Dashboard/Admin/Allergies/List";
import {EditAllergy} from "../pages/Dashboard/Admin/Allergies/Edit";

const routes = [
    {
        path: '/dashboard',
        exact: true,
        component: Statistics,
    },
    {
        path: '/dashboard/nutritionists',
        exact: true,
        component: NutritionistsList,
    },
    {
        path: '/dashboard/profile',
        exact: true,
        component: NutritionistProfile,
    },
    {
        path: '/dashboard/users',
        exact: true,
        component: Users,
    },
    {
        path: '/dashboard/invoices',
        exact: true,
        component: Invoices,
    },
    {
        path: '/dashboard/codes/list',
        exact: true,
        component: CodesList,
    },
    {
        path: '/dashboard/codes/profiles',
        exact: true,
        component: CodesSKUProfiles,
    },
    {
        path: '/dashboard/codes/skuitems',
        exact: true,
        component: SKUItems,
    },
    {
        path: '/dashboard/roles',
        exact: true,
        component: Roles,
    },
    {
        path: '/dashboard/allergies/list',
        exact: true,
        component: AllergiesList,
    },
    {
        path: '/dashboard/allergies/edit',
        exact: true,
        component: EditAllergy,
    },
    {
        path: '/dashboard/ingredients/list',
        exact: true,
        component: IngredientsList,
    },
    {
        path: '/dashboard/ingredients/edit',
        exact: true,
        component: EditIngredient,
    },
    {
        path: '/dashboard/statistics',
        exact: true,
        component: Statistics,
    },
    {
        component: NotFound,
    },
];

export default routes;
