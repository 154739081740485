import {DEFAULT_API_STATE, IApiState} from "../types";
import {INVOICES_LIST_ERROR, INVOICES_LIST_RECEIVED, INVOICES_LIST_REQUEST} from "../actions/Invoices.actions";

export interface IInvoicesState extends IApiState {
}

const DEFAULT_STATE : IInvoicesState = DEFAULT_API_STATE;

const InvoicesReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        case INVOICES_LIST_REQUEST:
            return {
                ...state,
                waiting: true,
                error: '',
                success: false,
            }
        case INVOICES_LIST_RECEIVED:
            return {
                ...state,
                waiting: false,
                error: '',
                success: true,
                data: action.payload,
            }
        case INVOICES_LIST_ERROR:
            return {
                ...state,
                waiting: false,
                error: action.payload,
                success: false,
                data: null,
            }
        // Default
        default:
            return state;
    }
};

export default InvoicesReducers;