import {call, put, takeLatest } from "redux-saga/effects";
import {USERS_LIST_REQUEST, usersListError, usersListReceived} from "../actions/Users.actions";
import {getUsersListRequest} from "../../service/user";

function* doGetUsersList(): any {
    try {
        const usersResult = yield call(getUsersListRequest);

        if (usersResult.error) {
            switch (usersResult.error_code) {
                default:
                    yield put(usersListError(`${usersResult.error_code}, ${usersResult.error_message}`));
                    return;
            }
        }

        if (usersResult.data) {
            yield put(usersListReceived(usersResult.data));
            return;
        }

        yield put(usersListError('Error retrieving nutritionists'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(usersListError(error_message))
    }
}

const UsersSagas = [
    takeLatest(USERS_LIST_REQUEST, doGetUsersList),
];

export default UsersSagas;
