import {IServiceResponse} from "./models/service";
import {IUserAuth} from "./models/auth";
import Api from "./api";

export interface IAuthUserRequestParams {
    email?: string;
    user_id?: string;
    password: string;
}

export const authUserRequest = async (
    params: IAuthUserRequestParams,
): Promise<IServiceResponse<IUserAuth | null>> => {
    return await Api.doPost("/auth", {
        dashboard: true,
        ...params
    });
};

export const deauthRequest = async () : Promise<IServiceResponse<null>> => {
    return await Api.doGet("/deauth");
};
