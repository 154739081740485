import {DEFAULT_API_STATE, IApiState} from "../types";
import {
    NUTRITIONISTS_LIST_ERROR,
    NUTRITIONISTS_LIST_RECEIVED,
    NUTRITIONISTS_LIST_REQUEST
} from "../actions/Nutritionists.actions";

export interface INutritionistsState extends IApiState {
}

const DEFAULT_STATE : INutritionistsState = DEFAULT_API_STATE;

const NutritionistsReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        case NUTRITIONISTS_LIST_REQUEST:
            return {
                ...state,
                waiting: true,
                error: '',
                success: false,
            }
        case NUTRITIONISTS_LIST_RECEIVED:
            return {
                ...state,
                waiting: false,
                error: '',
                success: true,
                data: action.payload,
            }
        case NUTRITIONISTS_LIST_ERROR:
            return {
                ...state,
                waiting: false,
                error: action.payload,
                success: false,
                data: null,
            }
        // Default
        default:
            return state;
    }
};

export default NutritionistsReducers;