import {DEFAULT_API_STATE, IApiState} from "../types";
import {
    INGREDIENTS_CATEGORIES_LIST_ERROR,
    INGREDIENTS_CATEGORIES_LIST_RECEIVED,
    INGREDIENTS_CATEGORIES_LIST_REQUESTING, INGREDIENTS_LIST_ADD_ITEM,
    INGREDIENTS_LIST_ERROR,
    INGREDIENTS_LIST_RECEIVED, INGREDIENTS_LIST_REMOVE_ITEM,
    INGREDIENTS_LIST_REQUESTING, INGREDIENTS_LIST_UPDATE_ITEM
} from "../actions/Ingredients.actions";
import {IIngredient, IIngredientTranslation} from "../../service/models/ingredient";

export interface IIngredientsState {
    list: IApiState;
    categories: IApiState;
}

const DEFAULT_STATE : IIngredientsState = {
    list: {
        ...DEFAULT_API_STATE,
        data: [],
    },
    categories: {
        ...DEFAULT_API_STATE,
        data: [],
    },
};

function getTranslation(translations: IIngredientTranslation[], locale: string) {
    let result = null;
    if (translations != null) {
        let translation = translations.find((item) => item.locale === locale);
        if (translation) {
            result = translation.name;
        }
    }
    return result;
}

const IngredientsReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        // Ingredients List retrieval
        case INGREDIENTS_LIST_REQUESTING:
            return {
                ...state,
                list: {
                    ...state.list,
                    waiting: true,
                    error: '',
                    success: false,
                }
            }
        case INGREDIENTS_LIST_RECEIVED:
            return {
                ...state,
                list: {
                    waiting: false,
                    error: '',
                    success: true,
                    data: (action.payload != null) ? action.payload : []
                }
            }
        case INGREDIENTS_LIST_ERROR:
            return {
                ...state,
                list: {
                    waiting: false,
                    error: action.payload,
                    success: false,
                    data: []
                }
            }
            // ingredient list handling (add / remove)
        case INGREDIENTS_LIST_ADD_ITEM: {
            if (!action.payload || !action.payload.id) {
                return state;
            }
            let newItems = [...state.list.data, {
                ...action.payload,
                name: getTranslation(action.payload?.translations, 'es')
            }];
            return {
                ...state,
                list: {
                    ...state.list,
                    data: newItems
                }
            }
        }
        case INGREDIENTS_LIST_UPDATE_ITEM: {
            if (!action.payload || !action.payload.id) {
                return state;
            }
            let updatedItems = [];
            if (state.list.data) {
                updatedItems = state.list.data.map((i:IIngredient) => i.id === action.payload.id ? {
                    ...i,
                    ...action.payload,
                    name: getTranslation(action.payload?.translations, 'es')
                } : i);
            }
            return {
                ...state,
                list: {
                    ...state.list,
                    data: updatedItems
                }
            }
        }
        case INGREDIENTS_LIST_REMOVE_ITEM:
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                list: {
                    ...state.list,
                    data: state.list.data.filter((i:IIngredient) => i.id !== action.payload)
                }
            }
        // Ingredients Categories List retrieval
        case INGREDIENTS_CATEGORIES_LIST_REQUESTING:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    waiting: true,
                    error: '',
                    success: false,
                }
            }
        case INGREDIENTS_CATEGORIES_LIST_RECEIVED:
            return {
                ...state,
                categories: {
                    waiting: false,
                    error: '',
                    success: true,
                    data: (action.payload != null) ? action.payload : []
                }
            }
        case INGREDIENTS_CATEGORIES_LIST_ERROR:
            return {
                ...state,
                categories: {
                    waiting: false,
                    error: action.payload,
                    success: false,
                    data: []
                }
            }
        default:
            return state;
    }
};

export default IngredientsReducers;
