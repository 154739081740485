import React from 'react';
import './App.scss';

import { renderRoutes } from 'react-router-config';

import { routes } from './config';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faUnlockAlt,
  faShoppingBasket,
  faUtensils,
  faFileInvoiceDollar,
  faUser,
  faChartBar,
  faSignOutAlt,
  faUserCircle,
  faTicketAlt,
  faToggleOn,
  faCheckDouble,
  faPizzaSlice,
  faUsers,
  faArrowUp, faArrowDown, faChartPie, faPercent, faShoppingCart, faUserTag, faCartArrowDown, faTags,
    faAllergies,
} from '@fortawesome/free-solid-svg-icons'
import {faAndroid, faApple} from "@fortawesome/free-brands-svg-icons";

library.add(faEnvelope, faUnlockAlt, faShoppingBasket, faUtensils, faFileInvoiceDollar, faUser, faChartBar,
    faSignOutAlt, faUserCircle, faTicketAlt, faToggleOn, faCheckDouble, faPizzaSlice, faUsers, faArrowUp, faArrowDown, faChartPie,
    faPercent, faShoppingCart, faUserTag, faCartArrowDown, faApple, faAndroid, faTags, faAllergies)

const App : React.FC = (props) => {
  return (
    <div className="App">
        {renderRoutes(routes)}
    </div>
  );
}

export default App;
