import React, {useState} from "react";

import { Link } from 'react-router-dom';
import logo from '../../assets/img/halen_white.png';

import './Login.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";
import Footer from "../../components/Footer/Footer";
import {ProgressSpinner} from "primereact/progressspinner";
import {useDispatch, useSelector} from "react-redux";
import {loginRequest} from "../../redux/actions/Login.actions";
import {IAppState} from "../../redux/reducers";
import {Message} from "primereact/message";
import {Password} from "primereact/password";

type ILoginState = {
    email: string;
    password: string;
    remember: boolean;
}

interface LoginPageProps {
    history: any;
}

const Login: React.FC<LoginPageProps> = (props) => {
    const dispatch = useDispatch();
    const [state, setState] = useState<ILoginState>({
        email: '',
        password: '',
        remember: false,
    });

    const loginState = useSelector((state:IAppState) => state.login);

    const onLogin = (state:ILoginState) => {
        dispatch(loginRequest(state));
    }

    const handleInputChange = (ev:any) => {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            [name]: value
        });
    }

    return (
        <div className="main-content">
            <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
                <div className="container px-4">
                    <img src={logo} alt={'logo'}/>
                </div>
            </nav>
            <div className="header bg-gradient-primary-login py-7 py-lg-8">
                <div className="container">
                    <div className="header-body text-center mb-7">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-6">
                                <h1 className="text-white">Login</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                         xmlns="http://www.w3.org/2000/svg">
                        <polygon className="fill-default" points="2560 0 2560 100 0 100"/>
                    </svg>
                </div>
            </div>
            <div className="container mt--9 pb-5">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-7">
                        <div className="card bg-secondary shadow border-0">
                            <div className="card-body-login px-lg-5 py-lg-5">
                                    <div className="form-group p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-envelope"/>
                                        </span>
                                        <InputText placeholder="Email" name="email" onChange={handleInputChange} value={state.email}/>
                                    </div>
                                    <div className="form-group p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <FontAwesomeIcon icon="unlock-alt" />
                                        </span>
                                        <Password placeholder="Contraseña" name="password" onChange={handleInputChange} feedback={false} value={state.password}/>
                                    </div>
                                    <div className="custom-control-alternative custom-checkbox p-col-12">
                                        <Checkbox inputId="cbRemember" name='remember' onChange={handleInputChange} checked={state.remember}/>
                                        <label htmlFor="cbRemember" className="p-checkbox-label">Recuérdame</label>
                                    </div>
                                    <div className="text-center">
                                        <Button label="Entrar" className="p-button-raised p-button-rounded my-4" disabled={loginState.waiting} onClick={() => onLogin(state)}>
                                            {loginState.waiting && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
                                        </Button>
                                    </div>
                                {loginState.error ?
                                    <div>
                                        <Message severity="error" text={loginState.error}/>
                                    </div>
                                    : null
                                }
                                {props.history.location && props.history.location.state && props.history.location.state.expired ?
                                    <div>
                                        <Message severity="warn" text="Tu sesión ha caducado. Debes iniciarla de nuevo."/>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <Link className='text-light' to='/remember'><small>¿Olvidaste tu contraseña?</small></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
);
}

export default Login;
