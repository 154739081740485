import {put, call, select, takeLeading} from "redux-saga/effects";
import {
    INGREDIENTS_CATEGORIES_LIST_REQUEST,
    INGREDIENTS_LIST_REQUEST,
    ingredientsCategoriesListError,
    ingredientsCategoriesListReceived,
    ingredientsCategoriesListRequesting,
    ingredientsListError,
    ingredientsListReceived,
    ingredientsListRequesting
} from "../actions/Ingredients.actions";
import {IAppState} from "../reducers";
import {getIngredientListRequest, getIngredientsCategoriesListRequest} from "../../service/ingredients";

function* doGetIngredientsList(action: any): any {
    try {
        const ingredientsList = yield select((state:IAppState) => state.ingredients.list);

        // ask for list only once
        if (ingredientsList.data && ingredientsList.data.length>0) {
            return;
        }

        // signal requesting (set waiting=true... etc)
        yield put(ingredientsListRequesting());
        const ilResponse = yield call(getIngredientListRequest);
        if (ilResponse.error) {
            switch (ilResponse.error_code) {
                default:
                    yield put(ingredientsListError(`${ilResponse.error_code}, ${ilResponse.error_message}`));
                    return;
            }
        }

        yield put(ingredientsListReceived(ilResponse.data));
    } catch (error) {
        console.log("INGREDIENT LIST RETRIEVAL ERROR", error);
        const error_message = (error.message) ? error.message : error;
        yield put(ingredientsListError(error_message));
    }
}

function* doGetIngredientsCategoriesList(action: any): any {
    try {
        const ingredientsCategoriesList = yield select((state:IAppState) => state.ingredients.categories);

        // ask for list only once
        if (ingredientsCategoriesList.data && ingredientsCategoriesList.data.length>0) {
            return;
        }

        // signal requesting (set waiting=true... etc)
        yield put(ingredientsCategoriesListRequesting());
        const iclResponse = yield call(getIngredientsCategoriesListRequest);
        if (iclResponse.error) {
            switch (iclResponse.error_code) {
                default:
                    yield put(ingredientsListError(`${iclResponse.error_code}, ${iclResponse.error_message}`));
                    return;
            }
        }

        yield put(ingredientsCategoriesListReceived(iclResponse.data));
    } catch (error) {
        console.log("INGREDIENT CATEGORIES LIST RETRIEVAL ERROR", error);
        const error_message = (error.message) ? error.message : error;
        yield put(ingredientsCategoriesListError(error_message));
    }
}

const IngredientsSagas = [
    takeLeading(INGREDIENTS_LIST_REQUEST, doGetIngredientsList),
    takeLeading(INGREDIENTS_CATEGORIES_LIST_REQUEST, doGetIngredientsCategoriesList),
];

export default IngredientsSagas;
