import React, {useEffect, useRef, useState} from 'react';

import './RecipesList.scss';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../../redux/reducers";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {Message} from "primereact/message";
import {
    recipesListRemoveItem,
    recipesListRequest
} from "../../../../../redux/actions/Recipes.actions";
import {Button} from "primereact/button";
import {MultiSelect} from "primereact/multiselect";
import {Dialog} from "primereact/dialog";
import {RecipeDayMoments, RecipeSeasons} from "../../../../../service/models/recipes";
import {removeRecipeRequest} from "../../../../../service/recipes";

interface RecipesListProps {
    history: any;
}

const recipeNameTemplate = (rowData: any) => {
    return (
            <React.Fragment>
                <img className="thumb" alt={rowData.title} src={rowData.thumb} onError={(e) => e.currentTarget.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width="32" style={{verticalAlign: 'middle'}} />
                <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.title}</span>
            </React.Fragment>
    );
}

const momentTemplate = (rowData:any) => {
    if (rowData && rowData.moments) {
        let moments_list:string[]=[];
        rowData.moments.forEach((m:string) => {
            const day_moment = RecipeDayMoments.find(item => item.value === m);
            moments_list.push((day_moment !== undefined) ? day_moment.label : m);
        });
        return moments_list.join(', ');
    }
    return null;
}

const seasonTemplate = (rowData:any) => {
    if (rowData && rowData.seasons) {
        let seasons_list:string[]=[];
        rowData.seasons.forEach((s:string) => {
            const season = RecipeSeasons.find(item => item.value === s);
            seasons_list.push((season !== undefined) ? season.label : s);
        });
        return seasons_list.join(', ');
    }
    return null;
}

const dateTemplate = (rowData:any) => {
    if (rowData.created_at) {
        return moment(rowData.created_at).format('DD-MM-YYYY');
    }
    return null;
}

const elaborationTimeTemplate = (rowData:any) => {
    if (rowData.elaboration_time) {
        return rowData.elaboration_time+' min';
    }
    return null;
}

function filterStringArray(value:string[], filter:any) {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
        return true;
    }

    if (value === undefined || value === null) {
        return false;
    }

    return filter.some((v:string)=> value.indexOf(v) !== -1)
}


const RecipesList : React.FC<RecipesListProps> = (props) => {
    const dispatch = useDispatch();
    const recipesListState = useSelector((state:IAppState) => state.recipes.list);
    const [selectedMoments, setSelectedMoments] = useState(null);
    const [selectedSeasons, setSelectedSeasons] = useState(null);

    const [deleteRecipeState, setDeleteRecipeState] = useState({
        visible: false,
        waiting: false,
        name: '',
        id: -1,
    });

    const dataTableRef = useRef<DataTable>(null);

    useEffect(() => {
        dispatch(recipesListRequest());
    }, [dispatch]);

    if (recipesListState.error) {
        return (
            <div className="container-fluid">
                <Message text={recipesListState.error} severity="error" />
            </div>
        )
    }

    if (recipesListState.waiting || recipesListState.data == null) {
        return <LoadingView />;
    }

    function hideDeleteRecipeDialog() {
        setDeleteRecipeState({...deleteRecipeState, visible: false});
    }

    function deleteRecipe() {
        setDeleteRecipeState({...deleteRecipeState, waiting: true});
        removeRecipeRequest(deleteRecipeState.id).then(res => {
            setDeleteRecipeState({...deleteRecipeState, waiting: false});
            if (res.error) {
                return;
            }
            dispatch(recipesListRemoveItem(deleteRecipeState.id));
            setDeleteRecipeState({...deleteRecipeState, visible: false});
        }).catch(err => {
            setDeleteRecipeState({...deleteRecipeState, waiting: false});
        });
    }

    const deleteRecipeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRecipeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteRecipe} />
        </>
    );

    function onMomentFilterChange(value: any) {
        if (dataTableRef.current) {
            dataTableRef.current.filter(value, 'moments', 'custom');
        }
        setSelectedMoments(value);
    }

    function onSeasonFilterChange(value: any) {
        if (dataTableRef.current) {
            dataTableRef.current.filter(value, 'seasons', 'custom');
        }
        setSelectedSeasons(value);
    }

    function enumItemTemplate(option: any) {
        return (
            <div className="p-multiselect-option">
                {option.label}
            </div>
        );
    }

    function actionBodyTemplate(rowData: any, columnProps: any) {
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={() => {
                    props.history.push('./edit', {id: rowData.id});
                }}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                        disabled={deleteRecipeState.waiting}
                        onClick={() => {
                            setDeleteRecipeState({
                                ...deleteRecipeState,
                                visible: true,
                                name: rowData.title,
                                id: rowData.id,
                            });
                        }}/>
            </>
        );
    }

    const momentFilterOptions = <MultiSelect value={selectedMoments} options={RecipeDayMoments} onChange={(e) => onMomentFilterChange(e.value)}  placeholder="Todos" className="p-column-filter" />;
    const seasonFilterOptions = <MultiSelect value={selectedSeasons} options={RecipeSeasons} onChange={(e) => onSeasonFilterChange(e.value)} itemTemplate={enumItemTemplate} placeholder="Todas" className="p-column-filter" />;

    return (
        <div id="RecipesList">
            <div className="container-fluid">
                <div className="row mt-5 pb-3 align-items-center justify-content-between">
                    <span className="col-2 ml-0 pl-0 h4 mb-0 text-halen d-none d-lg-inline-block">Recetas</span>
                    <Button label="Crear receta" className="col-2 p-button-raised p-button-rounded align-self-end"
                            onClick={() => props.history.push('./edit', {isNew: true})} />
                </div>
                <div className="row">
                    <DataTable ref={dataTableRef} value={recipesListState.data} className="w-100 p-datatable-striped p-datatable-auto-layout" paginator rows={10}>
                        <Column field="title" header="Nombre" body={recipeNameTemplate} filter filterPlaceholder="Buscar por nombre" filterMatchMode="contains" sortable />
                        <Column field="created_at" header="Fecha" body={dateTemplate} sortable />
                        <Column field="moments" header="Momento del día" body={momentTemplate} filter filterElement={momentFilterOptions} filterFunction={filterStringArray} />
                        <Column field="seasons" header="Estación" body={seasonTemplate} filter filterElement={seasonFilterOptions} filterFunction={filterStringArray} />
                        <Column field="elaboration_time" header="Tiempo de preparación" body={elaborationTimeTemplate} sortable />
                        <Column body={actionBodyTemplate}/>
                    </DataTable>
                </div>

                <Dialog visible={deleteRecipeState.visible} style={{ width: '450px' }} header="Confirmar" modal footer={deleteRecipeDialogFooter} onHide={hideDeleteRecipeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {deleteRecipeState.name && <span>¿Estás seguro de borrar <b>{deleteRecipeState.name}</b>?</span>}
                        {!deleteRecipeState.name && <span>¿Estás seguro de borrar esta fila?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default RecipesList;
