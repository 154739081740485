import {IServiceResponse} from "./models/service";
import Api from "./api";
import {ICreateRecipeData, ICreateRecipeParams, IRecipe, IRecipeImageUpdate, IRecipeResponse} from "./models/recipes";

export const getRecipesListRequest = async (): Promise<IServiceResponse<IRecipeResponse[] | null>> => {
    return await Api.doGet("/dashboard/recipes");
};

export const getRecipeInfo = async(recipeId:number) : Promise<IServiceResponse<IRecipe | null>> => {
    return await Api.doGet("/recipe/"+recipeId);
}

export const createRecipeRequest = async (params: ICreateRecipeParams) : Promise<IServiceResponse<ICreateRecipeData | null>> => {
    return await Api.doPut("/recipe/new", params);
};

export const saveRecipeInfo = async(params: IRecipe) : Promise<IServiceResponse<null>> => {
    return await Api.doPost("/recipe/"+params.id+"/edit", params);
}

export const removeRecipeRequest = async (recipe_id: number) : Promise<IServiceResponse<null>> => {
    return await Api.doDelete("/recipe/"+recipe_id, {});
};

export const updateRecipeImage = async (recipe_id: number, formData: FormData) : Promise<IServiceResponse<IRecipeImageUpdate | null>> => {
    return await Api.doPost("/recipe/"+recipe_id+"/image", formData);
};