import {IServiceResponse} from "./models/service";
import Api from "./api";
import {
    ICodeItem,
    INewCodeRequest,
    INewItemResponse,
    INewSKUItem,
    INewSKUProfileItem,
    ISKUItem
} from "./models/codes";

const API_CODES_URL = '/dashboard/codes';
const API_CODES_PROFILES_URL = API_CODES_URL+'/profiles';
const API_CODES_SKUS_URL = API_CODES_URL+'/skus';


export const getCodesList = async () : Promise<IServiceResponse<ICodeItem[] | null>> => {
    return await Api.doGet(API_CODES_URL);
}

export const getCodesProfilesList = async () : Promise<IServiceResponse<any | null>> => {
    return await Api.doGet(API_CODES_PROFILES_URL);
}

export const addCodeRequest = async (params: INewCodeRequest) : Promise<IServiceResponse<null>> => {
    return await Api.doPut(API_CODES_URL, params);
};

export const removeCodeRequest = async (code: string) : Promise<IServiceResponse<null>> => {
    return await Api.doDelete(API_CODES_URL, { code });
};

export const getCodesSkuItemsList = async () : Promise<IServiceResponse<ISKUItem[] | null>> => {
    return await Api.doGet(API_CODES_SKUS_URL);
};

export const addSkuItemRequest = async (params: INewSKUItem) : Promise<IServiceResponse<INewItemResponse | null>> => {
    return await Api.doPut(API_CODES_SKUS_URL, params);
};

export const removeSkuItemRequest = async (itemId: number) : Promise<IServiceResponse<null>> => {
    return await Api.doDelete(API_CODES_SKUS_URL+'/'+itemId, null);
};

export const addSkuProfileRequest = async (params: INewSKUProfileItem) : Promise<IServiceResponse<INewItemResponse | null>> => {
    return await Api.doPut(API_CODES_PROFILES_URL, params);
};

export const removeSkuProfileRequest = async (profileId: number) : Promise<IServiceResponse<null>> => {
    return await Api.doDelete(API_CODES_PROFILES_URL+'/'+profileId, null);
};

export const attachSkuToProfile = async (profileId: number, itemId: number) : Promise<IServiceResponse<null>> => {
    return await Api.doPut(API_CODES_PROFILES_URL+'/'+profileId+'/skus/'+itemId, null);
};

export const detachSkuFromProfile = async (profileId: number, itemId: number) : Promise<IServiceResponse<null>> => {
    return await Api.doDelete(API_CODES_PROFILES_URL+'/'+profileId+'/skus/'+itemId, null);
};
