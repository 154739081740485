import {DEFAULT_API_STATE, IApiState} from "../types";
import {USERS_LIST_ERROR, USERS_LIST_RECEIVED, USERS_LIST_REQUEST} from "../actions/Users.actions";

export interface IUsersState extends IApiState {
}

const DEFAULT_STATE : IUsersState = DEFAULT_API_STATE;

const UsersReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        case USERS_LIST_REQUEST:
            return {
                ...state,
                waiting: true,
                error: '',
                success: false,
            }
        case USERS_LIST_RECEIVED:
            return {
                ...state,
                waiting: false,
                error: '',
                success: true,
                data: action.payload,
            }
        case USERS_LIST_ERROR:
            return {
                ...state,
                waiting: false,
                error: action.payload,
                success: false,
                data: null,
            }
        // Default
        default:
            return state;
    }
};

export default UsersReducers;