import {DEFAULT_API_STATE, IApiState} from "../types";
import {IAllergy, IAllergyTranslation} from "../../service/models/allergy";
import {
    ALLERGIES_LIST_ADD_ITEM,
    ALLERGIES_LIST_ERROR,
    ALLERGIES_LIST_RECEIVED,
    ALLERGIES_LIST_REMOVE_ITEM,
    ALLERGIES_LIST_REQUESTING, ALLERGIES_LIST_UPDATE_ITEM
} from "../actions/Allergies.actions";

export interface IAllergiesState extends IApiState {
}

const DEFAULT_STATE : IAllergiesState = {
    ...DEFAULT_API_STATE,
    data: []
};

function getTranslation(translations: IAllergyTranslation[], locale: string) {
    let result = null;
    if (translations != null) {
        let translation = translations.find((item) => item.locale === locale);
        if (translation) {
            result = translation.name;
        }
    }
    return result;
}

const AllergiesReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        // Allergies List retrieval
        case ALLERGIES_LIST_REQUESTING:
            return {
                ...state,
                waiting: true,
                error: '',
                success: false,
            }
        case ALLERGIES_LIST_RECEIVED:
            return {
                ...state,
                waiting: false,
                error: '',
                success: true,
                data: (action.payload != null) ? action.payload : []
            }
        case ALLERGIES_LIST_ERROR:
            return {
                ...state,
                waiting: false,
                error: action.payload,
                success: false,
                data: []
            }
        // allergies list handling (add / remove)
        case ALLERGIES_LIST_ADD_ITEM: {
            if (!action.payload || !action.payload.id) {
                return state;
            }
            let newItems = [...state.data, {
                ...action.payload,
                name: getTranslation(action.payload?.translations, 'es')
            }];
            return {
                ...state,
                data: newItems
            }
        }
        case ALLERGIES_LIST_UPDATE_ITEM: {
            if (!action.payload || !action.payload.id) {
                return state;
            }
            let updatedItems = [];
            if (state.data) {
                updatedItems = state.data.map((a:IAllergy) => a.id === action.payload.id ? {
                    ...a,
                    ...action.payload,
                    name: getTranslation(action.payload?.translations, 'es')
                } : a);
            }
            return {
                ...state,
                data: updatedItems
            }
        }
        case ALLERGIES_LIST_REMOVE_ITEM:
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                data: state.data.filter((a:IAllergy) => a.id !== action.payload)
            }
        default:
            return state;
    }
};

export default AllergiesReducers;
