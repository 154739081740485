import React, {useEffect, useRef, useState} from 'react';

import './IngredientsList.scss';
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../../redux/reducers";
import {
    ingredientsCategoriesListRequest,
    ingredientsListRemoveItem,
    ingredientsListRequest
} from "../../../../../redux/actions/Ingredients.actions";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Message} from "primereact/message";
import {MultiSelect} from "primereact/multiselect";
import {getFriendlyLabel} from "../../../../../helpers/forms";
import {IngredientCategoriesValues} from "../../../../../service/models/ingredient";
import {Dialog} from "primereact/dialog";
import {removeIngredientRequest} from "../../../../../service/ingredients";
import {Toast} from "primereact/toast";

interface IngredientsListProps {
    history: any;
}

const categoryBodyTemplate = (rowData:any) => {
    return <span>{getFriendlyLabel(IngredientCategoriesValues, rowData.base_category)}</span>;
}

const categoryItemTemplate = (category:any) => {
    return <span>{getFriendlyLabel(IngredientCategoriesValues, category)}</span>;
}

const IngredientsList : React.FC<IngredientsListProps> = (props) => {
    const dispatch = useDispatch();

    const toastRef = useRef<Toast>(null);
    const dataTableRef = useRef(null);
    const ingredientsState = useSelector((state:IAppState) => state.ingredients);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [deleteIngredientState, setDeleteIngredientState] = useState({
        visible: false,
        waiting: false,
        name: '',
        id: -1,
    });

    useEffect(() => {
        dispatch(ingredientsListRequest());
        dispatch(ingredientsCategoriesListRequest());
    }, [dispatch]);

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    function hideDeleteIngredientDialog() {
        setDeleteIngredientState({...deleteIngredientState, visible: false});
    }

    function deleteIngredient() {
        setDeleteIngredientState({...deleteIngredientState, waiting: true});
        removeIngredientRequest(deleteIngredientState.id).then(res => {
            setDeleteIngredientState({...deleteIngredientState, waiting: false});
            if (res.error) {
                showToast('Error eliminando ingrediente', res.error_message, 'error');
                return;
            }
            dispatch(ingredientsListRemoveItem(deleteIngredientState.id));
            setDeleteIngredientState({...deleteIngredientState, visible: false});
        }).catch(err => {
            setDeleteIngredientState({...deleteIngredientState, waiting: false});
        });
    }

    const deleteIngredientDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteIngredientDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteIngredient} />
        </>
    );


    function actionBodyTemplate(rowData: any, columnProps: any) {
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={() => {
                    props.history.push('./edit', {id: rowData.id});
                }}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                        disabled={deleteIngredientState.waiting}
                        onClick={() => {
                            setDeleteIngredientState({
                                ...deleteIngredientState,
                                visible: true,
                                name: rowData.title,
                                id: rowData.id,
                            });
                        }}/>
            </>
        );
    }

    if (ingredientsState.list.error) {
        return (
            <div className="container-fluid">
                <Message text={ingredientsState.list.error} severity="error" />
            </div>
        )
    }

    if (ingredientsState.categories.error) {
        return (
            <div className="container-fluid">
                <Message text={ingredientsState.categories.error} severity="error" />
            </div>
        )
    }

    if (ingredientsState.list.waiting || ingredientsState.categories.waiting) {
        return <LoadingView />;
    }

    function onCategoryFilterChange(value: any) {
        if (dataTableRef.current) {
            // @ts-ignore
            dataTableRef.current.filter(value, 'base_category', 'in');
            setSelectedCategory(value);
        }
    }

    const categoryFilter = <MultiSelect value={selectedCategory} options={ingredientsState.categories.data || []} onChange={(e) => onCategoryFilterChange(e.value)} itemTemplate={categoryItemTemplate} placeholder="Elige una categoría" className="p-column-filter" showClear />;

    return (
        <div id="Ingredients">
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">Gestión de <span className="text-halen-bold">Ingredientes</span></h3>
            </nav>
            <div className="container-fluid">
                <Toast ref={toastRef} position="top-right" />
                <div className="row pb-3 align-items-center justify-content-between">
                    <Button label="Crear ingrediente" className="col-3 offset-9 p-button-raised p-button-rounded align-self-end"
                            onClick={() => props.history.push('./edit', {isNew: true})} />
                </div>
                <div className="row tables-row">
                    <div className="col-12 h-100">
                        <DataTable ref={dataTableRef} value={ingredientsState.list.data || []} className="p-datatable-striped h-100"
                                   tableClassName="h-100"  paginator rows={10} dataKey="id" loading={ingredientsState.list.waiting || ingredientsState.categories.waiting}
                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10,20,50,100,200,500,1000]}>
                            <Column field="name" header="Nombre" filter filterPlaceholder="Buscar por nombre"
                                    filterMatchMode="contains" sortable style={{width: '60%'}}/>
                            <Column field="base_category" header="Categoría" sortable body={categoryBodyTemplate} filter
                                    filterElement={categoryFilter}  style={{width: '25%'}}/>
                            <Column body={actionBodyTemplate} style={{width: '15%'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={deleteIngredientState.visible} style={{ width: '450px' }} header="Confirmar" modal
                        footer={deleteIngredientDialogFooter} onHide={hideDeleteIngredientDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {deleteIngredientState.name && <span>¿Estás seguro de borrar <b>{deleteIngredientState.name}</b>?</span>}
                        {!deleteIngredientState.name && <span>¿Estás seguro de borrar esta fila?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default IngredientsList;
