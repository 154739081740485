export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
export function usersListRequest() {
    return {
        type: USERS_LIST_REQUEST,
    };
}

export const USERS_LIST_RECEIVED = 'USERS_LIST_RECEIVED';
export function usersListReceived(payload:any) {
    return {
        type: USERS_LIST_RECEIVED,
        payload,
    };
}

export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';
export function usersListError(error:string) {
    return {
        type: USERS_LIST_ERROR,
        payload: error
    };
}
