import Api from "./api";
import { IServiceResponse } from "./models/service";
import {INewProfile, IProfile, IProfileAvatarUpdate, IUser, IUserError} from "./models/user";

export const getUserRequest = async (): Promise<IServiceResponse<IUser | IUserError>> => {
    return await Api.doGet("/user/info");
};

export const changeUserPassword = async (original_password: string, new_password: string) : Promise<IServiceResponse<null>> => {
    return await Api.doPost('/_app/password/change', {original_password, new_password});
}

export const getUsersListRequest = async (): Promise<IServiceResponse<any>> => {
    return await Api.doGet('/dashboard/users');
}

export const getProfileRequest = async(): Promise<IServiceResponse<IProfile|null>> => {
    return await Api.doGet('/dashboard/profile');
}

export const saveProfileRequest = async (profile:IProfile) : Promise<IServiceResponse<null>> => {
    return await Api.doPost('/dashboard/profile', profile);
}

export const updateProfileAvatar = async (formData: FormData) : Promise<IServiceResponse<IProfileAvatarUpdate | null>> => {
    return await Api.doPost('/dashboard/profile/avatar', formData);
};

export const getOtherProfileRequest = async(userId: number): Promise<IServiceResponse<IProfile|null>> => {
    return await Api.doGet('/dashboard/profile/' + userId);
}

export const createOtherProfileRequest = async (profile:INewProfile) : Promise<IServiceResponse<null>> => {
    return await Api.doPut('/dashboard/profile', profile);
}

export const saveOtherProfileRequest = async (userId: number, profile:IProfile) : Promise<IServiceResponse<null>> => {
    return await Api.doPost('/dashboard/profile/' + userId, profile);
}

export const updateOtherProfileAvatar = async (userId: number, formData: FormData) : Promise<IServiceResponse<IProfileAvatarUpdate | null>> => {
    return await Api.doPost('/dashboard/profile/' + userId + '/avatar', formData);
};

export const changeOtherUserPassword = async (userId: number, new_password: string) : Promise<IServiceResponse<null>> => {
    return await Api.doPost('/dashboard/user/' + userId + '/password', {new_password});
}
