import React from 'react';

import './Statistics.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../redux/reducers";
import {Message} from "primereact/message";
import LoadingView from "../../../../components/LoadingView/LoadingView";

const Statistics : React.FC = (props) => {
    const generalStatsState = useSelector((state:IAppState) => state.stats.general);

/*    useEffect(() => {
        dispatch(statsRequest());
    }, [dispatch]);
*/

    if (generalStatsState.error) {
        return (
            <div className="container-fluid">
                <Message text={generalStatsState.error} severity="error" />
            </div>
        )
    }

    if (generalStatsState.waiting) { //|| statsState.data == null) {
        return <LoadingView />;
    }

    return (
        <>
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">Estadísticas</h3>
            </nav>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-lg-6 pl-0">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Recetas</h5>
                                        <span className="h2 font-weight-bold mb-0">0</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                            <FontAwesomeIcon icon="chart-bar" />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-success mr-2"><FontAwesomeIcon icon="arrow-up" /> 3.48%</span>
                                    <span className="text-nowrap">Desde el último mes</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 pl-1">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Estadística 2</h5>
                                        <span className="h2 font-weight-bold mb-0">2,356</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                            <FontAwesomeIcon icon="chart-pie" />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-danger mr-2"><FontAwesomeIcon icon="arrow-down" /> 3.48%</span>
                                    <span className="text-nowrap">Desde el último mes</span>
                                </p>
                            </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 pl-1">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Estadística 3</h5>
                                        <span className="h2 font-weight-bold mb-0">924</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                            <FontAwesomeIcon icon="users" />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-warning mr-2"><FontAwesomeIcon icon="arrow-down" /> 1.10%</span>
                                    <span className="text-nowrap">Desde ayer</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 pr-0">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Estadística 4</h5>
                                        <span className="h2 font-weight-bold mb-0">49,65%</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                            <FontAwesomeIcon icon="percent" />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-success mr-2"><FontAwesomeIcon icon="arrow-up" /> 12%</span>
                                    <span className="text-nowrap">Desde el último mes</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Statistics;
