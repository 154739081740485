import React from 'react';

import './FlagsDropdown.scss';
import {Dropdown} from "primereact/dropdown";
import {AvailableLanguages} from "../../helpers/locales";

export type TFlagChoice = {
    name: string;
    code: string;
}

function selectedLanguageTemplate(option:any, props:any) {
    if (option) {
        return (
            <div className="language-item language-item-value">
                <span className={`flag flag-${option.code.toLowerCase()}`} />
                <div>{option.name}</div>
            </div>
        );
    }

    return (
        <span>
                {props.placeholder}
            </span>
    );
}

function languageOptionTemplate(option:any) {
    return (
        <div className="language-item">
            <span className={`flag flag-${option.code.toLowerCase()}`} />
            <div>{option.name}</div>
        </div>
    );
}


interface Props {
    value?:TFlagChoice;
    onChange: (newValue:TFlagChoice) => void;
    optionLabel?:string;
    placeholder?:string;
    className?:any;
}

const FlagsDropdown : React.FC<Props> = (props) => {
    const label = props.optionLabel || "name";
    const placeholder = props.placeholder || "Selecciona idioma";

    return (
        <Dropdown className={props.className} value={props.value} options={AvailableLanguages}
                  onChange={(e) => props.onChange(e.value)}
                  optionLabel={label} filter showClear filterBy={label} placeholder={placeholder}
                  valueTemplate={selectedLanguageTemplate} itemTemplate={languageOptionTemplate} />
    );
}

export default FlagsDropdown;
