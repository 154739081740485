import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import { History } from 'history';
import history from './history';

import LoginReducers, {ILoginState} from "./Login.reducers";
import AllergiesReducers, {IAllergiesState} from "./Allergies.reducers";
import IngredientsReducers, {IIngredientsState} from "./Ingredients.reducers";
import NutritionistsReducers, {INutritionistsState} from "./Nutritionists.reducers";
import UsersReducers, {IUsersState} from "./Users.reducers";
import StatsReducers, {IStatsState} from "./Stats.reducers";
import InvoicesReducers, {IInvoicesState} from "./Invoices.reducers";
import ProfileReducers, {IProfileState} from "./Profile.reducers";
import RecipesReducers, {IRecipesState} from "./Recipes.reducers";
import CodesReducers, {ICodesState} from "./Codes.reducers";

export interface IAppState {
    router: any;
    login: ILoginState;
    profile: IProfileState;
    allergies: IAllergiesState;
    ingredients: IIngredientsState;
    nutritionists: INutritionistsState;
    users: IUsersState;
    stats: IStatsState;
    invoices: IInvoicesState;
    recipes: IRecipesState;
    codes: ICodesState;
}

const createRootReducer = (his: History) =>
    combineReducers<IAppState>({
        router: connectRouter(his),
        login: LoginReducers,
        profile: ProfileReducers,
        allergies: AllergiesReducers,
        ingredients: IngredientsReducers,
        nutritionists: NutritionistsReducers,
        users: UsersReducers,
        stats: StatsReducers,
        invoices: InvoicesReducers,
        recipes: RecipesReducers,
        codes: CodesReducers,
    });

const rootReducer = createRootReducer(history);
export default rootReducer;
