import {call, put, takeLatest } from "redux-saga/effects";
import {
    NUTRITIONISTS_LIST_REQUEST,
    nutritionistsListError,
    nutritionistsListReceived
} from "../actions/Nutritionists.actions";
import {getNutritionistsListRequest} from "../../service/nutritionist";

function* doGetNutritionistsList(): any {
    try {
        const nutritionistsResult = yield call(getNutritionistsListRequest);

        if (nutritionistsResult.error) {
            switch (nutritionistsResult.error_code) {
                default:
                    yield put(nutritionistsListError(`${nutritionistsResult.error_code}, ${nutritionistsResult.error_message}`));
                    return;
            }
        }

        if (nutritionistsResult.data) {
            yield put(nutritionistsListReceived(nutritionistsResult.data));
            return;
        }

        yield put(nutritionistsListError('Error retrieving nutritionists'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(nutritionistsListError(error_message))
    }
}

const NutritionistsSagas = [
    takeLatest(NUTRITIONISTS_LIST_REQUEST, doGetNutritionistsList),
];

export default NutritionistsSagas;
