/**
 * Codes List retrieval
 */
import {ICodeItem, ISKUItem} from "../../service/models/codes";

export const CODES_LIST_REQUEST = 'CODES_LIST_REQUEST';
export function codesListRequest() {
    return { type: CODES_LIST_REQUEST };
}

export const CODES_LIST_RECEIVED = 'CODES_LIST_RECEIVED';
export function codesListReceived(items: any) {
    return { type: CODES_LIST_RECEIVED, payload: items };
}

export const CODES_LIST_ERROR = 'CODES_LIST_ERROR';
export function codesListError(error: string) {
    return { type: CODES_LIST_ERROR, payload: error };
}

export const CODES_LIST_ADD = 'CODES_LIST_ADD';
export function codesListAdd(newItem: ICodeItem) {
    return { type: CODES_LIST_ADD, payload: newItem };
}

export const CODES_LIST_REMOVE = 'CODES_LIST_REMOVE';
export function codesListRemove(code: string) {
    return { type: CODES_LIST_REMOVE, payload: code };
}

export const CODES_SKU_ITEMS_LIST_REQUEST = 'CODES_SKU_ITEMS_LIST_REQUEST';
export function codesSkuItemsListRequest() {
    return { type: CODES_SKU_ITEMS_LIST_REQUEST };
}

export const CODES_SKU_ITEMS_LIST_RECEIVED = 'CODES_SKU_ITEMS_LIST_RECEIVED';
export function codesSkuItemsListReceived(items: any) {
    return { type: CODES_SKU_ITEMS_LIST_RECEIVED, payload: items };
}

export const CODES_SKU_ITEMS_LIST_ERROR = 'CODES_SKU_ITEMS_LIST_ERROR';
export function codesSkuItemsListError(error: string) {
    return { type: CODES_SKU_ITEMS_LIST_ERROR, payload: error };
}

export const CODES_SKU_ITEMS_LIST_ADD = 'CODES_SKU_ITEMS_LIST_ADD';
export function codesSkuItemsListAddItem(newItem: ISKUItem) {
    return { type: CODES_SKU_ITEMS_LIST_ADD, payload: newItem };
}

export const CODES_SKU_ITEMS_LIST_REMOVE = 'CODES_SKU_ITEMS_LIST_REMOVE';
export function codesSkuItemsListRemoveItem(itemId: number) {
    return { type: CODES_SKU_ITEMS_LIST_REMOVE, payload: itemId };
}
