import {OptionValue} from "../../helpers/forms";
import {ITranslation} from "./translations";

export interface IIngredient {
    acid_alkaline_forming: string;
    base_category: string;
    carbohydrate_starch?: number;
    carbohydrate_sugar?: number;
    cholesterol?: number;
    glucemic_index?: number;
    id?: number;
    insoluble_fiber?: number;
    iron?: number;
    kcals: number;
    main_alias: string;
    monounsaturated_fat?: number;
    name: string;
    other_nutrients?: string;
    image: string|null;
    thumb: string|null;
    polyunsaturated_fat?: number;
    potassium?: number;
    protein?: number;
    saturated_fat?: number;
    serving_size: number;
    serving_unit: string;
    sodium?: string;
    soluble_fiber?: number;
    trans_fat?: number;
    vitamin?: number;
    vitamins: any;
}

export const IngredientCategoriesValues : Array<OptionValue> = [
    {id: 'vegetable', label: 'Vegetales'},
    {id: 'fruit', label: 'Fruta'},
    {id: 'dried_fruit', label: 'Frutos secos'},
    {id: 'veg_protein', label: 'Proteína Vegetal'},
    {id: 'milk', label: 'Leche'},
    {id: 'meat', label: 'Carne'},
    {id: 'sausage', label: 'Salchichas'},
    {id: 'fish', label: 'Pescado'},
    {id: 'seafood', label: 'Marisco'},
    {id: 'sugar', label: 'Azúcar'},
    {id: 'snack', label: 'Snack'},
    {id: 'cereal', label: 'Cereales'},
    {id: 'legume', label: 'Legumbres'},
    {id: 'egg', label: 'Huevo'},
    {id: 'cake', label: 'Pastel'},
    {id: 'drink', label: 'Bebida'},
    {id: 'oil', label: 'Aceite'},
    {id: 'fat', label: 'Grasa'},
    {id: 'sauce', label: 'Salsas'},
    {id: 'condiment', label: 'Especias'},
    {id: 'water', label: 'Agua'},
    {id: 'other', label: 'Otros'},
];

export const MeasureValues : Array<OptionValue> = [
    {id: 'grams', label: 'Gramos'},
    {id: 'kilograms', label: 'Kilogramos'},
    {id: 'cup', label: 'Taza'},
    {id: 'tablespoon', label: 'Cucharada Sopera'},
    {id: 'teaspoon', label: 'Cucharadas de Café'},
    {id: 'milliliter', label: 'Mililitros'},
    {id: 'liter', label: 'Litros'},
    {id: 'leaf', label: 'Hojas'},
    {id: 'pinch', label: 'Pizca'},
    {id: 'units', label: 'Unidades'},
];

export const AcidAlkalineValues: Array<OptionValue> = [
    {id: 'neutral', label: 'Neutral'},
    {id: 'acid', label: 'Ácido'},
    {id: 'alkaline', label: 'Alcalino'},
]

export interface IIngredientTranslation extends ITranslation {
}

export interface IIngredientUnitConversion {
    from: string;
    to: string;
    factor: number;
}

export interface IDashboardIngredient extends IIngredient {
    translations: IIngredientTranslation[],
    preferred_display_unit?: string|null;
}

export interface ICreateIngredientData {
    id: number;
}

export interface ICreateIngredientParams extends IDashboardIngredient {
}
