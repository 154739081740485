export interface IApiState {
    waiting: boolean;
    error: string;
    success: boolean;
    data: any;
}

export const DEFAULT_API_STATE : IApiState = {
    waiting: false,
    error: '',
    success: false,
    data: null
}
