import * as React from 'react';
import {PropsWithChildren, useEffect} from 'react';
import {renderRoutes} from 'react-router-config';
import {adminRoutes, nutritionistRoutes} from "../../config";

import './Dashboard.scss';
import logo from '../../assets/img/Halen_logo.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../redux/reducers";
import {loginInitialCheck, logoutRequest} from "../../redux/actions/Login.actions";
import {LoginStatus} from "../../redux/reducers/Login.reducers";
import LoadingView from "../../components/LoadingView/LoadingView";
import {Message} from "primereact/message";

function goTo(props:PropsWithChildren<DashboardPageProps>, path: string) {
    props.history.push('/dashboard/'+path);
}

interface DashboardPageProps {
    history: any;
}

function renderMenuByRoles(roles: string[], props:DashboardPageProps) {
    if (!roles || !roles.includes) return null;

    if (roles.includes('admin')) {
        return (
            <>
                <li className="nav-item row" onClick={() => goTo(props, 'nutritionists')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="user-circle"/></div>
                    <div className="col sidebar-title">Nutricionistas</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'users')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="user"/></div>
                    <div className="col sidebar-title">Usuarios</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'invoices')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="file-invoice-dollar"/></div>
                    <div className="col sidebar-title">Facturas</div>
                </li>
                <li className="nav-item row">
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="ticket-alt"/></div>
                    <div className="col sidebar-title">Códigos referidos</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'codes/skuitems')}>
                    <div className="col-1 nav-icon offset-1"><FontAwesomeIcon icon="cart-arrow-down"/></div>
                    <div className="col sidebar-title">SKU Items</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'codes/profiles')}>
                    <div className="col-1 nav-icon offset-1"><FontAwesomeIcon icon="tags"/></div>
                    <div className="col sidebar-title">Perfiles SKU</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'codes/list')}>
                    <div className="col-1 nav-icon offset-1"><FontAwesomeIcon icon="user-tag"/></div>
                    <div className="col sidebar-title">Códigos de usuario</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'roles')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="toggle-on"/></div>
                    <div className="col sidebar-title">Gestión de roles</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'allergies/list')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="allergies"/></div>
                    <div className="col sidebar-title">Gestión de alergias</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'ingredients/list')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="pizza-slice"/></div>
                    <div className="col sidebar-title">Gestión de ingredientes</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'statistics')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="chart-bar"/></div>
                    <div className="col sidebar-title">Estadísticas</div>
                </li>
              </>
        )
    }

    if (roles.includes('nutritionist')) {
        return (
            <>
                <li className="nav-item row" onClick={() => goTo(props, 'recipes/list')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="shopping-basket"/></div>
                    <div className="col">Recetas</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'invoices')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="file-invoice-dollar"/></div>
                    <div className="col">Facturas</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'profile')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="user"/></div>
                    <div className="col">Perfil</div>
                </li>
                <li className="nav-item row" onClick={() => goTo(props, 'statistics')}>
                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="chart-bar"/></div>
                    <div className="col">Estadísticas</div>
                </li>
            </>
        )
    }
}

function renderRoutesByRoles(roles: string[], props:DashboardPageProps) {
    if (roles.includes('admin')) {
        return renderRoutes(adminRoutes);
    }

    if (roles.includes('nutritionist')) {
        return renderRoutes(nutritionistRoutes);
    }

    return null;
}

const Dashboard : React.FC<DashboardPageProps> = (props) => {
    const dispatch = useDispatch();
    const loginState = useSelector((state:IAppState) => state.login);

    useEffect(() => {
        if (loginState.status === LoginStatus.UNKNOWN) {
            dispatch(loginInitialCheck());
        }
    }, [dispatch, loginState.status]);

    if (loginState.error) {
        return (
            <div className="container-fluid">
                <Message text={loginState.error} severity="error" />
            </div>
        )
    }

    if (loginState.waiting || loginState.status !== LoginStatus.LOGGED_IN) {
        return <LoadingView />;
    }

    return (
        <div id="Dashboard">
            <div className="sidebar-box">
                <div className="sidebar-container">
                    <div className="sidebar sidebar-left sidebar-content">
                        <div className="text-center">
                            <img src={logo} className="logo" alt="logo"/>
                        </div>
                        <div style={{height: '100%'}}>
                            <ul className="navbar-nav">
                                {renderMenuByRoles(loginState.roles, props)}
                                <li className="nav-item row" onClick={() => dispatch(logoutRequest())}>
                                    <div className="col-1 nav-icon"><FontAwesomeIcon icon="sign-out-alt"/></div>
                                    <div className="col">Salir</div>
                                </li>
                            </ul>
                        </div>
                        <div className="copyright text-center text-xl-left text-muted">
                            © 2019-2021 <a href="https://www.halen.io" className="font-weight-bold ml-1"
                                           target="_blank" rel="noopener noreferrer">Halen</a>
                        </div>
                    </div>
                </div>
            </div>
            <main className="main-content">
                {renderRoutesByRoles(loginState.roles, props)}
            </main>
        </div>
    );
}

export default Dashboard;
