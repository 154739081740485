import React, {useEffect, useRef} from 'react';

import './NutritionistsList.scss';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../../redux/reducers";
import {nutritionistsListRequest} from "../../../../../redux/actions/Nutritionists.actions";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {Message} from "primereact/message";
import {Button} from "primereact/button";

interface NutritionistsProps {
    history: any;
}

const nutritionistNameTemplate = (rowData: any) => {
    return (
        <React.Fragment>
            {rowData.avatar ?
            <img className="avatar" alt={rowData.name} src={rowData.avatar} onError={(e) => e.currentTarget.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width="32" style={{verticalAlign: 'middle'}} />
                : <i className="avatar pi pi-user"></i>}
            <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.name}</span>
        </React.Fragment>
    );
}

const NutritionistsList: React.FC<NutritionistsProps> = (props) => {
    const dispatch = useDispatch();
    const nutritionistsState = useSelector((state:IAppState) => state.nutritionists);

    const dataTableRef = useRef(null);

    useEffect(() => {
        dispatch(nutritionistsListRequest());
    }, [dispatch]);

    if (nutritionistsState.error) {
        return (
            <div className="container-fluid">
                <Message text={nutritionistsState.error} severity="error" />
            </div>
        )
    }

    if (nutritionistsState.waiting || nutritionistsState.data == null) {
        return <LoadingView />;
    }

    function actionBodyTemplate(rowData: any, columnProps: any) {
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={() => {
                    props.history.push('./profile', {userId: rowData.id});
                }}/>
            </>
        );
    }

    return (
        <div id="Nutritionists">
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">Nutricionistas</h3>
            </nav>
            <div className="container-fluid">
                <div className="row pb-3 align-items-center justify-content-end">
                    <Button label="Crear nutricionista" className="col-3 p-button-raised p-button-rounded align-self-end"
                            onClick={() => props.history.push('./profile', {userId: undefined})} />
                </div>
                <div className="row nutritionist-list-row">
                    <DataTable ref={dataTableRef} value={nutritionistsState.data} className=" w-100 p-datatable-striped p-datatable-auto-layout" paginator rows={10}>
                        <Column field="name" header="Nombre" body={nutritionistNameTemplate} filter filterPlaceholder="Buscar por nombre" filterMatchMode="contains" sortable/>
                        <Column field="joined_at" header="Fecha de incorporación" sortable/>
                        <Column field="email" header="Dirección de email" filter filterPlaceholder="Buscar por email" filterMatchMode="contains" sortable/>
                        <Column field="country" header="País" filter filterPlaceholder="Buscar por país"
                                filterMatchMode="contains" sortable/>
                        <Column field="scoring" header="Scoring" sortable/>
                        <Column body={actionBodyTemplate}/>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default NutritionistsList;
