export const STATS_REQUEST = 'STATS_REQUEST';
export function statsRequest() {
    return {
        type: STATS_REQUEST,
    };
}

export const STATS_SUCCESS = 'STATS_SUCCESS';
export function statsSuccess(stats:any) {
    return {
        type: STATS_SUCCESS,
        payload: stats
    };
}

export const STATS_ERROR = 'STATS_ERROR';
export function statsError(error:string) {
    return {
        type: STATS_ERROR,
        payload: error
    };
}

// Profile Stats
export const STATS_PROFILE_REQUEST = 'STATS_PROFILE_REQUEST';
export function statsProfileRequest() {
    return {
        type: STATS_PROFILE_REQUEST,
    };
}

export const STATS_PROFILE_SUCCESS = 'STATS_PROFILE_SUCCESS';
export function statsProfileSuccess(stats:any) {
    return {
        type: STATS_PROFILE_SUCCESS,
        payload: stats
    };
}

export const STATS_PROFILE_ERROR = 'STATS_PROFILE_ERROR';
export function statsProfileError(error:string) {
    return {
        type: STATS_PROFILE_ERROR,
        payload: error
    };
}
