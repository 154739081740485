import {IServiceResponse} from "./models/service";
import Api from "./api";
import {ICreateIngredientData, ICreateIngredientParams, IDashboardIngredient, IIngredient} from "./models/ingredient";

export const getIngredientListRequest = async () : Promise<IServiceResponse<IIngredient[] | null>> => {
    return await Api.doGet("/dashboard/ingredients");
};

export const getIngredientsCategoriesListRequest = async () : Promise<IServiceResponse<string[] | null>> => {
    return await Api.doGet("/ingredient/categories");
};

export const createIngredientRequest = async (params: ICreateIngredientParams) : Promise<IServiceResponse<ICreateIngredientData | null>> => {
    return await Api.doPut("/dashboard/ingredients", params);
};

export const getIngredientInfo = async(ingredient_id:number) : Promise<IServiceResponse<IDashboardIngredient | null>> => {
    return await Api.doGet("/dashboard/ingredients/"+ingredient_id);
}

export const saveIngredientInfo = async(params: IDashboardIngredient) : Promise<IServiceResponse<null>> => {
    return await Api.doPost("/dashboard/ingredients/"+params.id, params);
}

export const removeIngredientRequest = async (ingredient_id: number) : Promise<IServiceResponse<null>> => {
    return await Api.doDelete("/dashboard/ingredients/"+ingredient_id, {});
};
