import {IAllergy} from "../../service/models/allergy";

/**
 * Allergies List retrieval
 */

export const ALLERGIES_LIST_REQUEST = 'ALLERGIES_LIST_REQUEST';
export function allergiesListRequest() {
    return { type: ALLERGIES_LIST_REQUEST };
}

export const ALLERGIES_LIST_REQUESTING = 'ALLERGIES_LIST_REQUESTING';
export function allergiesListRequesting() {
    return { type: ALLERGIES_LIST_REQUESTING };
}

export const ALLERGIES_LIST_RECEIVED = 'ALLERGIES_LIST_RECEIVED';
export function allergiesListReceived(allergies: IAllergy[]) {
    return { type: ALLERGIES_LIST_RECEIVED, payload: allergies };
}

export const ALLERGIES_LIST_ERROR = 'ALLERGIES_LIST_ERROR';
export function allergiesListError(error: string) {
    return { type: ALLERGIES_LIST_ERROR, payload: error };
}

// Add Allergy to list directly
export const ALLERGIES_LIST_ADD_ITEM = 'ALLERGIES_LIST_ADD_ITEM';
export function allergiesListAddItem(payload:IAllergy) {
    return {
        type: ALLERGIES_LIST_ADD_ITEM,
        payload
    }
}

// Update Allergy in List
export const ALLERGIES_LIST_UPDATE_ITEM = 'ALLERGIES_LIST_UPDATE_ITEM';
export function allergiesListUpdateItem(payload:IAllergy) {
    return {
        type: ALLERGIES_LIST_UPDATE_ITEM,
        payload
    }
}

// Delete Allergy from List
export const ALLERGIES_LIST_REMOVE_ITEM = 'ALLERGIES_LIST_REMOVE_ITEM';
export function allergiesListRemoveItem(allergy_id:number) {
    return {
        type: ALLERGIES_LIST_REMOVE_ITEM,
        payload: allergy_id
    }
}
