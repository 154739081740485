import React from 'react';

import './LoadingView.scss';
import {ProgressSpinner} from "primereact/progressspinner";

const LoadingView : React.FC = (props) => {
    return (
        <div className="loading-container">
            <ProgressSpinner />
        </div>
    );
}

export default LoadingView;
