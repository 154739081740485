import {call, put, takeLatest } from "redux-saga/effects";
import {PROFILE_REQUEST, profileError, profileSuccess} from "../actions/Profile.actions";
import {getProfileRequest} from "../../service/user";

function* doGetProfile(): any {
    try {
        const profileResult = yield call(getProfileRequest);

        if (profileResult.error) {
            switch (profileResult.error_code) {
                default:
                    yield put(profileError(`${profileResult.error_code}, ${profileResult.error_message}`));
                    return;
            }
        }

        if (profileResult.data) {
            yield put(profileSuccess(profileResult.data));
            return;
        }

        yield put(profileError('Error retrieving profile'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(profileError(error_message))
    }
}

const ProfileSagas = [
    takeLatest(PROFILE_REQUEST, doGetProfile),
];

export default ProfileSagas;
