export interface IRecipeResponse {
    id: number;
    created_at: string;
    description: string;
    thumb: string;
}

export const RecipeDayMoments = [
    {label: 'Desayuno', value: 'breakfast'},
    {label: 'Comida', value: 'lunch'},
    {label: 'Cena', value: 'dinner'},
];

export const RecipeSeasons = [
    {label: 'Primavera', value: 'spring'},
    {label: 'Verano', value: 'summer'},
    {label: 'Otoño', value: 'autumn'},
    {label: 'Invierno', value: 'winter'},
]

export type IRecipeIngredient = {
    id: number;
    name: string;
    quantity: number;
    measure: string;
}

export type IStep = {
    id: number;
    text: string;
};

export type IRecipeNutritionalInfo = {
    kcals: number;
    protein: number;
    carbohydrate_starch: number;
    carbohydrate_sugar: number;
    fiber: number;
    monounsaturated_fat: number;
    polyunsaturated_fat: number;
    saturated_fat: number;
    trans_fat: number;
    cholesterol: number;
    iron: number;
    sodium: number;
    potassium: number;
};

export interface IRecipeCommonAttributes {
    title: string;
    description: string;
    image?: string;
    thumb?: string;
    moments: string[];
    seasons: string[];
    elaboration_time: number;
    people: number;
    ingredients: IRecipeIngredient[];
    steps: IStep[];
    nutrition_facts?: IRecipeNutritionalInfo;
}

export interface IRecipe extends IRecipeCommonAttributes {
    id: number;
}

export interface ICreateRecipeData {
    id: number;
    nutrition_facts?: IRecipeNutritionalInfo;
}

export interface ICreateRecipeParams extends  IRecipeCommonAttributes {
    language: string;
}

export interface IRecipeImageUpdate {
    image?: string;
    thumb?: string;
}
