import React, {useEffect, useRef, useState} from 'react';
import './SKUItems.scss';
import {useDispatch, useSelector} from "react-redux";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {IAppState} from "../../../../../redux/reducers";
import {
    codesSkuItemsListAddItem,
    codesSkuItemsListRemoveItem,
    codesSkuItemsListRequest
} from "../../../../../redux/actions/Codes.actions";
import {Message} from "primereact/message";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {INewSKUItem, SKUStores} from "../../../../../service/models/codes";
import useTDPForm from "../../../../../hooks/tdp-form/tdp-form";
import {ITDPFormValidations} from "../../../../../hooks/tdp-form/tdp-form-types";
import {RequiredValidation} from "../../../../../hooks/tdp-form/tdp-form-validations";
import {ProgressSpinner} from "primereact/progressspinner";
import {addSkuItemRequest, removeSkuItemRequest} from "../../../../../service/codes";
import {Toast} from "primereact/toast";
import { Dialog } from 'primereact/dialog';
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
// @ts-ignore
import {confirmDialog} from "primereact/confirmdialog";
import {storeItemTemplate} from "../CodesTemplateUtils";

const formInitialValues: INewSKUItem = {
    store: '',
    sku: '',
    description: '',
}

const formValidations: ITDPFormValidations<INewSKUItem> = {
    store: [RequiredValidation({errorText: 'El store es obligatorio'})],
    sku: [RequiredValidation({errorText: 'El código SKU es obligatorio'})],
    description: [],
};

function storeBodyTemplate(rowData: any) {
    if (rowData !== undefined && rowData.store) {
        const item = SKUStores.find(item => item.value === rowData.store);
        return storeItemTemplate(item);
    }
    return null;
}


const SKUItems : React.FC = (props) => {
    const dispatch = useDispatch();
    const itemsListState = useSelector((state:IAppState) => state.codes.itemslist);

    const [saving, setSaving] = useState<boolean>(false);

    const [newItemState, setNewItemState] = useState({
        ...formInitialValues,
        visible: false,
    });

    const toastRef = useRef<Toast>(null);

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    const onSubmit = async (submittedValues:INewSKUItem) => {
        const toastTitle = 'Error guardando item';

        setSaving(true);

        try {
            const saveItemResponse = await addSkuItemRequest({
                ...submittedValues,
            });

            setSaving(false);

            if (saveItemResponse.error) {
                showToast(toastTitle, saveItemResponse.error_message, 'error');
                return;
            }

            const itemId = saveItemResponse.data?.id;
            if (!itemId) {
                showToast(toastTitle, 'Error obteniendo el nuevo item', 'error');
                return;
            }

            showToast('Guardar SKU Item', 'OK!', 'success');
            dispatch(codesSkuItemsListAddItem({
                ...submittedValues,
                id: itemId
            }));

            form.resetValues(formInitialValues);
            hideNewItemDialog();
        } catch (err) {
            setSaving(false);
            showToast(toastTitle, err.message,'error');
        }
    }

    const form = useTDPForm(formInitialValues, formValidations, onSubmit);

    useEffect(() => {
        dispatch(codesSkuItemsListRequest());
    }, [dispatch]);

    if (itemsListState.error) {
        return (
            <div className="container-fluid">
                <Message text={itemsListState.error} severity="error" />
            </div>
        )
    }

    if (itemsListState.waiting || itemsListState.data == null) {
        return <LoadingView />;
    }

    function hideNewItemDialog() {
        setNewItemState({...newItemState, visible: false});
    }

    const newItemDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideNewItemDialog} />
            <Button label="Guardar" icon="pi pi-save" className="p-button-text" disabled={saving} onClick={form.handleSubmit}>
                    {saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
            </Button>
        </>
    );

    const actionHeaderTemplate = () => {
        return (
            <Button icon="pi pi-plus" className="p-button-rounded p-button-success" onClick={() => setNewItemState({...newItemState, visible: true})} />
        );
    }

    async function removeSku(id: any) {
        const toastTitle = 'Error borrando item';
        try {
            const removeResponse = await removeSkuItemRequest(id);
            if (!removeResponse || removeResponse.error) {
                showToast(toastTitle, removeResponse.error_message, 'error');
                return;
            }

            showToast('Eliminar SKU Item', 'OK!', 'success');
            dispatch(codesSkuItemsListRemoveItem(id));
        } catch (err) {
            showToast(toastTitle, err.message,'error');
        }
    }

    function actionBodyTemplate(rowData:any, props:any) {
        return <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
            confirmDialog({
                message: '¿Estás seguro de eliminar el SKU "'+rowData.sku+'" de "'+rowData.store+'"? Recuerda que desaparecerá de todos los perfiles donde esté asociado y no se ofrecerá más a los clientes.',
                // @ts-ignore
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                style: {maxWidth: '500px'},
                accept: () => removeSku(rowData.id),
            });
        }} />
    }

    return (
        <div id="sku_items">
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">SKU <span className="text-halen-bold">Items</span></h3>
            </nav>
            <div className="container-fluid">
                <Toast ref={toastRef} position="top-right" />
                <div className="row tables-row">
                    <div className="col-12 h-100">
                        <DataTable className="h-100" tableClassName="h-100" paginator rows={20}
                                   value={itemsListState.data} dataKey="id">
                            <Column field="store" header="Store" filter filterPlaceholder="Buscar por store" filterMatchMode="contains" body={storeBodyTemplate} style={{width:'20%'}}/>
                            <Column field="sku" header="SKU" filter filterPlaceholder="Buscar por SKU" filterMatchMode="contains" style={{width: '30%'}}/>
                            <Column field="description" header="Descripción" filter filterPlaceholder="Buscar por descripción" filterMatchMode="contains" style={{width: '40%'}}/>
                            <Column body={actionBodyTemplate} header={actionHeaderTemplate()} style={{width: '10%'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={newItemState.visible} style={{ width: '450px' }} header="Añadir nuevo item SKU" modal footer={newItemDialogFooter} onHide={hideNewItemDialog}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-control-label" htmlFor="input-name">Store</label>
                                    <Dropdown value={form.values.store}
                                              className="form-control-alternative w-100"
                                              options={SKUStores} optionLabel="label" optionValue="value"
                                              onChange={(e) =>
                                                  form.handleChange('store', e.value)
                                              } placeholder="Escoge una tienda"
                                              itemTemplate={(option) => {
                                                  return storeItemTemplate(option)
                                              }}
                                    />

                                    {form.errors.store.error ?
                                        <small className="p-invalid">{form.errors.store.errorText}</small>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-control-label" htmlFor="input-name">Código SKU</label>
                                    <InputText className="form-control form-control-alternative"
                                               placeholder="SKU" value={form.values.sku}
                                               onChange={(e) =>
                                                   form.handleChange('sku', e.currentTarget.value)
                                               }/>
                                    {form.errors.sku.error ?
                                        <small className="p-invalid">{form.errors.sku.errorText}</small>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-control-label" htmlFor="input-name">Descripción (* opcional)</label>
                                    <InputText className="form-control form-control-alternative"
                                               placeholder="Descripción interna del SKU" value={form.values.description}
                                               onChange={(e) =>
                                                   form.handleChange('description', e.currentTarget.value)
                                               }/>
                                    {form.errors.description.error ?
                                        <small className="p-invalid">{form.errors.description.errorText}</small>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default SKUItems;
