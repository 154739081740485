import {
    // Login
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    // Logout
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR, LOGIN_INITIAL_CHECK
} from '../actions/Login.actions';

export type LoginBody = {
    email: string;
    password: string;
};

export interface TokenInfo {
    access_token: string;
    refresh_token?: string;
}

export enum LoginStatus {
    UNKNOWN,
    LOGGING_IN,
    LOGGED_IN,
    LOGGED_OUT,
}

export interface ILoginState {
    waiting: boolean;
    error: string;
    status: LoginStatus;
    roles:string[];
}

const DEFAULT_STATE : ILoginState = {
    waiting: false,
    error: '',
    status: LoginStatus.UNKNOWN,
    roles: [],
}

const LoginReducers = (
    state:ILoginState = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                waiting: true,
                error: '',
                status: LoginStatus.LOGGING_IN,
                roles: [],
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                waiting: false,
                error: '',
                status: LoginStatus.LOGGED_IN,
                roles: action.payload,
            }
        case LOGIN_ERROR:
            return {
                ...state,
                waiting: false,
                status: LoginStatus.LOGGED_OUT,
                error: action.payload,
            }
        case LOGIN_INITIAL_CHECK:
            return {
                ...state,
                waiting: true,
                error: '',
                status: LoginStatus.LOGGING_IN,
                roles: [],
            }
        case LOGOUT_REQUEST:
            return {
                ...state,
                waiting: true,
                error: '',
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                waiting: false,
                error: '',
                status: LoginStatus.LOGGED_OUT,
                roles: [],
            }
        case LOGOUT_ERROR:
            return {
                ...state,
                waiting: false,
                error: action.payload,
            }
        // Default
        default:
            return state;
    }
};

export default LoginReducers;