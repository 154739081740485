import Api, {ApiErrors} from '../../service/api';

import { put, all, call, takeLatest } from 'redux-saga/effects';
import {
    LOGIN_REQUEST,
    loginSuccess,
    loginError,
    LOGOUT_REQUEST,
    logoutSuccess,
    logoutError, LOGIN_INITIAL_CHECK, logoutRequest, LOGOUT_SUCCESS
} from '../actions/Login.actions';

import {TokenInfo} from "../reducers/Login.reducers";
import storage from "../../helpers/storage";
import {replace} from "connected-react-router";
import {authUserRequest, deauthRequest} from "../../service/auth";
import {getUserRequest} from "../../service/user";

const DASHBOARD_MAIN_PAGE = '/dashboard';
const KEY_TOKEN = 'token';

function* doLoginInitialCheck(): any {
    try {
        const tokenInfo: TokenInfo = storage.getSession(KEY_TOKEN);

        console.log("Initial Check - tokenInfo", tokenInfo);
        if (tokenInfo != null) {
            const {access_token, refresh_token} = tokenInfo;

            // in theory we only need accessToken (and try to retrieve user profile to check validity)
            if (access_token) {
                if (refresh_token) Api.setRefreshToken(refresh_token);
                Api.setAccessToken(access_token);

                //ToDo: load profile here
                const [userInfoResponse] = yield all([
                    call(getUserRequest),
                ]);

                const isUserRequestSucceeded = (userInfoResponse !== undefined) && !userInfoResponse.error && (Object.keys(userInfoResponse.data).length > 2);

                if (isUserRequestSucceeded) {
                    yield put(loginSuccess(userInfoResponse.data.roles));
                    yield put(replace(DASHBOARD_MAIN_PAGE));
                    return;
                }
            }
        }

        yield put(logoutSuccess(false));
    } catch (error) {
        console.log("Initial Check - Error", error);

        if (error.response && error.response.status === 401) {
            yield put(logoutRequest(true)); // signal as session expired
            return;
        }

        // error recovering local data... to consider it as logged out
        let error_message = (error.message) ? error.message : error;
        // map axios error to a more user friendly message
        if (error.name === "Error" && error.message === "Network Error") {
            error_message = "No se puede conectar con el servidor. Por favor, inténtalo más tarde";
        }
        yield put(loginError(error_message));
    }
}

function* doLogin(action: any): any {
    try {
        const requestParams = {
            email: action.payload.email,
            password: action.payload.password,
        };

        const userRequest = yield call(authUserRequest, requestParams);
        if (userRequest.error) {
            switch (userRequest.error_code) {
                case ApiErrors.ERROR_INVALID_CREDENTIAL:
                    yield put(loginError('Credenciales incorrectas'));
                    return;
                case ApiErrors.ERROR_INVALID_EMAIL:
                    yield put(loginError('EMail no válido'));
                    return;
                case ApiErrors.ERROR_ACESS_DENIED:
                    yield put(loginError('Acceso denegado'));
                    return;
                default:
                    yield put(loginError(`${userRequest.error_code}, ${userRequest.error_message}`));
                    return;
            }
        }

        // eslint-disable-next-line eqeqeq
        if (userRequest.data != undefined) {
            const token: TokenInfo = {
                access_token: userRequest.data?.access_token,
                refresh_token: userRequest.data?.refresh_token,
            };
            storage.setSession(KEY_TOKEN, token);

            Api.setRefreshToken(userRequest.data.refresh_token);
            Api.setAccessToken(userRequest.data.access_token);

            const [userInfoResponse] = yield all([
                call(getUserRequest),
            ]);

            const isUserRequestSucceeded = (userInfoResponse !== undefined) && !userInfoResponse.error && (Object.keys(userInfoResponse.data).length > 2);

            if (isUserRequestSucceeded) {
                yield put(loginSuccess(userInfoResponse.data.roles));
                yield put(replace(DASHBOARD_MAIN_PAGE));
                return;
            }

            yield put(loginError('Error obteniendo la información. Por favor, inténtalo más tarde'));
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            yield put(logoutRequest(true)); // signal as session expired
            return;
        }

        let error_message = (error.message) ? error.message : error;
        // map axios error to a more user friendly message
        if (error.name === "Error" && error.message === "Network Error") {
            error_message = "No se puede conectar con el servidor. Por favor, inténtalo más tarde";
        }
        yield put(loginError(error_message));
    }
}

function* doLogout(action:any): any {
    let error_message = '';
    try {
        const logoutRequest = yield call(deauthRequest);
        if (logoutRequest.error) {
            switch (logoutRequest.error_code) {
                case ApiErrors.ERROR_TOKEN_REVOKED:
                    // do nothing.. allow logout
                    break;
                default:
                    error_message = `${logoutRequest.error_code}, ${logoutRequest.error_message}`;
                    break;
            }
        }
    } catch (error) {
        const data = (error.response && error.response.data) ? error.response.data : {};
        if (data.error && data.status === 401 && data.sub_status === 42) {
            // expired token, perform logout anyway
        } else {
            error_message = (error.message) ? error.message : error;
        }
    }

    if (error_message) {
        yield put(logoutError(error_message));
        return;
    }

    yield put(logoutSuccess(action.payload));
}

function* onLogoutSuccess(action:any) {
    Api.clearTokens();
    storage.removeSession(KEY_TOKEN);
    yield put(replace('/', {expired: action.payload}));
}

const LoginSagas = [
    takeLatest(LOGIN_INITIAL_CHECK, doLoginInitialCheck),
    takeLatest(LOGIN_REQUEST, doLogin),
    takeLatest(LOGOUT_REQUEST, doLogout),
    takeLatest(LOGOUT_SUCCESS, onLogoutSuccess),
];

export default LoginSagas;
