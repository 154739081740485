import {IProfile} from "../../service/models/user";

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export function profileRequest() {
    return {
        type: PROFILE_REQUEST,
    };
}

export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export function profileSuccess(payload:IProfile) {
    return {
        type: PROFILE_SUCCESS,
        payload
    };
}

export const PROFILE_ERROR = 'PROFILE_ERROR';
export function profileError(error:string) {
    return {
        type: PROFILE_ERROR,
        payload: error
    };
}
