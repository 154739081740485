import { all } from 'redux-saga/effects';

import LoginWatchers from './Login.sagas';
import ProfileWatchers from './Profile.sagas';
import NutritionistsWatchers from './Nutritionists.sagas';
import AllergiesWatchers from './Allergies.sagas';
import IngredientsWatchers from './Ingredients.sagas';
import UsersWatchers from './Users.sagas';
import StatsWatchers from './Stats.sagas';
import InvoicesWatchers from './Invoices.sagas';
import RecipesWatchers from './Recipes.sagas';
import CodesWatchers from './Codes.sagas';

export default function* rootSaga() {
    const allWatches:any = [
        ...LoginWatchers,
        ...ProfileWatchers,
        ...NutritionistsWatchers,
        ...AllergiesWatchers,
        ...IngredientsWatchers,
        ...UsersWatchers,
        ...StatsWatchers,
        ...InvoicesWatchers,
        ...RecipesWatchers,
        ...CodesWatchers
    ];

    yield all(allWatches);
}
