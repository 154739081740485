import React, {useEffect, useRef} from 'react';

import './Invoices.scss';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../redux/reducers";
import {DataTable} from "primereact/datatable";
import {invoicesListRequest} from "../../../../redux/actions/Invoices.actions";
import LoadingView from "../../../../components/LoadingView/LoadingView";
import {Message} from "primereact/message";
import {Column} from "primereact/column";

const dateTemplate = (rowData:any) => {
    if (rowData.created_at) {
        return moment(rowData.created_at).format('DD-MM-YYYY');
    }
    return null;
}

const amountTemplate = (rowData:any) => {
    if (rowData.amount) {
        return rowData.amount+' €';
    }
    return null;
}

const Invoices : React.FC = (props) => {
    const dispatch = useDispatch();
    const invoicesState = useSelector((state:IAppState) => state.invoices);

    const dataTableRef = useRef<DataTable>(null);

    useEffect(() => {
        dispatch(invoicesListRequest());
    }, [dispatch]);

    if (invoicesState.error) {
        return (
            <div className="container-fluid">
                <Message text={invoicesState.error} severity="error" />
            </div>
        )
    }

    if (invoicesState.waiting || invoicesState.data == null) {
        return <LoadingView />;
    }

    return (
        <div id="Invoices">
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">Facturas</h3>
            </nav>
            <div className="container-fluid">
                <div className="row">
                    <DataTable ref={dataTableRef} value={invoicesState.data} className="p-datatable-striped" paginator rows={10}>
                        <Column field="created_at" header="Fecha" body={dateTemplate} sortable />
                        <Column field="description" header="Concepto" filter filterPlaceholder="Buscar por concepto" filterMatchMode="contains"  sortable />
                        <Column field="amount" header="Cantidad" body={amountTemplate} sortable />
                        <Column field="id" header="Nº de Factura" sortable />
                        <Column field="user.name" header="Nutricionista" filter filterPlaceholder="Buscar por Nutricionista" filterMatchMode="contains" sortable />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default Invoices;
