import {put, call, takeLeading} from "redux-saga/effects";
import {
    CODES_LIST_REQUEST,
    CODES_SKU_ITEMS_LIST_REQUEST, codesListError, codesListReceived,
    codesSkuItemsListError,
    codesSkuItemsListReceived
} from "../actions/Codes.actions";
import {getCodesList, getCodesSkuItemsList} from "../../service/codes";

function* doGetCodesList(action: any): any {
    try {
        const codesListResult = yield call(getCodesList);

        if (codesListResult.error) {
            switch (codesListResult.error_code) {
                default:
                    yield put(codesListError(`${codesListResult.error_code}, ${codesListResult.error_message}`));
                    return;
            }
        }

        if (codesListResult.data) {
            yield put(codesListReceived(codesListResult.data));
            return;
        }

        yield put(codesListError('Error retrieving SKU Items'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(codesListError(error_message))
    }
}

function* doGetSKUItemsList(action: any): any {
    try {
        const itemsListResult = yield call(getCodesSkuItemsList);

        if (itemsListResult.error) {
            switch (itemsListResult.error_code) {
                default:
                    yield put(codesSkuItemsListError(`${itemsListResult.error_code}, ${itemsListResult.error_message}`));
                    return;
            }
        }

        if (itemsListResult.data) {
            yield put(codesSkuItemsListReceived(itemsListResult.data));
            return;
        }

        yield put(codesSkuItemsListError('Error retrieving SKU Items'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(codesSkuItemsListError(error_message))
    }
}

const CodesSagas = [
    takeLeading(CODES_LIST_REQUEST, doGetCodesList),
    takeLeading(CODES_SKU_ITEMS_LIST_REQUEST, doGetSKUItemsList),
];

export default CodesSagas;
