import React, {useEffect, useRef, useState} from 'react';

import './NutritionistProfile.scss';
import {Button} from "primereact/button";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import useTDPForm from "../../../../../hooks/tdp-form/tdp-form";
import {ITDPFormValidations} from "../../../../../hooks/tdp-form/tdp-form-types";
import {RequiredValidation} from "../../../../../hooks/tdp-form/tdp-form-validations";
import {ProgressSpinner} from "primereact/progressspinner";
import {
    changeOtherUserPassword,
    createOtherProfileRequest,
    getOtherProfileRequest,
    saveOtherProfileRequest, updateOtherProfileAvatar
} from "../../../../../service/user";
import {Toast} from "primereact/toast";
import {Password} from "primereact/password";
import {tdp_diff} from "../../../../../utils/tdp_diff";

interface IProfileForm {
    first_name: string;
    last_name: string;
    email: string;
    main_phone: string;
    city: string;
    region: string;
    country: string;
    zip_code: string;
    about_me: string;
    bio: string;
    avatar: string;
}

const formInitialValues: IProfileForm = {
    first_name: '',
    last_name: '',
    email: '',
    main_phone: '',
    city: '',
    region: '',
    country: '',
    zip_code: '',
    about_me: '',
    bio: '',
    avatar: '',
}

const formValidations: ITDPFormValidations<IProfileForm> = {
    first_name: [RequiredValidation({errorText: 'El nombre es obligatorio'})],
    last_name: [RequiredValidation({errorText: 'El apellido es obligatorio'})],
    email: [RequiredValidation({errorText: 'El email es obligatorio'})],
    main_phone: [],
    city: [],
    region: [],
    country: [],
    zip_code: [],
    about_me: [],
    bio: [],
    avatar: [],
};

interface NewPasswordState {
    password: string|undefined;
    errorText: string|undefined;
    saving: boolean;
}

interface NutritionistProfileProps {
    history: any;
}

const NutritionistProfile : React.FC<NutritionistProfileProps> = (props) => {
    const inputFileRef = React.useRef(null)

    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const [newAvatar, setNewAvatar] = useState<any>(null);
    const [newPassword, setNewPassword] = useState<NewPasswordState>({
        password: undefined,
        errorText: undefined,
        saving: false,
    });

    const [currentProfileData, setCurrentProfileData] = useState<IProfileForm>(formInitialValues);

    const { userId } = props.history?.location?.state;

    const onSubmit = async (submittedValues:IProfileForm) => {
        console.log("ONSUBMIT", submittedValues);

        if (userId === undefined && !newPassword.password) {
            setNewPassword({...newPassword, errorText: 'Especificar una contraseña es obligatorio para un nuevo usuario'});
            return;
        }

        const toastTitle = "Error guardando perfil";

        const modifiedData = tdp_diff(currentProfileData, submittedValues);
        if (modifiedData && Object.keys(modifiedData).length > 0) {
            setSaving(true);
            try {
                if (userId !== undefined) {
                    const saveOtherProfileResponse = await saveOtherProfileRequest(userId, modifiedData);
                    if (saveOtherProfileResponse.error) {
                        throw new Error(saveOtherProfileResponse.error_message);
                    }

                    if (newAvatar != null) {
                        const formData = new FormData();
                        formData.append("file", newAvatar);

                        // update avatar
                        const updateOtherAvatarImageResponse = await updateOtherProfileAvatar(userId, formData);
                        if (updateOtherAvatarImageResponse.error) {
                            throw new Error('Error saving avatar: '+updateOtherAvatarImageResponse.error_message);
                        }

                        // update URLs
                        if (updateOtherAvatarImageResponse.data) {
                            form.handleChange('avatar', updateOtherAvatarImageResponse.data.avatar);
                        }
                        setNewAvatar(null);
                    }
                    setSaving(false);
                } else {
                    createOtherProfileRequest({
                        ...modifiedData,
                        // @ts-ignore
                        password: newPassword.password,
                        role: 'nutritionist',
                    }).then(res => {
                        setSaving(false);
                        if (res.error) {
                            showToast(toastTitle, res.error_message, 'error');
                            return;
                        }

                        showToast('Crear usuario', 'OK!', 'success');
                    }).catch(err => {
                        setSaving(false);
                        showToast(toastTitle, err.message,'error');
                    });
                }
            } catch (err) {
                setSaving(false);
                showToast(toastTitle, err.message,'error');
            }
        }
    }

    const form = useTDPForm(formInitialValues, formValidations, onSubmit);

    useEffect(() => {
        // new user?
        if (userId !== undefined) {
            setLoading(true);
            getOtherProfileRequest(userId).then(res => {
                setLoading(false);
                if (res.error) {
                    return;
                }
                const profileData = {...form.values, ...res.data};
                form.resetValues(profileData);
                setCurrentProfileData(profileData);
            }).catch(err => {
                setLoading(false);
                console.log('Error retrieving profile', err);
            });
        }
        // eslint-disable-next-line
    }, [userId]);

    const toastRef = useRef<Toast>(null);

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    if (loading) {
        return <LoadingView />;
    }

    function changeUserPassword() {
        const toastTitle = 'Cambiar Password';

        if (!newPassword.password || !newPassword.password.trim()) {
            showToast(toastTitle, 'No se ha especificado una nueva password!','error');
            return;
        }

        setNewPassword({...newPassword, saving: true});

        changeOtherUserPassword(userId, newPassword.password).then(res => {
            setNewPassword({...newPassword, saving: false});

            if (res.error) {
                showToast(toastTitle, res.error_message, 'error');
                return;
            }

            showToast(toastTitle, 'OK!', 'success');
        }).catch(err => {
            showToast(toastTitle, err.message,'error');
            setNewPassword({...newPassword, saving: false});
        });
    }

    function selectNewAvatar(ev: any) {
        if (ev.nativeEvent) {
            ev.nativeEvent.stopPropagation();
            ev.nativeEvent.preventDefault();
        }
        ev.preventDefault();
        ev.stopPropagation();

        console.log('Click update avatar', ev, inputFileRef);
        if (inputFileRef != null && inputFileRef.current != null) {
            // @ts-ignore
            inputFileRef.current.click();
        }
    }

    function onAvatarFileSelected(ev: any) {
        console.log('OnAvatarFileSelected', ev);

        const files = ev.dataTransfer ? ev.dataTransfer.files : ev.target.files;
        if (files?.length > 0) {
            if (form.values.avatar) {
                URL.revokeObjectURL(form.values.avatar);
            }
            form.handleChange('avatar', URL.createObjectURL(files[0]));
            setNewAvatar(files[0]);
        }

        // clear input
        if (inputFileRef && inputFileRef.current) {
            // @ts-ignore
            inputFileRef.current.value = '';
        }
    }

    return (
        <div id="Profile">
            <nav className="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
                <div className="container-fluid">
                    {/*<span className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">Perfil</span>*/}
                </div>
            </nav>
            <div className="header pb-8 pt-5 d-flex align-items-center">
                <Toast ref={toastRef} position="top-right" />
                <span className="mask opacity-8"/>
                <div className="container-fluid d-flex align-items-center">
                    <div className="row">
                        <div className="col-lg-7 col-md-10">
                            <h1 className="display-4 text-white">Perfil de Usuario</h1>
                            <p className="text-white mt-0 mb-5">Información del perfil del usuario</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt--7">
                <div className="row">
                    <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                        <div className="card card-profile shadow">
                            <div className="row justify-content-center">
                                <div className="col-lg-3 order-lg-2">
                                    <div className="card-profile-image">
                                        <input ref={inputFileRef} type="file" onChange={onAvatarFileSelected} multiple={false} accept="image/*" style={{display: 'none'}} />
                                        <div className="card-profile-image" onClick={selectNewAvatar} title="Haz click aquí para cambiar tu avatar">
                                            {form.values.avatar ? <img src={form.values.avatar} className="profile-avatar" alt="avatar"/>
                                                : <i className="avatar profile-avatar pi pi-user"></i>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0 pt-md-4">
                                <div className="text-center">
                                    <h3>
                                    </h3>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2"/>{form.values.city}, {form.values.country}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 order-xl-1">
                        <div className="card bg-secondary shadow">
                            <div className="card-header bg-white border-0">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h3 className="mb-0">Datos de la cuenta</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                    <h6 className="heading-small text-muted mb-4">Información básica</h6>
                                    <div className="pl-lg-4">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-first-name">Nombre</label>
                                                    <InputText id="input-first-name" className="form-control form-control-alternative"
                                                               placeholder="Nombre" value={form.values.first_name || ''}
                                                               onChange={(e) =>
                                                                   form.handleChange('first_name', e.currentTarget.value)
                                                               } />
                                                    {form.errors.first_name.error ?
                                                        <small className="p-invalid">{form.errors.first_name.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-last-name">Apellidos</label>
                                                    <InputText id="input-last-name" className="form-control form-control-alternative"
                                                               placeholder="Apellidos" value={form.values.last_name || ''}
                                                               onChange={(e) =>
                                                                   form.handleChange('last_name', e.currentTarget.value)
                                                               }/>
                                                    {form.errors.last_name.error ?
                                                        <small className="p-invalid">{form.errors.last_name.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-email">Email</label>
                                                    <InputText type="email" id="input-email" className="form-control form-control-alternative"
                                                           placeholder="Email" value={form.values.email || ''}
                                                            onChange={(e) =>
                                                                form.handleChange('email', e.currentTarget.value)
                                                            }/>
                                                    {form.errors.email.error ?
                                                        <small className="p-invalid">{form.errors.email.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-main-phone">Teléfono móvil</label>
                                                    <InputText id="input-main-phone" className="form-control form-control-alternative"
                                                           placeholder="Teléfono móvil" value={form.values.main_phone || ''}
                                                           onChange={(e) =>
                                                               form.handleChange('main_phone', e.currentTarget.value)
                                                           }/>
                                                    {form.errors.main_phone.error ?
                                                        <small className="p-invalid">{form.errors.main_phone.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-instagram-url">Perfil de instagram</label>
                                                    <input type="text" id="input-instagram-url" className="form-control form-control-alternative"
                                                           placeholder="www.instagram.com/perfil" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-pro-nr">Número de colegiado</label>
                                                    <input type="text" id="input-pro-nr" className="form-control form-control-alternative"
                                                           placeholder="Número de colegiado" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-password">Contraseña</label>
                                                    <Password id="input-password" className="w-100" inputClassName="form-control form-control-alternative" placeholder="Contraseña"
                                                              value={newPassword.password} onChange={(e) => setNewPassword({...newPassword, password: e.currentTarget.value})} toggleMask />
                                                    {newPassword.errorText ?
                                                        <small className="p-invalid">{newPassword.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col align-self-center">
                                                {userId !== undefined && <Button label="Cambiar contraseña" className="p-button-warning p-button-raised p-button-rounded" onClick={changeUserPassword}>
                                                    {newPassword.saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
                                                </Button>}
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Datos de facturación</h6>
                                    <div className="pl-lg-4">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-organization-type">Tipo de organización</label>
                                                    <input type="text" id="input-organization-type" className="form-control form-control-alternative" placeholder="Sl, SA, Autónomo, Otros" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-vatid">CIF/NIF</label>
                                                    <InputText id="input-vatid" className="form-control form-control-alternative" placeholder="CIF/NIF" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-company">Nombre persona o organización</label>
                                                    <input type="text" id="input-organization" className="form-control form-control-alternative" placeholder="Nombre persona o organización" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-company">Razón social</label>
                                                    <input type="text" id="input-business-name" className="form-control form-control-alternative" placeholder="Razón Social" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-bank-account">Cuenta bancaria</label>
                                                    <input id="input-bank-account" className="form-control form-control-alternative" placeholder="Cuenta bancaria"  type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-address">Dirección de facturación</label>
                                                    <input id="input-address" className="form-control form-control-alternative" placeholder="Dirección de facturación" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-city">Ciudad</label>
                                                    <InputText id="input-city" className="form-control form-control-alternative"
                                                           placeholder="City" value={form.values.city || ''}
                                                            onChange={(e) =>
                                                                form.handleChange('city', e.currentTarget.value)
                                                            }/>
                                                    {form.errors.city.error ?
                                                        <small className="p-invalid">{form.errors.city.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-country">País</label>
                                                    <InputText id="input-country" className="form-control form-control-alternative"
                                                           placeholder="País" value={form.values.country || ''}
                                                            onChange={(e) =>
                                                                form.handleChange('country', e.currentTarget.value)
                                                            }/>
                                                    {form.errors.country.error ?
                                                        <small className="p-invalid">{form.errors.country.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-country">Código Postal</label>
                                                    <InputText id="input-postal-code" className="form-control form-control-alternative"
                                                           placeholder="Postal code" value={form.values.zip_code || ''}
                                                        onChange={(e) =>
                                                            form.handleChange('zip_code', e.currentTarget.value)
                                                        }/>
                                                    {form.errors.zip_code.error ?
                                                        <small className="p-invalid">{form.errors.zip_code.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Información adicional</h6>
                                    <div className="pl-lg-4">
                                        <div className="form-group">
                                            <label>Descripción</label>
                                            <InputTextarea rows={4} className="form-control form-control-alternative" placeholder="Una breve descripción sobre ti"
                                                           value={form.values.about_me || ''}
                                                           onChange={(e) =>
                                                               form.handleChange('about_me', e.currentTarget.value)
                                                           }/>
                                            {form.errors.about_me.error ?
                                                <small className="p-invalid">{form.errors.about_me.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="pl-lg-4">
                                        <div className="form-group">
                                            <label>Formación/BIO</label>
                                            <InputTextarea rows={4} className="form-control form-control-alternative" placeholder="Información sobre tu formación o biografía"
                                                           value={form.values.bio || ""}
                                                           onChange={(e) =>
                                                               form.handleChange('bio', e.currentTarget.value)
                                                           }/>
                                            {form.errors.bio.error ?
                                                <small className="p-invalid">{form.errors.bio.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <Button label="Guardar datos" className="p-button-raised p-button-rounded"
                                                disabled={saving} onClick={form.handleSubmit}>
                                            {saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
                                        </Button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NutritionistProfile;
