import {DEFAULT_API_STATE, IApiState} from "../types";
import {
    RECIPES_LIST_ERROR,
    RECIPES_LIST_RECEIVED, RECIPES_LIST_REMOVE_ITEM,
    RECIPES_LIST_REQUEST, RECIPES_LIST_UPDATE_ITEM
} from "../actions/Recipes.actions";
import {IRecipe} from "../../service/models/recipes";

export interface IRecipesState {
    list: IApiState,
}

const DEFAULT_STATE : IRecipesState = {
    list: DEFAULT_API_STATE,
};

const RecipesReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        case RECIPES_LIST_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    waiting: true,
                    error: '',
                    success: false,
                }
            }
        case RECIPES_LIST_RECEIVED:
            return {
                ...state,
                list: {
                    ...state.list,
                    waiting: false,
                    error: '',
                    success: true,
                    data: action.payload,
                }
            }
        case RECIPES_LIST_ERROR:
            return {
                ...state,
                list: {
                    ...state.list,
                    waiting: false,
                    error: action.payload,
                    success: false,
                    data: null,
                }
            }
        case RECIPES_LIST_UPDATE_ITEM:
            if (!action.payload || !action.payload.id) {
                return state;
            }
            let updatedItems = [];
            if (state.list.data) {
                updatedItems = state.list.data.map((r:IRecipe) => r.id === action.payload.id ? {...r, ...action.payload} : r);
            }
            return {
                ...state,
                list: {
                    ...state.list,
                    data: updatedItems
                }
            }
        case RECIPES_LIST_REMOVE_ITEM:
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                list: {
                    ...state.list,
                    data: state.list.data.filter((r:IRecipe) => r.id !== action.payload)
                }
            }
        // Default
        default:
            return state;
    }
};

export default RecipesReducers;