import React, {useEffect, useRef, useState} from 'react';

import './CodesList.scss';
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../../redux/reducers";
import {Button} from "primereact/button";
// @ts-ignore
import {confirmDialog} from "primereact/confirmdialog";
import {
    codesListAdd,
    codesListRemove,
    codesListRequest
} from "../../../../../redux/actions/Codes.actions";
import {Dialog} from "primereact/dialog";
import {INewCodeItem, ISKUProfileItem} from "../../../../../service/models/codes";
import {InputText} from "primereact/inputtext";
import {ITDPFormValidations} from "../../../../../hooks/tdp-form/tdp-form-types";
import {PositiveNumberValidation, RequiredValidation} from "../../../../../hooks/tdp-form/tdp-form-validations";
import {addCodeRequest, getCodesProfilesList, removeCodeRequest} from "../../../../../service/codes";
import useTDPForm from "../../../../../hooks/tdp-form/tdp-form";
import {ProgressSpinner} from "primereact/progressspinner";
import {AutoComplete} from "primereact/autocomplete";

const formInitialValues: INewCodeItem = {
    code: '',
    userId: 0,
    userName: '',
    profileId: 0,
    profileName: '',
}

const formValidations: ITDPFormValidations<INewCodeItem> = {
    code: [RequiredValidation({errorText: 'El código es obligatorio'})],
    userId: [RequiredValidation({errorText: 'El usuario es obligatorio'}),
            PositiveNumberValidation({errorText: 'El usuario es obligatorio'})],
    userName: [RequiredValidation({errorText: 'El usuario es obligatorio'})],
    profileId: [RequiredValidation({errorText: 'El perfil es obligatorio'}),
                PositiveNumberValidation({errorText: 'El perfil es obligatorio'})],
    profileName: [RequiredValidation({errorText: 'El perfil es obligatorio'})],
};

const CodesList : React.FC = (props) => {
    const dispatch = useDispatch();
    const codesListState = useSelector((state:IAppState) => state.codes.codeslist);
    const usersState = useSelector((state:IAppState) => state.users);

    const [profiles, setProfiles] = useState<any>([]);

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredProfiles, setFilteredProfiles] = useState([]);

    const [saving, setSaving] = useState<boolean>(false);

    const [newCodeState, setNewCodeState] = useState({
        ...formInitialValues,
        visible: false,
    });

    const toastRef = useRef<Toast>(null);

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    const onSubmit = async (submittedValues:INewCodeItem) => {
        const toastTitle = 'Error guardando código';

        setSaving(true);

        try {
            const addCodeResponse = await addCodeRequest({
                code: submittedValues.code,
                user_id: submittedValues.userId,
                profile_id: submittedValues.profileId,
            });

            setSaving(false);

            if (addCodeResponse.error) {
                showToast(toastTitle, addCodeResponse.error_message, 'error');
                return;
            }

            showToast('Guardar Código', 'OK!', 'success');
            dispatch(codesListAdd({
                code: submittedValues.code,
                user: {
                    id: submittedValues.userId,
                    name: submittedValues.userName,
                },
                profile: {
                    id: submittedValues.profileId,
                    name: submittedValues.profileName,
                },
            }));
            form.resetValues(formInitialValues);
            hideNewCodeDialog();
        } catch (err) {
            setSaving(false);
            showToast(toastTitle, err.message,'error');
        }
    }

    const form = useTDPForm(formInitialValues, formValidations, onSubmit);

    useEffect(() => {
        dispatch(codesListRequest());
        getCodesProfilesList().then(res => {
            if (res.data) {
                setProfiles(res.data);
            }
        }).catch(err => {
            console.log('Error retrieving profiles', err);
        });
    }, [dispatch]);

    function hideNewCodeDialog() {
        setNewCodeState({...newCodeState, visible: false});
    }

    const newCodeDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideNewCodeDialog} />
            <Button label="Guardar" icon="pi pi-save" className="p-button-text" disabled={saving} onClick={form.handleSubmit}>
                {saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
            </Button>
        </>
    );

    const actionHeaderTemplate = () => {
        return (
            <Button icon="pi pi-plus" className="p-button-rounded p-button-success"
                    onClick={() => setNewCodeState({...newCodeState, visible: true})} />
        );
    }

    async function removeCode(code: string) {
        const toastTitle = 'Error borrando código';
        try {
            const removeResponse = await removeCodeRequest(code);
            if (!removeResponse || removeResponse.error) {
                showToast(toastTitle, removeResponse.error_message, 'error');
                return;
            }

            showToast('Eliminar Código', 'OK!', 'success');
            dispatch(codesListRemove(code));
        } catch (err) {
            showToast(toastTitle, err.message,'error');
        }
    }

    function actionBodyTemplate(rowData:any, props:any) {
        return <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
            confirmDialog({
                message: '¿Estás seguro de eliminar el código "'+rowData.code+'"?',
                // @ts-ignore
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                style: {maxWidth: '500px'},
                accept: () => removeCode(rowData.code)
            });
        }} />
    }

    function onUserChange(props:any, id:number|undefined, name:string) {
        form.handleChange('userId', id);
        form.handleChange('userName', name);
    }

    function searchUser(e:any) {
        if (!e.query.trim().length) {
            setFilteredUsers(usersState.data);
            return;
        }

        if (!usersState.data) {
            setFilteredUsers([]);
            return;
        }

        const lcQueryString = e.query.toLowerCase();
        setFilteredUsers(usersState.data.filter((user: any) => {
            return (user.name.toLowerCase().indexOf(lcQueryString)>=0);
        }));
    }

    function searchProfile(e:any) {
        if (!e.query.trim().length) {
            setFilteredProfiles(profiles);
            return;
        }

        const lcQueryString = e.query.toLowerCase();
        setFilteredProfiles(profiles.filter((profile: ISKUProfileItem) => {
            return (profile.name.toLowerCase().indexOf(lcQueryString)>=0);
        }));
    }

    function onProfileChange(props:any, id:number|undefined, name:string) {
        form.handleChange('profileId', id);
        form.handleChange('profileName', name);
    }

    return (
        <div id="codes_list">
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">Codes <span className="text-halen-bold">List</span></h3>
            </nav>
            <div className="container-fluid">
                <Toast ref={toastRef} position="top-right" />
                <div className="row tables-row">
                    <div className="col-12 h-100">
                        <DataTable className="h-100" paginator rows={20} tableClassName="h-100" value={codesListState.data}>
                            <Column field="code" header="Code" filter filterPlaceholder="Buscar por código" filterMatchMode="contains" style={{width:'20%'}} sortable/>
                            <Column field="user.name" header="User" filter filterPlaceholder="Buscar por usuario" filterMatchMode="contains" style={{width: '30%'}} sortable />
                            <Column field="profile.name" header="Perfil" filter filterPlaceholder="Buscar por perfil" filterMatchMode="contains" style={{width: '40%'}} sortable />
                            <Column body={actionBodyTemplate} header={actionHeaderTemplate()} style={{width: '10%'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={newCodeState.visible} style={{ width: '450px' }} header="Añadir nuevo item SKU" modal footer={newCodeDialogFooter} onHide={hideNewCodeDialog}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-control-label" htmlFor="input-name">Código</label>
                                    <InputText className="form-control form-control-alternative"
                                               placeholder="Code" value={form.values.code}
                                               onChange={(e) =>
                                                   form.handleChange('code', e.currentTarget.value)
                                               }/>
                                    {form.errors.code.error ?
                                        <small className="p-invalid">{form.errors.code.errorText}</small>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-control-label" htmlFor="input-name">Usuario</label>
                                    <AutoComplete dropdown className="form-control-alternative w-100"
                                                  value={form.values.userName}
                                                  suggestions={filteredUsers}
                                                  field="name" minLength={3} completeMethod={searchUser}
                                                  onChange={(e) =>
                                                      onUserChange(props, undefined, e.value)
                                                  }
                                                  onSelect={(e) =>
                                                      onUserChange(props, e.value.id, e.value.name)
                                                  }/>
                                    {form.errors.userId.error ?
                                        <small className="p-invalid">{form.errors.userId.errorText}</small>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-control-label" htmlFor="input-name">Perfil</label>
                                    <AutoComplete dropdown className="form-control-alternative w-100"
                                                  value={form.values.profileName}
                                                  suggestions={filteredProfiles}
                                                  field="name" minLength={3} completeMethod={searchProfile}
                                                  onChange={(e) =>
                                                      onProfileChange(props, undefined, e.value)
                                                  }
                                                  onSelect={(e) =>
                                                      onProfileChange(props, e.value.id, e.value.name)
                                                  }/>
                                    {form.errors.profileId.error ?
                                        <small className="p-invalid">{form.errors.profileId.errorText}</small>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default CodesList;
