import {IServiceResponse} from "./models/service";
import Api from "./api";
import {IStatsProfileResponse, IStatsResponse} from "./models/stats";

export const getStatsRequest = async (): Promise<IServiceResponse<IStatsResponse | null>> => {
    return await Api.doGet("/dashboard/stats");
};

export const getStatsProfileRequest = async (): Promise<IServiceResponse<IStatsProfileResponse | null>> => {
    return await Api.doGet("/dashboard/stats/profile");
};
