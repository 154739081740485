import {
    STATS_ERROR,
    STATS_PROFILE_ERROR, STATS_PROFILE_REQUEST,
    STATS_PROFILE_SUCCESS,
    STATS_REQUEST,
    STATS_SUCCESS
} from "../actions/Stats.actions";
import {DEFAULT_API_STATE, IApiState} from "../types";

export interface IStatsState {
    general: IApiState;
    profile: IApiState;
}

const DEFAULT_STATE : IStatsState = {
    general: DEFAULT_API_STATE,
    profile: DEFAULT_API_STATE,
};

const StatsReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        case STATS_REQUEST:
            return {
                ...state,
                general: {
                    ...state.general,
                    waiting: true,
                    error: '',
                    success: false,
                }
            }
        case STATS_SUCCESS:
            return {
                ...state,
                general: {
                    ...state.general,
                    waiting: false,
                    error: '',
                    success: true,
                    data: action.payload,
                }
            }
        case STATS_ERROR:
            return {
                ...state,
                general: {
                    ...state.general,
                    waiting: false,
                    error: action.payload,
                    success: false,
                    data: null,
                }
            }
        // Stats profile
        case STATS_PROFILE_REQUEST:
            return {
                ...state,
                profile: {
                    ...state.general,
                    waiting: true,
                    error: '',
                    success: false,
                }
            }
        case STATS_PROFILE_SUCCESS:
            return {
                ...state,
                profile: {
                    ...state.general,
                    waiting: false,
                    error: '',
                    success: true,
                    data: action.payload,
                }
            }
        case STATS_PROFILE_ERROR:
            return {
                ...state,
                profile: {
                    ...state.general,
                    waiting: false,
                    error: action.payload,
                    success: false,
                    data: null,
                }
            }
        // Default
        default:
            return state;
    }
};

export default StatsReducers;
