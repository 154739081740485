import {call, put, takeLatest } from "redux-saga/effects";
import {
    RECIPES_LIST_REQUEST,
    recipesListError,
    recipesListReceived
} from "../actions/Recipes.actions";
import {getRecipesListRequest} from "../../service/recipes";

function* doGetRecipesList(): any {
    try {
        const recipesListResult = yield call(getRecipesListRequest);

        if (recipesListResult.error) {
            switch (recipesListResult.error_code) {
                default:
                    yield put(recipesListError(`${recipesListResult.error_code}, ${recipesListResult.error_message}`));
                    return;
            }
        }

        if (recipesListResult.data) {
            yield put(recipesListReceived(recipesListResult.data));
            return;
        }

        yield put(recipesListError('Error retrieving recipes'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(recipesListError(error_message))
    }
}

const RecipesSagas = [
    takeLatest(RECIPES_LIST_REQUEST, doGetRecipesList),
];

export default RecipesSagas;
