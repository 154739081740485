import {
    ICreditCard,
    IEmail,
    IEquals,
    IEqualsParams,
    IErrorTextValidation,
    INumber,
    IRequired,
    IUrl
} from "./tdp-form-types";
import {isCreditCard, isEmail, isNumberString} from "class-validator";

export const RequiredValidation: IRequired = <IValue = string | boolean>(
    params: IErrorTextValidation
) => {
    return {
        method: (value: IValue, name: string) => (value),
        errorText: params.errorText || "Required field",
    };
}

export const NumberValidation: INumber = (params: IErrorTextValidation) => {
    return {
        method: (value: string, name: string) => isNumberString(value),
        errorText: params.errorText || "Invalid number",
    };
};

export const PositiveNumberValidation: INumber = (params: IErrorTextValidation) => {
    return {
        method: (value: any, name: string) => {
            if (typeof value === "string") {
                value = parseInt(value, 10);
            }
            return !isNaN(value) && value>0;
        },
        errorText: params.errorText || "Invalid number or value <= 0",
    };
};

export const PositiveOrZeroNumberValidation: INumber = (params: IErrorTextValidation) => {
    return {
        method: (value: any, name: string) => {
            if (typeof value === "string") {
                value = parseInt(value, 10);
            }
            return !isNaN(value) && value>=0;
        },
        errorText: params.errorText || "Invalid number or value < 0",
    };
};

export const EmailValidation: IEmail = (params: IErrorTextValidation) => {
    return {
        method: (value: string, name: string) => isEmail(value),
        errorText: params.errorText || "Invalid email",
    };
};

export const EqualsValidation: IEquals = <IValue = string | boolean>(
    params: IEqualsParams,
) => {
    return {
        method: (value: IValue, name: string, formValues: any) =>
            formValues[params.compareName] === value,
        errorText:
            params.errorText ||
            `The field is not equal with the ${params.compareName} field`,
    };
};

export const UrlValidation: IUrl = (params: IErrorTextValidation) => {
    return {
        method: (value: string, name: string) => isEmail(value),
        errorText: params.errorText || "Invalid url",
    };
};

export const CreditCardValidation: ICreditCard = (
    params: IErrorTextValidation,
) => {
    return {
        method: (value: string, name: string) => isCreditCard(value),
        errorText: params.errorText || "Invalid credit card",
    };
};

export const ArrayValidation: IRequired = <IValue = any[]>(
    params: IErrorTextValidation
) => {
    return {
        method: (value: IValue, name: string) => {
            return (value != null && Array.isArray(value) && value.length>0);
        },
        errorText: params.errorText || "Required field",
    };
}
