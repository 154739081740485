import {IServiceResponse} from "./models/service";
import Api from "./api";
import {IAllergy, ICreateAllergyData, ICreateAllergyParams, IDashboardAllergy} from "./models/allergy";

export const getAllergiesListRequest = async () : Promise<IServiceResponse<IAllergy[] | null>> => {
    return await Api.doGet("/dashboard/allergies");
};

export const createAllergyRequest = async (params: ICreateAllergyParams) : Promise<IServiceResponse<ICreateAllergyData | null>> => {
    return await Api.doPut("/dashboard/allergies", params);
};

export const getAllergyInfo = async(allergy_id:number) : Promise<IServiceResponse<IDashboardAllergy | null>> => {
    return await Api.doGet("/dashboard/allergies/"+allergy_id);
}

export const saveAllergyInfo = async(params: IDashboardAllergy) : Promise<IServiceResponse<null>> => {
    return await Api.doPost("/dashboard/allergies/"+params.id, params);
}

export const removeAllergyRequest = async (allergy_id: number) : Promise<IServiceResponse<null>> => {
    return await Api.doDelete("/dashboard/allergies/"+allergy_id, {});
};
