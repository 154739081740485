import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/free-brands-svg-icons";
import React from "react";

export function storeItemTemplate(item: any | undefined) {
    if (item !== undefined) {
        return (
            <div>
                <FontAwesomeIcon icon={['fab', item.icon as IconName]}/>&nbsp;{item.label}
        </div>
    );
    }
    return null;
}
