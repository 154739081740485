import {call, put, takeLatest } from "redux-saga/effects";
import {INVOICES_LIST_REQUEST, invoicesListError, invoicesListReceived} from "../actions/Invoices.actions";
import {getInvoicesListRequest} from "../../service/invoices";

function* doGetInvoicesList(): any {
    try {
        const invoicesListResult = yield call(getInvoicesListRequest);

        if (invoicesListResult.error) {
            switch (invoicesListResult.error_code) {
                default:
                    yield put(invoicesListError(`${invoicesListResult.error_code}, ${invoicesListResult.error_message}`));
                    return;
            }
        }

        if (invoicesListResult.data) {
            yield put(invoicesListReceived(invoicesListResult.data));
            return;
        }

        yield put(invoicesListError('Error retrieving invoices'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(invoicesListError(error_message))
    }
}

const InvoicesSagas = [
    takeLatest(INVOICES_LIST_REQUEST, doGetInvoicesList),
];

export default InvoicesSagas;
