import React from "react";

import './NotFound.scss';
import logo from "../../assets/img/halen_white.png";
import Footer from "../../components/Footer/Footer";

const NotFound: React.FC = (props) => {
    return (
        <div className="main-content">
            <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
                <div className="container px-4">
                    <img src={logo} alt={'logo'}/>
                </div>
            </nav>
            <div className="header bg-gradient-primary-login py-7 py-lg-8">
                <div className="container">
                    <div className="header-body text-center mb-7">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-6">
                                <h1 className="text-white">La página no existe</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default NotFound;
