import {LoginBody} from "../reducers/Login.reducers";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export function loginRequest(payload: LoginBody) {
    return {
        type: LOGIN_REQUEST,
        payload
    };
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export function loginSuccess(roles:string[]) {
    return {
        type: LOGIN_SUCCESS,
        payload: roles
    };
}

export const LOGIN_ERROR = 'LOGIN_ERROR';
export function loginError(error:string) {
    return {
        type: LOGIN_ERROR,
        payload: error
    };
}

export const LOGIN_INITIAL_CHECK = 'LOGIN_INITIAL_CHECK';
export function loginInitialCheck() {
    return {
        type: LOGIN_INITIAL_CHECK
    };
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export function logoutRequest(expired:boolean=false) {
    return {
        type: LOGOUT_REQUEST,
        payload: expired
    };
}

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export function logoutSuccess(expired:boolean) {
    return {
        type: LOGOUT_SUCCESS
    };
}

export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export function logoutError(error: string) {
    return {
        type: LOGOUT_ERROR,
        payload: error
    };
}
