import { useState} from 'react';
import {
    TDPFormError,
    ITDPDefaultFormValues,
    ITDPErrorValues,
    ITDPFormValidations,
    ITDPUseForm,
    ITDPValidation
} from "./tdp-form-types";

const useTDPForm :ITDPUseForm = <
    ITDPFormValues extends { [key: string]: any } = ITDPDefaultFormValues
>(initialValues:ITDPFormValues, validations:ITDPFormValidations<ITDPFormValues>, submitCallback:(values:ITDPFormValues) => void) => {
    const [values, setValues] = useState<ITDPFormValues>(initialValues);

    let initialErrors: any = { ...values };
    Object.keys(initialErrors).forEach((key: string) => {
        initialErrors[key] = {
            error: false,
            errorText: "",
        };
    });

    const [errors, setErrors] = useState<ITDPErrorValues<ITDPFormValues>>(initialErrors);

    const validateForm = () => {
        let valid: boolean = true;

        let newErrorValues: ITDPErrorValues<ITDPFormValues> = { ...errors };

        let inputError: TDPFormError = {
            error: false,
            errorText: "",
        };

        let valueValidation: ITDPValidation | undefined = undefined;

        Object.keys(values).forEach((key: string) => {
            inputError = {
                error: false,
                errorText: "",
            };

            if (validations[key] === undefined) return;

            valueValidation = validations[key].find(
                (validation) => !validation.method(values[key], key),
            );

            if (valueValidation) {
                valid = false;
                inputError = {
                    error: true,
                    errorText: valueValidation.errorText,
                };
            }

            newErrorValues = {
                ...newErrorValues,
                [key]: inputError,
            };
        });
        setErrors(newErrorValues);

        return valid;
    };

    const handleSubmit = () => {
        const valid = validateForm();
        if (valid) {
            submitCallback(values);
        }
    };

    const handleChange = (name:string, value:any) => {
        setValues(values => ({ ...values, [name]: value }));

        let newError: TDPFormError = {
            error: false,
            errorText: "",
        };

        const valueValidations: ITDPValidation[] = validations[name];

        const valueValidation: ITDPValidation | undefined = valueValidations.find(
            (validation) => !validation.method(value, name),
        );

        if (valueValidation) {
            newError = {
                error: true,
                errorText: valueValidation.errorText,
            };
        }

        setErrors({
            ...errors,
            [name]: newError,
        });
    };

    const resetValues = (newValues:ITDPFormValues) => {
        setValues(newValues);
    }

    return {
        handleChange,
        handleSubmit,
        resetValues,
        values,
        errors,
    }
};

export default useTDPForm;
