import React, {useEffect} from 'react';

import './Statistics.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Chart} from "primereact/chart";
import {Card} from "primereact/card";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../redux/reducers";
import {statsRequest} from "../../../../redux/actions/Stats.actions";
import {Message} from "primereact/message";
import LoadingView from "../../../../components/LoadingView/LoadingView";

const chart1 = {
    options: {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#495057'
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor: '#495057'
                }
            }]
        }
    },
    data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'First Dataset',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: '#42A5F5'
            },
            {
                label: 'Second Dataset',
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: false,
                borderColor: '#FFA726'
            }
        ]
    }
};

const chart3 = {
    data:  {
        datasets: [{
            data: [
                11,
                16,
                7,
                3,
                14
            ],
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#FFA726",
                "#26C6DA",
                "#7E57C2"
            ],
            label: 'My dataset'
        }],
        labels: [
            "Red",
            "Green",
            "Yellow",
            "Grey",
            "Blue"
        ]
    },
    options: {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        },
        scale: {
            gridLines: {
                color: '#ebedef'
            }
        }
    }
};

function getUsersChartData(users: any) {
    let chartData = {
        labels: [] as any,
        datasets: [
            {
                label: 'Nuevos usuarios por mes',
                backgroundColor: '#42A5F5',
                data: [] as any
            },
        ]
    };

    const by_month = users.by_month;
    for (let i=0; i<by_month.length; ++i) {
        chartData.labels.push(by_month[i].month);
        chartData.datasets[0].data.push(by_month[i].count);
    }

    return chartData;
}


function getAgeRangesChartData(users: any) {
    // Rango edades
    let chartData = {
        labels: ['undisclosed','18-25','26-40','41-55','56-65','65+'],
        datasets: [
            {
                data: [0,0,0,0,0,0,0],
                backgroundColor: ['#FF6384','#36A2EB','#FFCE56','#46BFBD','#97BBCD','#F7464A','#4D5360'],
            }
        ]
    }

/*    for (let i = 0; i < chartData.labels.length; ++i) {
        let rangeVal = users['by_age'][chartData.labels[i]];
        if (rangeVal) {
            chartData.datasets[0].data[i] = rangeVal;
        }
    }*/

    return chartData;
}

const Statistics : React.FC = (props) => {
    const dispatch = useDispatch();
    const generalStatsState = useSelector((state:IAppState) => state.stats.general);

    useEffect(() => {
        dispatch(statsRequest());
    }, [dispatch]);

    if (generalStatsState.error) {
        return (
            <div className="container-fluid">
                <Message text={generalStatsState.error} severity="error" />
            </div>
        )
    }

    if (generalStatsState.waiting || generalStatsState.data == null) {
        return <LoadingView />;
    }

    return (
        <>
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">Estadísticas</h3>
            </nav>
            <div className="container-fluid">
                <div className="row small-cards-row">
                    <div className="col-xl-3 col-lg-6 pl-0">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Recetas</h5>
                                        <span className="h2 font-weight-bold mb-0">{generalStatsState.data.recipes}</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                            <FontAwesomeIcon icon="chart-bar" />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-success mr-2"><FontAwesomeIcon icon="arrow-up" /> 3.48%</span>
                                    <span className="text-nowrap">Desde el último mes</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 pl-1">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Estadística 2</h5>
                                        <span className="h2 font-weight-bold mb-0">2,356</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                            <FontAwesomeIcon icon="chart-pie" />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-danger mr-2"><FontAwesomeIcon icon="arrow-down" /> 3.48%</span>
                                    <span className="text-nowrap">Desde el último mes</span>
                                </p>
                            </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 pl-1">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Estadística 3</h5>
                                        <span className="h2 font-weight-bold mb-0">924</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                            <FontAwesomeIcon icon="users" />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-warning mr-2"><FontAwesomeIcon icon="arrow-down" /> 1.10%</span>
                                    <span className="text-nowrap">Desde ayer</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 pr-0">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Estadística 4</h5>
                                        <span className="h2 font-weight-bold mb-0">49,65%</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                            <FontAwesomeIcon icon="percent" />
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-success mr-2"><FontAwesomeIcon icon="arrow-up" /> 12%</span>
                                    <span className="text-nowrap">Desde el último mes</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="card col-xl-8 col-lg-12 mb-lg-3 p-1">
                        <h5 className="custom-card-title">Users</h5>
                        <Chart type="line" data={chart1.data} options={chart1.options} />
                    </div>
                    <div className="col-xl-3 offset-xl-1 col-lg-12 mb-lg-3 p-0">
                        <div className="row">
                            <div className="col-xl-12 col-lg-6">
                                <Card title="Usuarios" subTitle={`Total: ${generalStatsState.data.users.total}`}>
                                    <Chart type="doughnut" data={getAgeRangesChartData(generalStatsState.data.users)} />
                                </Card>
                            </div>
                            <div className="col-xl-12 col-lg-6">
                                <Card title="Grafico 3" subTitle="Algo que medir">
                                    <Chart type="polarArea" data={chart3.data} options={chart3.options} />
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card col-4">
                        <Chart type="bar" data={getUsersChartData(generalStatsState.data.users)} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Statistics;
