import {call, put, takeLatest } from "redux-saga/effects";
import {
    STATS_PROFILE_REQUEST,
    STATS_REQUEST,
    statsError,
    statsProfileError,
    statsProfileSuccess,
    statsSuccess
} from "../actions/Stats.actions";
import {getStatsProfileRequest, getStatsRequest} from "../../service/stats";

function* doGetStats(): any {
    try {
        const statsResult = yield call(getStatsRequest);

        if (statsResult.error) {
            switch (statsResult.error_code) {
                default:
                    yield put(statsError(`${statsResult.error_code}, ${statsResult.error_message}`));
                    return;
            }
        }

        if (statsResult.data) {
            yield put(statsSuccess(statsResult.data));
            return;
        }

        yield put(statsError('Error retrieving stats'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(statsError(error_message))
    }
}

function* doGetStatsProfile(): any {
    try {
        const statsProfileResponse = yield call(getStatsProfileRequest);

        if (statsProfileResponse.error) {
            switch (statsProfileResponse.error_code) {
                default:
                    yield put(statsProfileError(`${statsProfileResponse.error_code}, ${statsProfileResponse.error_message}`));
                    return;
            }
        }

        if (statsProfileResponse.data) {
            yield put(statsProfileSuccess(statsProfileResponse.data));
            return;
        }

        yield put(statsProfileError('Error retrieving profile stats'));
    } catch (error) {
        const error_message = (error.message) ? error.message : error;
        yield put(statsProfileError(error_message))
    }
}

const StatsSagas = [
    takeLatest(STATS_REQUEST, doGetStats),
    takeLatest(STATS_PROFILE_REQUEST, doGetStatsProfile),
];

export default StatsSagas;
