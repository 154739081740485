import {IRecipe} from "../../service/models/recipes";

export const RECIPES_LIST_REQUEST = 'RECIPES_LIST_REQUEST';
export function recipesListRequest() {
    return {
        type: RECIPES_LIST_REQUEST,
    };
}

export const RECIPES_LIST_RECEIVED = 'RECIPES_LIST_RECEIVED';
export function recipesListReceived(payload:any) {
    return {
        type: RECIPES_LIST_RECEIVED,
        payload,
    };
}

export const RECIPES_LIST_ERROR = 'RECIPES_LIST_ERROR';
export function recipesListError(error:string) {
    return {
        type: RECIPES_LIST_ERROR,
        payload: error
    };
}

// Update Recipe in List
export const RECIPES_LIST_UPDATE_ITEM = 'RECIPES_LIST_UPDATE_ITEM';
export function recipesListUpdateItem(payload:IRecipe) {
    return {
        type: RECIPES_LIST_UPDATE_ITEM,
        payload
    }
}

// Delete Recipe from List
export const RECIPES_LIST_REMOVE_ITEM = 'RECIPES_LIST_REMOVE_ITEM';
export function recipesListRemoveItem(recipe_id:number) {
    return {
        type: RECIPES_LIST_REMOVE_ITEM,
        payload: recipe_id
    }
}
