import React, {useEffect, useRef, useState} from 'react';

import './AllergiesList.scss';
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../../redux/reducers";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Message} from "primereact/message";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import {allergiesListRemoveItem, allergiesListRequest} from "../../../../../redux/actions/Allergies.actions";
import {removeAllergyRequest} from "../../../../../service/allergies";

interface AllergiesListProps {
    history: any;
}

const AllergiesList : React.FC<AllergiesListProps> = (props) => {
    const dispatch = useDispatch();

    const toastRef = useRef<Toast>(null);
    const dataTableRef = useRef(null);
    const allergiesState = useSelector((state:IAppState) => state.allergies);

    const [deleteAllergyState, setDeleteAllergyState] = useState({
        visible: false,
        waiting: false,
        name: '',
        id: -1,
    });

    useEffect(() => {
        dispatch(allergiesListRequest());
    }, [dispatch]);

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    function hideDeleteAllergyDialog() {
        setDeleteAllergyState({...deleteAllergyState, visible: false});
    }

    function deleteAllergy() {
        setDeleteAllergyState({...deleteAllergyState, waiting: true});
        removeAllergyRequest(deleteAllergyState.id).then(res => {
            setDeleteAllergyState({...deleteAllergyState, waiting: false});
            if (res.error) {
                showToast('Error eliminando alergia', res.error_message, 'error');
                return;
            }
            dispatch(allergiesListRemoveItem(deleteAllergyState.id));
            setDeleteAllergyState({...deleteAllergyState, visible: false});
        }).catch(err => {
            setDeleteAllergyState({...deleteAllergyState, waiting: false});
        });
    }

    const deleteAllergyDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAllergyDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteAllergy} />
        </>
    );


    function actionBodyTemplate(rowData: any, columnProps: any) {
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={() => {
                    props.history.push('./edit', {id: rowData.id});
                }}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                        disabled={deleteAllergyState.waiting}
                        onClick={() => {
                            setDeleteAllergyState({
                                ...deleteAllergyState,
                                visible: true,
                                name: rowData.name,
                                id: rowData.id,
                            });
                        }}/>
            </>
        );
    }

    if (allergiesState.error) {
        return (
            <div className="container-fluid">
                <Message text={allergiesState.error} severity="error" />
            </div>
        )
    }

    if (allergiesState.waiting) {
        return <LoadingView />;
    }

    return (
        <div id="Ingredients">
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">Gestión de <span className="text-halen-bold">Alergias</span></h3>
            </nav>
            <div className="container-fluid">
                <Toast ref={toastRef} position="top-right" />
                <div className="row pb-3 align-items-center justify-content-between">
                    <Button label="Crear alergia" className="col-3 offset-9 p-button-raised p-button-rounded align-self-end"
                            onClick={() => props.history.push('./edit', {isNew: true})} />
                </div>
                <div className="row tables-row">
                    <div className="col-12 h-100">
                        <DataTable ref={dataTableRef} value={allergiesState.data || []} className="p-datatable-striped h-100"
                                   tableClassName="h-100"  paginator rows={10} dataKey="id"
                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10,20,50,100,200,500,1000]}>
                            <Column field="name" header="Nombre" filter filterPlaceholder="Buscar por nombre"
                                    filterMatchMode="contains" sortable style={{width: '75%'}}/>
                            <Column body={actionBodyTemplate} style={{width: '25%'}}/>
                        </DataTable>
                    </div>
                </div>

                <Dialog visible={deleteAllergyState.visible} style={{ width: '450px' }} header="Confirmar" modal
                        footer={deleteAllergyDialogFooter} onHide={hideDeleteAllergyDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {deleteAllergyState.name && <span>¿Estás seguro de borrar <b>{deleteAllergyState.name}</b>?</span>}
                        {!deleteAllergyState.name && <span>¿Estás seguro de borrar esta fila?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default AllergiesList;
