import React, {useEffect, useRef, useState} from 'react';

import './EditAllergy.scss';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {DataTable, DataTableRowEditParams} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {useDispatch} from "react-redux";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {ProgressSpinner} from "primereact/progressspinner";
import {Toast} from "primereact/toast";
import {ITDPFormValidations} from "../../../../../hooks/tdp-form/tdp-form-types";
import {
    ArrayValidation,
    RequiredValidation
} from "../../../../../hooks/tdp-form/tdp-form-validations";
import useTDPForm from "../../../../../hooks/tdp-form/tdp-form";
import FlagsDropdown from "../../../../../components/FlagsDropdown/FlagsDropdown";
import {AvailableLanguages} from "../../../../../helpers/locales";
import {IAllergy, IAllergyTranslation} from "../../../../../service/models/allergy";
import {allergiesListAddItem, allergiesListUpdateItem} from "../../../../../redux/actions/Allergies.actions";
import {createAllergyRequest, getAllergyInfo, saveAllergyInfo} from "../../../../../service/allergies";

const DEFAULT_TRANSLATION_STATE: IAllergyTranslation = {
    locale: '',
    name: '',
}

interface IAllergyForm extends IAllergy {
    translations: IAllergyTranslation[],
}

const formInitialValues: IAllergyForm = {
    id: undefined,
    tag: undefined,
    translations: [],
}

const formValidations: ITDPFormValidations<IAllergyForm> = {
    id: [],
    tag: [RequiredValidation({errorText: 'El tag es obligatorio'})],
    translations: [ArrayValidation({errorText: 'Al menos una traducción es obligatoria'})],
};

interface EditAllergyProps {
    history: any;
}

function getLanguage(locale:string) {
    return AvailableLanguages.find(item => item.code === locale);
}

function getTranslationName(locale:string) {
    const item = AvailableLanguages.find(item => item.code === locale);
    return (item) ? item.name : '';
}

const EditAllergy: React.FC<EditAllergyProps> = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [translationOriginalRow, setTranslationOriginalRow] = useState<IAllergyTranslation>(DEFAULT_TRANSLATION_STATE);

    const onSubmit = async (submittedValues:IAllergyForm) => {
        const toastTitle = 'Error guardando alergia';

        // check translations
        const localeSeen = new Set();
        for (let i=0; i<submittedValues.translations.length; ++i) {
            const locale = submittedValues.translations[i]?.locale;
            if (!locale) {
                showToast(toastTitle, 'La información de la traducción número '+(i+1)+' no está completa', 'warn');
                return;
            }
            if (localeSeen.has(locale)) {
                const localeName = getTranslationName(locale);
                showToast(toastTitle, 'La información de la traducción para "'+(localeName ? localeName : locale)+'" está duplicada. Elimine una fila', 'warn');
                return;
            }
            localeSeen.add(locale);
        }

        const isNew = (submittedValues.id === undefined);
        const operation = (isNew) ? createAllergyRequest : saveAllergyInfo;

        setSaving(true);

        try {
            // @ts-ignore
            const saveAllergyResponse = await operation({
                ...submittedValues,
            });

            if (saveAllergyResponse.error) {
                setSaving(false);
                showToast(toastTitle, saveAllergyResponse.error_message, 'error');
                return;
            }

            if (isNew) {
                form.handleChange('id', saveAllergyResponse.data?.id);
                submittedValues.id = saveAllergyResponse.data?.id;
                dispatch(allergiesListAddItem(submittedValues))
            } else {
                dispatch(allergiesListUpdateItem(submittedValues));
            }

            setSaving(false);
            showToast('Guardar Alergia', 'OK!', 'success');
        } catch (err) {
            setSaving(false);
            showToast(toastTitle, err.message,'error');
        }
    }

    const form = useTDPForm(formInitialValues, formValidations, onSubmit);
    const [deleteTranslationState, setDeleteTranslationState] = useState({
        visible: false,
        index: -1,
        locale: '',
    });

    useEffect(() => {
       // load allergy info
       if (props.history.location.state) {
           const { id } = props.history.location.state;
           if (id !== undefined) {
               getAllergyInfo(id).then(res => {
                   setLoading(false);
                   if (res.error) {
                       return;
                   }
                   form.resetValues({...form.values, ...res.data});
               }).catch(err => {
                   setLoading(false);
               });
           } else {
               // new allergy?
               setLoading(false);
           }
       }
        // eslint-disable-next-line
   }, [props.history.location.state]);

    const toastRef = useRef<Toast>(null);

    if (loading) {
        return <LoadingView />;
    }

    function onTranslationRowEditInit(ev: DataTableRowEditParams) {
        setTranslationOriginalRow({...form.values.translations[ev.index]});
    }

    function onTranslationRowEditCancel(ev: DataTableRowEditParams) {
        let translations = [...form.values.translations];
        translations[ev.index] = translationOriginalRow;
        setTranslationOriginalRow(DEFAULT_TRANSLATION_STATE);
        form.handleChange('translations', translations);
    }

    function onTranslationChangeValue(props: any, value: any) {
        let updatedTranslations = [...props.value];
        updatedTranslations[props.rowIndex][props.field] = value;
        form.handleChange('translations', updatedTranslations);
    }

    function translationNameEditor(props: any) {
        return <InputText type="text" className="w-100" value={props.rowData.name}
                          onChange={(e) => onTranslationChangeValue(props, e.currentTarget.value)}/>;
    }

    function translationLocaleEditor(props: any) {
        return (
            <FlagsDropdown value={getLanguage(props.rowData.locale)}
                           className="w-100 form-dropdown"
                           onChange={(e) => onTranslationChangeValue(props, e?.code)}
            />
        );
    }

    function localeBodyTemplate(rowData: any) {
        if (rowData !== undefined && rowData.locale) {
            return (
                <div className="language-item language-item-value">
                    <span className={`flag flag-${rowData.locale.toLowerCase()}`} />
                    <div>{getTranslationName(rowData.locale)}</div>
                </div>
            );
        }
        return '';
    }

    function actionTranslationBodyTemplate(rowData:any, props:any) {
        if (rowData?.isNew && !props.editing) {
            delete rowData.isNew;
            props.onRowEditInit({preventDefault: () => {}});
            return;
        }
        if (props.editing) {
            return (
                <>
                    <Button icon="pi pi-check" disabled={!rowData.locale || !rowData.name} className="p-button-rounded p-button-success mr-1" onClick={props.onRowEditSave}/>
                    <Button icon="pi pi-times" className="p-button-rounded p-button-warning" onClick={props.onRowEditCancel}/>
                </>
            );
        }
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={props.onRowEditInit}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
                    setDeleteTranslationState({
                        visible: true,
                        index: props.rowIndex,
                        locale: rowData.locale,
                    });
                }} />
            </>
        );
    }

    function deleteTranslation() {
        let newTranslations = form.values.translations;
        newTranslations.splice(deleteTranslationState.index, 1);
        form.handleChange('translations', newTranslations);
        setDeleteTranslationState({
            index: -1,
            locale: '',
            visible: false
        })
    }

    function hideDeleteTranslationDialog() {
        setDeleteTranslationState({...deleteTranslationState, visible: false});
    }

    const deleteTranslationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTranslationDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTranslation} />
        </>
    );

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    return (
        <div id="EditAllergy">
            <div className="container-fluid">
                <div className="col-xl-12 order-xl-1 m-3">
                    <Toast ref={toastRef} position="top-right" />
                    <div className="card bg-secondary shadow">
                        <div className="card-header bg-white border-0">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h3 className="mb-0">Alergia</h3>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="heading-small text-muted mb-4">Información básica</h6>
                            <div className="pl-lg-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-tag">Tag</label>
                                            <InputText id="input-tag" className="form-control form-control-alternative"
                                                       placeholder="Tag" value={form.values.tag}
                                                       onChange={(e) =>
                                                           form.handleChange('tag', e.currentTarget.value)
                                                       }/>
                                            {form.errors.tag?.error ?
                                                <small className="p-invalid">{form.errors.tag.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h6 className="heading-small text-muted mb-4">Traducciones</h6>
                                <DataTable value={form.values.translations} editMode="row" dataKey="locale"
                                           onRowEditInit={onTranslationRowEditInit} onRowEditCancel={onTranslationRowEditCancel}>
                                    <Column field="locale" header="Idioma" style={{width:'25%'}} body={localeBodyTemplate} editor={(props) => translationLocaleEditor(props)}/>
                                    <Column field="name" header="Nombre" editor={(props) => translationNameEditor(props)} style={{width:'65%'}}/>
                                    <Column body={actionTranslationBodyTemplate} headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}} style={{width:'10%'}}/>
                                </DataTable>

                                {form.errors.translations.error ?
                                    <small className="p-invalid">{form.errors.translations.errorText}</small>
                                    : null
                                }

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center mt-3">
                                            <Button label="Añadir traducción"
                                                    className="p-button-warning p-button-raised p-button-rounded"
                                                    onClick={() =>
                                                        form.handleChange('translations', [...form.values.translations, {locale: undefined, name:'', isNew: true}])
                                                    }/>
                                        </div>
                                    </div>
                                </div>

                                <Dialog visible={deleteTranslationState.visible} style={{ width: '450px' }} header="Confirmar" modal footer={deleteTranslationDialogFooter} onHide={hideDeleteTranslationDialog}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                                        {deleteTranslationState.locale && <span>¿Estás seguro de borrar la traducción para <b>{deleteTranslationState.locale}</b>?</span>}
                                        {!deleteTranslationState.locale && <span>¿Estás seguro de borrar esta fila?</span>}
                                    </div>
                                </Dialog>
                            </div>
                            <div className="my-3">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-right">
                                            <Button label="Guardar alergia" className="p-button-raised p-button-rounded"
                                                disabled={saving} onClick={form.handleSubmit}>
                                                {saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditAllergy;
