import React from 'react';

import './Roles.scss';


const Roles : React.FC = (props) => {
    return (
        <div>
            Gestión de Roles
        </div>
    );
}

export default Roles;
