import React, {useEffect, useRef, useState} from 'react';

import './Users.scss';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../redux/reducers";
import {usersListRequest} from "../../../../redux/actions/Users.actions";
import {Dropdown} from "primereact/dropdown";
import LoadingView from "../../../../components/LoadingView/LoadingView";
import {Message} from "primereact/message";

interface UsersProps {
}

const statuses = [
    'initial', 'confirmed', 'free_period', 'free_expired', 'paid'
]

const statusBodyTemplate = (rowData:any) => {
    return <span className={`status-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
}

const statusItemTemplate = (status:any) => {
    return <span className={`status-badge status-${status.toLowerCase()}`}>{status}</span>;
}

const Users : React.FC<UsersProps> = (props) => {
    const dispatch = useDispatch();
    const [selectedStatus, setSelectedStatus] = useState(null);

    const usersState = useSelector((state:IAppState) => state.users);

    const dataTableRef = useRef<DataTable>(null);

    useEffect(() => {
        dispatch(usersListRequest());
    }, [dispatch]);

    if (usersState.error) {
        return (
            <div className="container-fluid">
                <Message text={usersState.error} severity="error" />
            </div>
        )
    }

    if (usersState.waiting || usersState.data == null) {
        return <LoadingView />;
    }

    function onStatusFilterChange(value: any) {
        if (dataTableRef.current) {
            dataTableRef.current.filter(value, 'status', 'equals');
            setSelectedStatus(value);
        }
    }

    const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={(e) => onStatusFilterChange(e.value)} itemTemplate={statusItemTemplate} placeholder="Elige un status" className="p-column-filter" showClear />;

    return (
        <div id="Users">
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">Usuarios</h3>
            </nav>
            <div className="container-fluid">
                <div className="row">
                    <DataTable ref={dataTableRef} value={usersState.data} className="p-datatable-striped" paginator rows={10}>
                        <Column field="name" header="Nombre" filter filterPlaceholder="Buscar por nombre" filterMatchMode="contains" sortable />
                        <Column field="joined_at" header="Fecha de incorporación" sortable />
                        <Column field="email" header="Dirección de email" filter filterPlaceholder="Buscar por email" filterMatchMode="contains" sortable />
                        <Column field="country" header="País" filter filterPlaceholder="Buscar por país" filterMatchMode="contains" sortable />
                        <Column field="status" header="Tipo" body={statusBodyTemplate} filter filterElement={statusFilter} />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default Users;
