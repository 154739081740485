import React, {useEffect, useRef, useState} from 'react';

import './EditRecipe.scss';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {DataTable, DataTableRowEditParams} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {MultiSelect} from "primereact/multiselect";
import {
    IRecipeIngredient,
    IStep,
    RecipeDayMoments,
    RecipeSeasons
} from "../../../../../service/models/recipes";
import {useDispatch, useSelector} from "react-redux";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {createRecipeRequest, getRecipeInfo, saveRecipeInfo, updateRecipeImage} from "../../../../../service/recipes";
import {ProgressSpinner} from "primereact/progressspinner";
import {Toast} from "primereact/toast";
import {recipesListUpdateItem} from "../../../../../redux/actions/Recipes.actions";
import {ITDPFormValidations} from "../../../../../hooks/tdp-form/tdp-form-types";
import {
    RequiredValidation,
    PositiveNumberValidation,
    PositiveOrZeroNumberValidation
} from "../../../../../hooks/tdp-form/tdp-form-validations";
import useTDPForm from "../../../../../hooks/tdp-form/tdp-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons/faUpload";
import {AutoComplete} from "primereact/autocomplete";
import {ingredientsListRequest} from "../../../../../redux/actions/Ingredients.actions";
import {IAppState} from "../../../../../redux/reducers";
import FlagsDropdown, {TFlagChoice} from "../../../../../components/FlagsDropdown/FlagsDropdown";
import {InputSwitch} from "primereact/inputswitch";
import {MeasureValues} from "../../../../../service/models/ingredient";

const DEFAULT_INGREDIENT_STATE: IRecipeIngredient = {
    id: -1,
    name: '',
    quantity: 0,
    measure: '',
}

function measureBodyTemplate(rowData: any) {
    if (rowData !== undefined && rowData.measure) {
        const item = MeasureValues.find(item => item.id === rowData.measure);
        if (item !== undefined) {
            return item.label;
        }
    }
    return '';
}

function getNewStepId(steps:IStep[]) {
    const max = steps.reduce((prev, current) => (prev > current.id) ? prev : current.id, 0)
    return max+1;
}

interface IEditRecipeForm {
    id: number|undefined
    title: string
    language: TFlagChoice
    description: string
    legal: boolean
    image: string
    moments: string[]
    seasons: string[]
    elaboration_time: number
    people: number
    ingredients: IRecipeIngredient[]
    steps: IStep[]
    is_public: boolean
    kcals: number|undefined
    protein: number|undefined
    carbohydrate_starch: number|undefined
    carbohydrate_sugar: number|undefined
    fiber: number|undefined
    monounsaturated_fat: number|undefined
    polyunsaturated_fat: number|undefined
    saturated_fat: number|undefined
    trans_fat: number|undefined
    cholesterol: number|undefined
    iron: number|undefined
    sodium: number|undefined
    potassium: number|undefined
}

const formInitialValues: IEditRecipeForm = {
    id: undefined,
    title: '',
    language: {
        name: 'Español',
        code: 'ES',
    },
    description: '',
    legal: false,
    image: '',
    moments: [],
    seasons: [],
    elaboration_time: 0,
    people: 1,
    ingredients: [],
    steps: [],
    is_public: false,
    kcals: 0,
    protein: 0,
    carbohydrate_starch: 0,
    carbohydrate_sugar: 0,
    fiber: 0,
    monounsaturated_fat: 0,
    polyunsaturated_fat: 0,
    saturated_fat: 0,
    trans_fat: 0,
    cholesterol: 0,
    iron: 0,
    sodium: 0,
    potassium: 0,
}

const formValidations: ITDPFormValidations<IEditRecipeForm> = {
    id: [],
    title: [RequiredValidation({errorText: 'El nombre es obligatorio'})],
    language: [],
    description: [RequiredValidation({errorText: 'La descripcion es obligatoria'})],
    legal: [],
    image: [],
    moments: [],
    seasons: [],
    elaboration_time: [RequiredValidation({errorText: 'El tiempo es obligatorio'}),
                    PositiveNumberValidation({errorText: 'El tiempo no puede ser negativo o cero'})],
    people: [RequiredValidation({errorText: 'El num. de personas es obligatorio'}),
        PositiveNumberValidation({errorText: 'El num. de personas no puede ser negativo o cero'})],
    ingredients: [],
    steps: [],
    is_public: [],
    kcals: [PositiveOrZeroNumberValidation({errorText: 'Las kilocalorías no pueden ser negativas'})],
    protein: [PositiveOrZeroNumberValidation({errorText: 'Las proteínas no pueden ser negativas'})],
    carbohydrate_starch: [PositiveOrZeroNumberValidation({errorText: 'Los carbohidratos no pueden ser negativos'})],
    carbohydrate_sugar: [PositiveOrZeroNumberValidation({errorText: 'Los carbohidratos no pueden ser negativos'})],
    fiber: [PositiveOrZeroNumberValidation({errorText: 'La fibra no puede ser negativa'})],
    monounsaturated_fat: [PositiveOrZeroNumberValidation({errorText: 'Las grasas monoinsaturadas no pueden ser negativas'})],
    polyunsaturated_fat: [PositiveOrZeroNumberValidation({errorText: 'Las grasas poli-insaturadas no pueden ser negativas'})],
    saturated_fat: [PositiveOrZeroNumberValidation({errorText: 'Las grasas saturadas no pueden ser negativas'})],
    trans_fat: [PositiveOrZeroNumberValidation({errorText: 'Las grasas trans no pueden ser negativas'})],
    cholesterol: [PositiveOrZeroNumberValidation({errorText: 'El colesterol no pueden ser negativo'})],
    iron: [PositiveOrZeroNumberValidation({errorText: 'El hierro no puede ser negativo'})],
    sodium: [PositiveOrZeroNumberValidation({errorText: 'El sodio no puede ser negativo'})],
    potassium: [PositiveOrZeroNumberValidation({errorText: 'El potasio no puede ser negativo'})],
};

interface EditRecipeProps {
    history: any;
}

const EditRecipe: React.FC<EditRecipeProps> = (props) => {
    const dispatch = useDispatch();

    const ingredientsListState = useSelector((state:IAppState) => state.ingredients.list);

    const [filteredIngredients, setFilteredIngredients] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [originalRow, setOriginalRow] = useState<IRecipeIngredient>(DEFAULT_INGREDIENT_STATE);
    const [fixNutritionalInfo, setFixNutritionalInfo] = useState<boolean>(false);
    const [newImage, setNewImage] = useState<any>(null);
    const [saving, setSaving] = useState<boolean>(false);

    function searchIngredient(e:any) {
        if (!e.query.trim().length) {
            setFilteredIngredients(ingredientsListState.data);
            return;
        }

        const lcQueryString = e.query.toLowerCase();
        setFilteredIngredients(ingredientsListState.data.filter((ingredient:IRecipeIngredient) => {
            return ingredient?.name && ingredient.name.toLowerCase().indexOf(lcQueryString)>=0;
        }));
    }

    function onUploadSelect(e:any) {
        const files = e.target.files;
//        console.log("UploadSelect E:", e, " Files:", files);

        if (!files || files.length < 1) {
            return;
        }

        const imageFile = e.target.files[0];

        if (e.target.labels && e.target.labels.length>0) {
            e.target.labels[0].innerText = imageFile.name;
        }

        if (form.values.image) {
            URL.revokeObjectURL(form.values.image);
        }
        form.handleChange('image', URL.createObjectURL(imageFile));
        setNewImage(imageFile);
    }

    const onSubmit = async (submittedValues:IEditRecipeForm) => {
        const toastTitle = 'Error guardando receta';

        // check legal
        if (newImage != null) {
            if (!submittedValues.legal) {
                showToast(toastTitle, 'Debes aceptar las condiciones legales', 'warn');
                return;
            }
        }

        // check ingredients
        for (let i=0; i<submittedValues.ingredients.length; ++i) {
            if (!submittedValues.ingredients[i].id) {
                showToast(toastTitle, 'La información del ingrediente número '+(i+1)+" no está completa", 'warn');
                return;
            }

            if (!submittedValues.ingredients[i].quantity.toString().match(/^\d+(\.\d*)?$/)) {
                showToast(toastTitle, 'La cantidad del ingrediente número '+(i+1)+" es incorrecta", 'warn');
                return;
            }
        }

        const isNew = (submittedValues.id === undefined);
        const operation = (isNew) ? createRecipeRequest : saveRecipeInfo;

        setSaving(true);

        try {
            let newValues: any = {
                id: submittedValues.id,
                description: submittedValues.description,
                elaboration_time: submittedValues.elaboration_time,
                title: submittedValues.title,
                language: submittedValues.language.code,
                ingredients: submittedValues.ingredients,
                steps: submittedValues.steps,
                moments: submittedValues.moments,
                seasons: submittedValues.seasons,
                is_public: submittedValues.is_public,
                people: submittedValues.people,
            }

            if (fixNutritionalInfo) {
                // remap again nutritional info
                newValues.nutrition_facts = {
                    kcals: submittedValues.kcals,
                    protein: submittedValues.protein,
                    carbohydrate_starch: submittedValues.carbohydrate_starch,
                    carbohydrate_sugar: submittedValues.carbohydrate_sugar,
                    fiber: submittedValues.fiber,
                    monounsaturated_fat: submittedValues.monounsaturated_fat,
                    polyunsaturated_fat: submittedValues.polyunsaturated_fat,
                    saturated_fat: submittedValues.saturated_fat,
                    trans_fat: submittedValues.trans_fat,
                    cholesterol: submittedValues.cholesterol,
                    iron: submittedValues.iron,
                    sodium: submittedValues.sodium,
                    potassium: submittedValues.potassium,
                };
            }

            // @ts-ignore
            const saveRecipeResponse = await operation(newValues);

            if (saveRecipeResponse.error) {
                setSaving(false);
                showToast(toastTitle, saveRecipeResponse.error_message, 'error');
                return;
            }

            let recipeId;
            if (isNew) {
                form.handleChange('id', saveRecipeResponse.data?.id);
                recipeId = saveRecipeResponse.data?.id;
            } else {
                // @ts-ignore
                dispatch(recipesListUpdateItem(submittedValues));
                recipeId = submittedValues.id;
            }

            // update nutritional info if exists on response
            if (saveRecipeResponse.data?.nutrition_facts) {
                const facts = saveRecipeResponse.data.nutrition_facts;
                form.handleChange('kcals', facts.kcals);
                form.handleChange('protein', facts.protein);
                form.handleChange('carbohydrate_starch', facts.carbohydrate_starch);
                form.handleChange('carbohydrate_sugar', facts.carbohydrate_sugar);
                form.handleChange('fiber', facts.fiber);
                form.handleChange('monounsaturated_fat', facts.monounsaturated_fat);
                form.handleChange('polyunsaturated_fat', facts.polyunsaturated_fat);
                form.handleChange('saturated_fat', facts.saturated_fat);
                form.handleChange('trans_fat', facts.trans_fat);
                form.handleChange('cholesterol', facts.cholesterol);
                form.handleChange('iron', facts.iron);
                form.handleChange('sodium', facts.sodium);
                form.handleChange('potassium', facts.potassium);
            }

            if (newImage != null && recipeId) {
                const formData = new FormData();
                formData.append('file', newImage);

                // update image
                const updateImageResponse = await updateRecipeImage(recipeId, formData);
                if (updateImageResponse.error) {
                    setSaving(false);
                    console.log('Error saving image', updateImageResponse);
                    showToast('Guardar Receta', toastTitle, 'error');
                    return;
                }

                // update URLs
                if (updateImageResponse.data) {
                    // @ts-ignore
                    dispatch(recipesListUpdateItem({
                        id: recipeId,
                        image: updateImageResponse.data.image,
                        thumb: updateImageResponse.data.thumb,
                    }));
                    form.handleChange('image', updateImageResponse.data.image);
                }
            }

            setSaving(false);
            showToast('Guardar Receta', 'OK!', 'success');
        } catch (err) {
            console.log('Error guardando receta', err);
            setSaving(false);
            showToast(toastTitle, err.message,'error');
        }
    }

    const form = useTDPForm(formInitialValues, formValidations, onSubmit);

    const [deleteIngredientState, setDeleteIngredientState] = useState({
        visible: false,
        index: -1,
        name: '',
    });

    const [deleteStepState, setDeleteStepState] = useState({
        visible: false,
        index: -1,
    });

    useEffect(() => {
       dispatch(ingredientsListRequest());

       // load recipe info
       if (props.history.location.state) {
           const { id } = props.history.location.state;
           if (id !== undefined) {
               getRecipeInfo(id).then(res => {
                   setLoading(false);
                   if (res.error) {
                       return;
                   }
                   const recipeValues = {
                       ...res.data,
                       ...res.data?.nutrition_facts, // map nutritional info to plain form object and remove this
                   };
                   delete recipeValues.nutrition_facts;
                   form.resetValues({...form.values, ...recipeValues});
               }).catch(err => {
                   setLoading(false);
               });
           } else {
               // new recipe?
               setLoading(false);
           }
       }
        // eslint-disable-next-line
   }, [props.history.location.state]);

    const toastRef = useRef<Toast>(null);

    if (loading || ingredientsListState.waiting) {
        return <LoadingView />;
    }

    function onIngredientRowEditInit(ev: DataTableRowEditParams) {
        setOriginalRow({...form.values.ingredients[ev.index]});
    }

    function onIngredientRowEditCancel(ev: DataTableRowEditParams) {
        let ingredients = [...form.values.ingredients];
        ingredients[ev.index] = originalRow;
        setOriginalRow(DEFAULT_INGREDIENT_STATE);
        form.handleChange('ingredients', ingredients);
    }

    function onIngredientChange(editProps:any, id:number|undefined, name:string) {
        let updatedIngredients = [...editProps.value];
        updatedIngredients[editProps.rowIndex]['id'] = id;
        updatedIngredients[editProps.rowIndex]['name'] = name;
        form.handleChange('ingredients', updatedIngredients);
    }

    function onEditorValueChange(editProps: any, value: any) {
        let updatedIngredients = [...editProps.value];
        updatedIngredients[editProps.rowIndex][editProps.field] = value;
        form.handleChange('ingredients', updatedIngredients);
    }

    function nameEditor(props: any) {
        return <AutoComplete dropdown className="w-100" value={props.rowData['name']} suggestions={filteredIngredients}
                             field="name" minLength={3} completeMethod={searchIngredient} autoFocus={!props?.rowData.name}
                             onChange={(e) =>
                                 onIngredientChange(props, undefined, e.value)
                             }
                            onSelect={(e) =>
                                onIngredientChange(props, e.value.id, e.value.name)
                            } panelStyle={{overflowX: 'scroll', zIndex: 1200}}/>;
    }

    function quantityEditor(props: any) {
        return <InputText keyfilter='pnum' className="w-100" value={props.rowData['quantity']}
                          onChange={(e) => onEditorValueChange(props, e.currentTarget.value)}
                          inputMode={"decimal"}
        />
    }

    function measureEditor(props: any) {
        return (
            <Dropdown value={props.rowData['measure']} options={MeasureValues} optionLabel="label" optionValue="id"
                      onChange={(e) => onEditorValueChange(props, e.value)} style={{width: '100%'}}
                      placeholder="Escoge una medida"
                      itemTemplate={(option) => {
                          return <span>{option.label}</span>
                      }}/>
        );
    }

    function deleteIngredient() {
        let newIngredients = form.values.ingredients;
        newIngredients.splice(deleteIngredientState.index, 1);
        form.handleChange('ingredients', newIngredients);
        setDeleteIngredientState({
            index: -1,
            name: '',
            visible: false
        })
    }

    function hideDeleteIngredientDialog() {
        setDeleteIngredientState({...deleteIngredientState, visible: false});
    }

    const deleteIngredientDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteIngredientDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteIngredient} />
        </>
    );

    function deleteStep () {
        let newSteps = form.values.steps;
        newSteps.splice(deleteStepState.index, 1);
        form.handleChange('steps', newSteps);
        setDeleteStepState({
            index: -1,
            visible: false
        })
    }

    function hideDeleteStepDialog() {
        setDeleteStepState({...deleteStepState, visible: false});
    }

    const deleteStepDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteStepDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteStep} />
        </>
    );

    function actionBodyTemplate(rowData:any, props:any) {
        if (rowData?.isNew && !props.editing) {
            delete rowData.isNew;
            props.onRowEditInit({preventDefault: () => {}});
            return;
        }
        if (props.editing) {
            return (
                <>
                    <Button icon="pi pi-check" disabled={!rowData.id} className="p-button-rounded p-button-success mr-1" onClick={props.onRowEditSave}/>
                    <Button icon="pi pi-times" className="p-button-rounded p-button-warning" onClick={props.onRowEditCancel}/>
                </>
            );
        }
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={props.onRowEditInit}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
                    setDeleteIngredientState({
                        visible: true,
                        index: props.rowIndex,
                        name: rowData.name,
                    });
                }} />
            </>
        );
    }

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    return (
        <div id="EditRecipe">
            <div className="container-fluid">
                <div className="col-xl-12 order-xl-1 m-3">
                    <Toast ref={toastRef} position="top-right" />
                    <div className="card bg-secondary shadow">
                        <div className="card-header bg-white border-0">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h3 className="mb-0">Características</h3>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="heading-small text-muted mb-4">Información básica</h6>
                            <div className="pl-lg-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-name">Nombre</label>
                                            <InputText id="input-name" className="form-control form-control-alternative"
                                                       placeholder="Nombre" value={form.values.title}
                                                       onChange={(e) =>
                                                           form.handleChange('title', e.currentTarget.value)
                                                       }/>
                                            {form.errors.title.error ?
                                                <small className="p-invalid">{form.errors.title.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="form-control-label"
                                                   htmlFor="input-language">Idioma</label>

                                            <FlagsDropdown value={form.values.language}
                                                           className="w-100 form-dropdown"
                                                           onChange={(e) => form.handleChange('language', e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group form-control-label">
                                            <label>Descripción</label>
                                            <InputTextarea className="form-control form-control-alternative" rows={3}
                                                           cols={30}
                                                           placeholder="Un par de líneas que describan la receta"
                                                           value={form.values.description}
                                                           onChange={(e) =>
                                                               form.handleChange('description', e.currentTarget.value)
                                                           }/>
                                            {form.errors.description.error ?
                                                <small className="p-invalid">{form.errors.description.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-first-name">Foto del
                                                plato</label><br/>
                                            <img src={form.values.image} className="recipe-image"  alt="Recipe"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="text-xl-left mb-3">
                                            <label className="p-button p-component p-fileupload-choose" htmlFor="image">
                                                <span><FontAwesomeIcon icon={faUpload} /> Escoger archivo</span>
                                            </label>
                                            <input type="file" id="image" accept="image/*" style={{'display': 'none'}} onChange={onUploadSelect}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 myb-3 align-self-center">
                                            <Checkbox inputId="legalCheck" onChange={e => {
                                                form.handleChange('legal', e.checked)
                                            }} checked={form.values.legal}/>
                                            <label htmlFor="legalCheck" className="mb-0 ml-2">
                                            <span className="text-muted">Acepto las condiciones legales al subir esta imagen</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-lg-6">
                                        <label className="form-control-label" htmlFor="input-first-name">Momento del
                                            día</label>
                                        <MultiSelect className="w-100" value={form.values.moments} options={RecipeDayMoments}
                                                  onChange={(e) => form.handleChange('moments', e.value)}
                                                  selectedItemsLabel="{0} elementos seleccionados"
                                                  placeholder="Momento del día"/>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="form-control-label" htmlFor="input-last-name">Estación</label>
                                        <MultiSelect className="w-100" value={form.values.seasons} options={RecipeSeasons}
                                                  onChange={(e) => form.handleChange('seasons', e.value)}
                                                  selectedItemsLabel="{0} elementos seleccionados"
                                                  placeholder="Estación"/>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="form-control-label" htmlFor="input-last-name">Tiempo de
                                            preparación (minutos)</label>
                                        <InputNumber className="w-100" format={false} max={2000} inputMode="number"
                                                     placeholder="Minutos en los que se tarda en hacer"
                                                     value={form.values.elaboration_time}
                                                     onValueChange={(e) =>
                                                         form.handleChange('elaboration_time', e.value)
                                                     }
                                                     mode="decimal"/>
                                        {form.errors.elaboration_time.error ?
                                            <small className="p-invalid">{form.errors.elaboration_time.errorText}</small>
                                            : null
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="form-control-label" htmlFor="input-last-name">Número de
                                            raciones</label>
                                        <InputNumber className="w-100" placeholder="Número de raciones"
                                                     value={form.values.people}
                                                     onValueChange={(e) =>
                                                         form.handleChange('people', e.value)
                                                     }
                                                     mode="decimal"/>
                                        {form.errors.people.error ?
                                            <small className="p-invalid">{form.errors.people.errorText}</small>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h6 className="heading-small text-muted mb-4">Ingredientes</h6>
                                <DataTable value={form.values.ingredients} editMode="row"
                                           onRowEditInit={onIngredientRowEditInit} onRowEditCancel={onIngredientRowEditCancel}>
                                    <Column field="name" header="Ingrediente" editor={(props) => nameEditor(props)} style={{width:'45%'}}/>
                                    <Column field="quantity" header="Cantidad" editor={(props) => quantityEditor(props)} style={{width:'15%'}}/>
                                    <Column field="measure" header="Medida" body={measureBodyTemplate} editor={(props) => measureEditor(props)} style={{width:'30%'}}/>
                                    <Column body={actionBodyTemplate} headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}} style={{width:'10%'}}/>
                                </DataTable>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center mt-3">
                                            <Button label="Añadir ingrediente"
                                                    className="p-button-warning p-button-raised p-button-rounded"
                                                    onClick={() =>
                                                        form.handleChange('ingredients', [...form.values.ingredients, {id: undefined, name:'', quantity:0, measure: '', isNew: true}])
                                                    }/>
                                        </div>
                                    </div>
                                </div>

                                <Dialog visible={deleteIngredientState.visible} style={{ width: '450px' }} header="Confirmar" modal footer={deleteIngredientDialogFooter} onHide={hideDeleteIngredientDialog}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                                        {deleteIngredientState.name && <span>¿Estás seguro de borrar <b>{deleteIngredientState.name}</b>?</span>}
                                        {!deleteIngredientState.name && <span>¿Estás seguro de borrar esta fila?</span>}
                                    </div>
                                </Dialog>
                            </div>
                            <div className="mt-4">
                                <h6 className="heading-small text-muted mb-4">Pasos</h6>
                                {form.values.steps.map((step:IStep, index:number) => {
                                    return (
                                    <div className="row ml-2" key={step.id}>
                                        <div className="col-lg-11">
                                            <div className="form-group form-control-label">
                                                <label>Paso {index+1}</label>
                                                <InputTextarea className="form-control form-control-alternative" rows={3}
                                                               cols={30} placeholder="Descripción del paso" value={form.values.steps[index].text}
                                                               onChange={(e) => {
                                                                   let newSteps = [...form.values.steps];
                                                                   newSteps[index].text = e.currentTarget.value;
                                                                   form.handleChange('steps', newSteps);
                                                               }} autoResize autoFocus={(index + 1 === form.values.steps.length) && !form.values.steps[index].text}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1 align-self-center">
                                            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined"
                                            onClick={() => {
                                                setDeleteStepState({
                                                    visible: true,
                                                    index,
                                                });
                                            }}/>
                                        </div>
                                    </div>);
                                })}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center">
                                            <Button label="Añadir paso"
                                                    className="p-button-warning p-button-raised p-button-rounded"
                                                    onClick={() => form.handleChange('steps', [...form.values.steps, {id: getNewStepId(form.values.steps), text:''}])
                                                    }/>
                                        </div>
                                    </div>
                                </div>
                                <Dialog visible={deleteStepState.visible} style={{ width: '450px' }} header="Confirmar" modal footer={deleteStepDialogFooter} onHide={hideDeleteStepDialog}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                                        <span>¿Estás seguro de borrar este paso?</span>
                                    </div>
                                </Dialog>
                            </div>
                            <div className="mt-4">
                                <h6 className="heading-small text-muted mb-4">Configuración de la Receta</h6>
                                <div className="pl-lg-4">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <span className="switch-label">Receta pública/visible</span>
                                                <InputSwitch checked={form.values.is_public}
                                                             onChange={(e) => form.handleChange('is_public', e.value)} />
                                                {form.errors.is_public.error ?
                                                    <small className="p-invalid">{form.errors.is_public.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h6 className="heading-small text-muted mb-4">Información nutricional</h6>
                                <div className="pl-lg-4">
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <span className="switch-label">Fijar información nutricional</span>
                                            <InputSwitch checked={fixNutritionalInfo}
                                                         onChange={(e) => setFixNutritionalInfo(e.value)} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-6">
                                            <label className="form-control-label" htmlFor="input-last-name">Kilocalorías</label>
                                            <InputNumber disabled={!fixNutritionalInfo} className="w-100" format={false}
                                                         max={2000} inputMode="number"
                                                         placeholder="Kilocalorías de la receta"
                                                         value={form.values.kcals}
                                                         onValueChange={(e) =>
                                                             form.handleChange('kcals', e.value)
                                                         }
                                                         mode="decimal"/>
                                            {form.errors.kcals.error ?
                                                <small className="p-invalid">{form.errors.kcals.errorText}</small>
                                                : null
                                            }
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-control-label" htmlFor="input-last-name">Proteínas</label>
                                            <InputNumber disabled={!fixNutritionalInfo} className="w-100"
                                                         placeholder="Proteínas" value={form.values.protein}
                                                         onValueChange={(e) =>
                                                             form.handleChange('protein', e.value)
                                                         }
                                                         mode="decimal"/>
                                            {form.errors.protein.error ?
                                                <small className="p-invalid">{form.errors.protein.errorText}</small>
                                                : null
                                            }
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-chstarch">Carbohydrate Starch (g)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-chstarch"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Carbohydrate Starch" value={form.values.carbohydrate_starch}
                                                           onChange={(e) =>
                                                               form.handleChange('carbohydrate_starch', e.currentTarget.value)
                                                           }/>
                                                {form.errors.carbohydrate_starch?.error ?
                                                    <small className="p-invalid">{form.errors.carbohydrate_starch?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-chsugar">Carbohydrate Sugar (g)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-chsugar"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Carbohydrate Sugar" value={form.values.carbohydrate_sugar}
                                                           onChange={(e) =>
                                                               form.handleChange('carbohydrate_sugar', e.currentTarget.value)
                                                           }/>
                                                {form.errors.carbohydrate_sugar?.error ?
                                                    <small className="p-invalid">{form.errors.carbohydrate_sugar?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-fiber">Fiber (g)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-fiber"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Fiber" value={form.values.fiber}
                                                           onChange={(e) =>
                                                               form.handleChange('fiber', e.currentTarget.value)
                                                           }/>
                                                {form.errors.fiber?.error ?
                                                    <small className="p-invalid">{form.errors.fiber?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-mono-fat">Monounsaturated Fat (g)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-mono-fat"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Monounsaturated fat" value={form.values.monounsaturated_fat}
                                                           onChange={(e) =>
                                                               form.handleChange('monounsaturated_fat', e.currentTarget.value)
                                                           }/>
                                                {form.errors.monounsaturated_fat?.error ?
                                                    <small className="p-invalid">{form.errors.monounsaturated_fat?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-poly-fat">Polyunsaturated Fat (g)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-poly-fat"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Polyunsaturated Fat" value={form.values.polyunsaturated_fat}
                                                           onChange={(e) =>
                                                               form.handleChange('polyunsaturated_fat', e.currentTarget.value)
                                                           }/>
                                                {form.errors.polyunsaturated_fat?.error ?
                                                    <small className="p-invalid">{form.errors.polyunsaturated_fat?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-saturated-fat">Saturated Fat (g)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-saturated-fat"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Saturated fat" value={form.values.saturated_fat}
                                                           onChange={(e) =>
                                                               form.handleChange('saturated_fat', e.currentTarget.value)
                                                           }/>
                                                {form.errors.saturated_fat?.error ?
                                                    <small className="p-invalid">{form.errors.saturated_fat?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-trans-fat">Trans Fat (g)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-trans-fat"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Trans Fat" value={form.values.trans_fat}
                                                           onChange={(e) =>
                                                               form.handleChange('trans_fat', e.currentTarget.value)
                                                           }/>
                                                {form.errors.trans_fat?.error ?
                                                    <small className="p-invalid">{form.errors.trans_fat?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-cholesterol">Colesterol (mg)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-cholesterol"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Cholesterol" value={form.values.cholesterol}
                                                           onChange={(e) =>
                                                               form.handleChange('cholesterol', e.currentTarget.value)
                                                           }/>
                                                {form.errors.cholesterol?.error ?
                                                    <small className="p-invalid">{form.errors.cholesterol?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-iron">Hierro (mg)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-iron"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Hierro" value={form.values.iron}
                                                           onChange={(e) =>
                                                               form.handleChange('iron', e.currentTarget.value)
                                                           }/>
                                                {form.errors.iron?.error ?
                                                    <small className="p-invalid">{form.errors.iron?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-sodium">Sodio (mg)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-sodium"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Sodio" value={form.values.sodium}
                                                           onChange={(e) =>
                                                               form.handleChange('sodium', e.currentTarget.value)
                                                           }/>
                                                {form.errors.sodium?.error ?
                                                    <small className="p-invalid">{form.errors.sodium?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-potassium">Potasio (mg)</label>
                                                <InputText disabled={!fixNutritionalInfo} id="input-potassium"
                                                           className="form-control form-control-alternative"
                                                           placeholder="Potasio" value={form.values.potassium}
                                                           onChange={(e) =>
                                                               form.handleChange('potassium', e.currentTarget.value)
                                                           }/>
                                                {form.errors.potassium?.error ?
                                                    <small className="p-invalid">{form.errors.potassium?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-right">
                                            <Button label="Guardar receta" className="p-button-raised p-button-rounded"
                                                disabled={saving} onClick={form.handleSubmit}>
                                                {saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditRecipe;
