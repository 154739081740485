import NotFound from "../pages/NotFound/NotFound";
import {RecipesList} from "../pages/Dashboard/Nutritionist/Recipes/RecipesList";
import {Invoices} from "../pages/Dashboard/Nutritionist/Invoices";
import {Profile} from "../pages/Dashboard/Nutritionist/Profile";
import {Statistics} from "../pages/Dashboard/Nutritionist/Statistics";
import {EditRecipe} from "../pages/Dashboard/Nutritionist/Recipes/EditRecipe";

const routes = [
    {
        path: '/dashboard',
        exact: true,
        component: Statistics,
    },
    {
        path: '/dashboard/recipes/edit',
        exact: true,
        component: EditRecipe,
    },
    {
        path: '/dashboard/recipes/list',
        exact: true,
        component: RecipesList,
    },
    {
        path: '/dashboard/invoices',
        exact: true,
        component: Invoices,
    },
    {
        path: '/dashboard/profile',
        exact: true,
        component: Profile,
    },
    {
        path: '/dashboard/statistics',
        exact: true,
        component: Statistics,
    },
    {
        component: NotFound,
    },
];

export default routes;
