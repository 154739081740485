import {DEFAULT_API_STATE, IApiState} from "../types";
import {
    CODES_LIST_ADD,
    CODES_LIST_ERROR,
    CODES_LIST_RECEIVED, CODES_LIST_REMOVE,
    CODES_LIST_REQUEST,
    CODES_SKU_ITEMS_LIST_ADD,
    CODES_SKU_ITEMS_LIST_ERROR,
    CODES_SKU_ITEMS_LIST_RECEIVED, CODES_SKU_ITEMS_LIST_REMOVE,
    CODES_SKU_ITEMS_LIST_REQUEST,
} from "../actions/Codes.actions";
import {ISKUItem} from "../../service/models/codes";

export interface ICodesState {
    codeslist: IApiState;
    itemslist: IApiState;
}

const DEFAULT_STATE : ICodesState = {
    codeslist: {
        ...DEFAULT_API_STATE,
        data: [],
    },
    itemslist: {
        ...DEFAULT_API_STATE,
        data: [],
    },
};

const CodesReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        // Codes List retrieval
        case CODES_LIST_REQUEST:
            return {
                ...state,
                codeslist: {
                    ...state.codeslist,
                    waiting: true,
                    error: '',
                    success: false,
                }
            }
        case CODES_LIST_RECEIVED:
            return {
                ...state,
                codeslist: {
                    waiting: false,
                    error: '',
                    success: true,
                    data: (action.payload != null) ? action.payload : []
                }
            }
        case CODES_LIST_ERROR:
            return {
                ...state,
                codeslist: {
                    waiting: false,
                    error: action.payload,
                    success: false,
                    data: []
                }
            }
        // Manage codes list
        case CODES_LIST_ADD:
            return {
                ...state,
                codeslist: {
                    ...state.codeslist,
                    data: [...state.codeslist.data, action.payload]
                }
            }
        case CODES_LIST_REMOVE:
            return {
                ...state,
                codeslist: {
                    ...state.codeslist,
                    data: state.codeslist.data.filter((item: any) => item.code !== action.payload)
                }
            }
        // SKU Items List retrieval
        case CODES_SKU_ITEMS_LIST_REQUEST:
            return {
                ...state,
                itemslist: {
                    ...state.itemslist,
                    waiting: true,
                    error: '',
                    success: false,
                }
            }
        case CODES_SKU_ITEMS_LIST_RECEIVED:
            return {
                ...state,
                itemslist: {
                    waiting: false,
                    error: '',
                    success: true,
                    data: (action.payload != null) ? action.payload : []
                }
            }
        case CODES_SKU_ITEMS_LIST_ERROR:
            return {
                ...state,
                itemslist: {
                    waiting: false,
                    error: action.payload,
                    success: false,
                    data: []
                }
            }
        // Manage list
        case CODES_SKU_ITEMS_LIST_ADD:
            return {
                ...state,
                itemslist: {
                    ...state.itemslist,
                    data: [...state.itemslist.data, action.payload]
                }
            }
        case CODES_SKU_ITEMS_LIST_REMOVE:
            return {
                ...state,
                itemslist: {
                    ...state.itemslist,
                    data: state.itemslist.data.filter((item:ISKUItem) => item.id !== action.payload)
                }
            }
        default:
            return state;
    }
};

export default CodesReducers;
