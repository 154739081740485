import React, {useEffect, useRef, useState} from 'react';

import './Profile.scss';
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../../redux/reducers";
import {Message} from "primereact/message";
import {profileRequest, profileSuccess} from "../../../../redux/actions/Profile.actions";
import {Button} from "primereact/button";
import LoadingView from "../../../../components/LoadingView/LoadingView";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import useTDPForm from "../../../../hooks/tdp-form/tdp-form";
import {ITDPFormValidations} from "../../../../hooks/tdp-form/tdp-form-types";
import {RequiredValidation} from "../../../../hooks/tdp-form/tdp-form-validations";
import {ProgressSpinner} from "primereact/progressspinner";
import {changeUserPassword, saveProfileRequest, updateProfileAvatar} from "../../../../service/user";
import {Toast} from "primereact/toast";
import {statsProfileRequest} from "../../../../redux/actions/Stats.actions";
import {Password} from "primereact/password";
import {tdp_diff} from "../../../../utils/tdp_diff";

interface IProfileForm {
    first_name: string;
    last_name: string;
    email: string;
    main_phone: string;
    city: string;
    region: string;
    country: string;
    zip_code: string;
    about_me: string;
    bio: string;
    avatar: string;
}

const formInitialValues: IProfileForm = {
    first_name: '',
    last_name: '',
    email: '',
    main_phone: '',
    city: '',
    region: '',
    country: '',
    zip_code: '',
    about_me: '',
    bio: '',
    avatar: '',
}

const formValidations: ITDPFormValidations<IProfileForm> = {
    first_name: [RequiredValidation({errorText: 'El nombre es obligatorio'})],
    last_name: [RequiredValidation({errorText: 'El apellido es obligatorio'})],
    email: [RequiredValidation({errorText: 'El email es obligatorio'})],
    main_phone: [],
    city: [],
    region: [],
    country: [],
    zip_code: [],
    about_me: [],
    bio: [],
    avatar: [],
};

interface NewPasswordState {
    original_password: string|undefined;
    new_password: string|undefined;
    saving: boolean;
}

const Profile : React.FC = (props) => {
    const dispatch = useDispatch();

    const inputFileRef = React.useRef(null)

    const globalProfileState = useSelector((state:IAppState) => state.profile);
    const profileStatsState = useSelector((state:IAppState) => state.stats.profile);

    const [newAvatar, setNewAvatar] = useState<any>(null);
    const [newPassword, setNewPassword] = useState<NewPasswordState>({
        original_password: undefined,
        new_password: undefined,
        saving: false,
    });

    const [saving, setSaving] = useState<boolean>(false);

    const onSubmit = async (submittedValues:IProfileForm) => {
        const toastTitle = "Error guardando perfil";

        setSaving(true);

        try {
            const modifiedData = tdp_diff(globalProfileState.data, submittedValues);
            if (modifiedData && Object.keys(modifiedData).length > 0) {
                const saveProfileResponse = await saveProfileRequest(modifiedData);
                if (saveProfileResponse.error) {
                    throw new Error(saveProfileResponse.error_message);
                }

                // update redux state
                dispatch(profileSuccess(submittedValues));
            }

            if (newAvatar != null) {
                const formData = new FormData();
                formData.append("file", newAvatar);

                // update avatar
                const updateAvatarImageResponse = await updateProfileAvatar(formData);
                if (updateAvatarImageResponse.error) {
                    throw new Error('Error saving avatar: '+updateAvatarImageResponse.error_message);
                }

                // update URLs
                if (updateAvatarImageResponse.data) {
                    // @ts-ignore
                    dispatch(profileSuccess({
                        avatar: updateAvatarImageResponse.data.avatar,
                    }));
                    form.handleChange('avatar', updateAvatarImageResponse.data.avatar);
                }
                setNewAvatar(null);
            }

            setSaving(false);
            showToast('Guardar Perfil', 'OK!', 'success');
        } catch (err) {
            setSaving(false);
            showToast(toastTitle, err.message,'error');
        }
    }

    const form = useTDPForm(formInitialValues, formValidations, onSubmit);

    useEffect(() => {
        dispatch(profileRequest());
        dispatch(statsProfileRequest());
    }, [dispatch]);

    useEffect(() => {
        if (globalProfileState.data) {
            form.resetValues(globalProfileState.data);
        }
        // eslint-disable-next-line
    }, [globalProfileState.data]);

    const toastRef = useRef<Toast>(null);

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    if (globalProfileState.error) {
        return (
            <div className="container-fluid">
                <Message text={globalProfileState.error} severity="error" />
            </div>
        )
    }

    if (globalProfileState.waiting || globalProfileState.data == null) {
        return <LoadingView />;
    }

    function changeMyPassword() {
        const toastTitle = 'Cambiar Password';

        if (!newPassword.original_password || !newPassword.original_password.trim()) {
            showToast(toastTitle, 'No se ha especificado la contraseña actual!','error');
            return;
        }

        if (!newPassword.new_password || !newPassword.new_password.trim()) {
            showToast(toastTitle, 'No se ha especificado la contraseña nueva!','error');
            return;
        }

        setNewPassword({...newPassword, saving: true});

        changeUserPassword(newPassword.original_password, newPassword.new_password).then(res => {
            setNewPassword({...newPassword, saving: false});

            if (res.error) {
                let message;
                switch (res.error_code) {
                    case 125:
                        message = 'La contraseña actual no es válida?'
                        break;
                    default:
                        message = res.error_message;
                        break;
                }
                showToast(toastTitle, message, 'error');
                return;
            }

            showToast(toastTitle, 'OK!', 'success');
        }).catch(err => {
            showToast(toastTitle, err.message,'error');
            setNewPassword({...newPassword, saving: false});
        });
    }

    function selectNewAvatar(ev: any) {
        if (ev.nativeEvent) {
            ev.nativeEvent.stopPropagation();
            ev.nativeEvent.preventDefault();
        }
        ev.preventDefault();
        ev.stopPropagation();

        console.log('Click update avatar', ev, inputFileRef);
        if (inputFileRef != null && inputFileRef.current != null) {
            // @ts-ignore
            inputFileRef.current.click();
        }
    }

    function onAvatarFileSelected(ev: any) {
        console.log('OnAvatarFileSelected', ev);

        const files = ev.dataTransfer ? ev.dataTransfer.files : ev.target.files;
        if (files?.length > 0) {
            if (form.values.avatar) {
                URL.revokeObjectURL(form.values.avatar);
            }
            form.handleChange('avatar', URL.createObjectURL(files[0]));
            setNewAvatar(files[0]);
        }

        // clear input
        if (inputFileRef && inputFileRef.current) {
            // @ts-ignore
            inputFileRef.current.value = '';
        }
    }

    return (
        <div id="Profile">
            <nav className="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
                <div className="container-fluid">
                    {/*<span className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">Tu perfil</span>*/}
                </div>
            </nav>
            <div className="header pb-8 pt-5 d-flex align-items-center">
                <Toast ref={toastRef} position="top-right" />
                <span className="mask opacity-8"/>
                <div className="container-fluid d-flex align-items-center">
                    <div className="row">
                        <div className="col-lg-7 col-md-10">
                            <h1 className="display-4 text-white">Usuario Nutricionista</h1>
                            <p className="text-white mt-0 mb-5">Esta es tu pagina de perfil con tus datos principales de Halen e información que verán los usuarios.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt--7">
                <div className="row">
                    <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                        <div className="card card-profile shadow">
                            <div className="row justify-content-center">
                                <div className="col-lg-3 order-lg-2">
                                    <input ref={inputFileRef} type="file" onChange={onAvatarFileSelected} multiple={false} accept="image/*" style={{display: 'none'}} />
                                    <div className="card-profile-image" onClick={selectNewAvatar} title="Haz click aquí para cambiar tu avatar">
                                        {form.values.avatar ? <img src={form.values.avatar} className="profile-avatar" alt="avatar"/>
                                            : <i className="avatar profile-avatar pi pi-user"></i>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0 pt-md-4">
                                <div className="row">
                                    <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                            <div>
                                                <span className="heading">{profileStatsState.data?.breakfast || 0}</span>
                                                <span className="description">Desayunos</span>
                                            </div>
                                            <div>
                                                <span className="heading">{profileStatsState.data?.lunch || 0}</span>
                                                <span className="description">Comidas</span>
                                            </div>
                                            <div>
                                                <span className="heading">{profileStatsState.data?.dinner || 0}</span>
                                                <span className="description">Cenas</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <h3>
                                    </h3>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2"/>{form.values.city}, {form.values.country}
                                    </div>
                                    <div className="h5 mt-4">
                                        <i className="ni business_briefcase-24 mr-2"/>Alias: <span className="text-halen">{globalProfileState.data?.alias}</span>
                                    </div>
                                    <br />
                                        <h3>
                                            Código de referido
                                        </h3>
                                        <div className="h5 font-weight-300">
                                            <i className="ni location_pin mr-2"/>{globalProfileState.data?.code}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 order-xl-1">
                        <div className="card bg-secondary shadow">
                            <div className="card-header bg-white border-0">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h3 className="mb-0">Mi cuenta</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                    <h6 className="heading-small text-muted mb-4">Información básica</h6>
                                    <div className="pl-lg-4">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-first-name">Nombre</label>
                                                    <InputText id="input-first-name" className="form-control form-control-alternative"
                                                               placeholder="Nombre" value={form.values.first_name || ''}
                                                               onChange={(e) =>
                                                                   form.handleChange('first_name', e.currentTarget.value)
                                                               } />
                                                    {form.errors.first_name.error ?
                                                        <small className="p-invalid">{form.errors.first_name.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-last-name">Apellidos</label>
                                                    <InputText id="input-last-name" className="form-control form-control-alternative"
                                                               placeholder="Apellidos" value={form.values.last_name || ''}
                                                               onChange={(e) =>
                                                                   form.handleChange('last_name', e.currentTarget.value)
                                                               }/>
                                                    {form.errors.last_name.error ?
                                                        <small className="p-invalid">{form.errors.last_name.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-email">Email</label>
                                                    <InputText type="email" id="input-email" className="form-control form-control-alternative"
                                                           placeholder="Email" value={form.values.email || ''}
                                                            onChange={(e) =>
                                                                form.handleChange('email', e.currentTarget.value)
                                                            }/>
                                                    {form.errors.email.error ?
                                                        <small className="p-invalid">{form.errors.email.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-main-phone">Teléfono móvil</label>
                                                    <InputText id="input-main-phone" className="form-control form-control-alternative"
                                                           placeholder="Teléfono móvil" value={form.values.main_phone || ''}
                                                           onChange={(e) =>
                                                               form.handleChange('main_phone', e.currentTarget.value)
                                                           }/>
                                                    {form.errors.main_phone.error ?
                                                        <small className="p-invalid">{form.errors.main_phone.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-instagram-url">Perfil de instagram</label>
                                                    <input type="text" id="input-instagram-url" className="form-control form-control-alternative"
                                                           placeholder="www.instagra.com/miperfil" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-pro-nr">Número de colegiado</label>
                                                    <input type="text" id="input-pro-nr" className="form-control form-control-alternative"
                                                           placeholder="Número de colegiado" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-old-password">Contraseña Actual</label>
                                                    <Password id="input-old-password" className="w-100" inputClassName="form-control form-control-alternative" placeholder="**********"
                                                              value={newPassword.original_password} onChange={(e) => setNewPassword({...newPassword, original_password: e.currentTarget.value})}
                                                              feedback={false} toggleMask />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-new-password">Contraseña nueva</label>
                                                    <Password id="input-new-password" className="w-100" inputClassName="form-control form-control-alternative" placeholder="**********"
                                                              value={newPassword.new_password} onChange={(e) => setNewPassword({...newPassword, new_password: e.currentTarget.value})}
                                                              feedback={false} toggleMask />
                                                </div>
                                            </div>
                                            <div className="col align-self-center">
                                                <Button label="Cambiar contraseña" className="p-button-warning p-button-raised p-button-rounded" disabled={!newPassword.original_password || !newPassword.new_password}
                                                    onClick={changeMyPassword}/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Datos de facturación</h6>
                                    <div className="pl-lg-4">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-organization-type">Tipo de organización</label>
                                                    <input type="text" id="input-organization-type" className="form-control form-control-alternative" placeholder="Sl, SA, Autónomo, Otros" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-vatid">CIF/NIF</label>
                                                    <InputText id="input-vatid" className="form-control form-control-alternative" placeholder="CIF/NIF" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-company">Nombre persona o organización</label>
                                                    <input type="text" id="input-organization" className="form-control form-control-alternative" placeholder="Nombre persona o organización" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-company">Razón social</label>
                                                    <input type="text" id="input-business-name" className="form-control form-control-alternative" placeholder="Razón Social" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-bank-account">Cuenta bancaria</label>
                                                    <input id="input-bank-account" className="form-control form-control-alternative" placeholder="Cuenta bancaria" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-address">Dirección de facturación</label>
                                                    <input id="input-address" className="form-control form-control-alternative" placeholder="Dirección de facturación" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-city">Ciudad</label>
                                                    <InputText id="input-city" className="form-control form-control-alternative"
                                                           placeholder="City" value={form.values.city || ''}
                                                            onChange={(e) =>
                                                                form.handleChange('city', e.currentTarget.value)
                                                            }/>
                                                    {form.errors.city.error ?
                                                        <small className="p-invalid">{form.errors.city.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-country">País</label>
                                                    <InputText id="input-country" className="form-control form-control-alternative"
                                                           placeholder="País" value={form.values.country || ''}
                                                            onChange={(e) =>
                                                                form.handleChange('country', e.currentTarget.value)
                                                            }/>
                                                    {form.errors.country.error ?
                                                        <small className="p-invalid">{form.errors.country.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-country">Código Postal</label>
                                                    <InputText id="input-postal-code" className="form-control form-control-alternative"
                                                           placeholder="Postal code" value={form.values.zip_code || ''}
                                                        onChange={(e) =>
                                                            form.handleChange('zip_code', e.currentTarget.value)
                                                        }/>
                                                    {form.errors.zip_code.error ?
                                                        <small className="p-invalid">{form.errors.zip_code.errorText}</small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Sobre mi</h6>
                                    <div className="pl-lg-4">
                                        <div className="form-group">
                                            <label>Descripción</label>
                                            <InputTextarea rows={4} className="form-control form-control-alternative" placeholder="Una breve descripción sobre ti"
                                                           value={form.values.about_me || ''}
                                                           onChange={(e) =>
                                                               form.handleChange('about_me', e.currentTarget.value)
                                                           }/>
                                            {form.errors.about_me.error ?
                                                <small className="p-invalid">{form.errors.about_me.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="pl-lg-4">
                                        <div className="form-group">
                                            <label>Formación/BIO</label>
                                            <InputTextarea rows={4} className="form-control form-control-alternative" placeholder="Información sobre tu formación o biografía"
                                                           value={form.values.bio || ''}
                                                           onChange={(e) =>
                                                               form.handleChange('bio', e.currentTarget.value)
                                                           }/>
                                            {form.errors.bio.error ?
                                                <small className="p-invalid">{form.errors.bio.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <Button label="Guardar datos" className="p-button-raised p-button-rounded"
                                                disabled={saving} onClick={form.handleSubmit}>
                                            {saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
                                        </Button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
