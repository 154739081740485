export const SKUStores = [
    {label: 'Android', value: 'android', icon: 'android'},
    {label: 'iOS', value: 'ios', icon: 'apple'},
]

export interface INewItemResponse {
    id:number;
}

export interface INewSKUItem {
    store: string;
    sku: string;
    description: string;
}

export interface ISKUItem extends INewSKUItem, INewItemResponse {
}

export interface INewSKUProfileItem {
    name: string;
}

export interface ISKUProfileItem extends INewSKUProfileItem, INewItemResponse {
}

export interface ICodeItem {
    code: string;
    user: {
        id: number,
        name: string,
    };
    profile: {
        id: number,
        name: string,
    };
}

export interface INewCodeItem {
    code: string;
    userId: number;
    userName: string;
    profileId: number;
    profileName: string;
}

export interface INewCodeRequest {
    code: string;
    user_id: number;
    profile_id: number
}
