import React, {useState} from "react";

import './RememberPassword.scss';
import logo from "../../assets/img/halen_white.png";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import {ProgressSpinner} from "primereact/progressspinner";

const RememberPassword: React.FC = (props) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState<boolean>(false);

    function onEmailChange(e:any) {
        setEmail(e.target.value);
    }

    function doRememberPwd(email: string) {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    return (
        <div className="main-content">
            <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
                <div className="container px-4">
                    <img src={logo} alt={'logo'}/>
                </div>
            </nav>
            <div className="header bg-gradient-primary-login py-7 py-lg-8">
                <div className="container">
                    <div className="header-body text-center mb-7">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-6">
                                <h1 className="text-white">¿Olvidaste tu contraseña?</h1>
                                <p className="text-lead text-white">Introduce por favor tu email.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                         xmlns="http://www.w3.org/2000/svg">
                        <polygon className="fill-default" points="2560 0 2560 100 0 100"/>
                    </svg>
                </div>
            </div>
            <div className="container mt--8 pb-5">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-7">
                        <div className="card bg-secondary shadow border-0">
                            <div className="card-body-login px-lg-5 py-lg-5">
                                <div className="form-group p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-envelope"/>
                                        </span>
                                    <InputText placeholder="Email" value={email} onChange={onEmailChange}/>
                                </div>
                                <div className="text-center">
                                    <Button label="Recuperar contraseña" disabled={loading}
                                            className="p-button-raised p-button-rounded my-4"
                                            onClick={() => doRememberPwd(email)}>
                                        {loading && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <Link className='text-light' to="/login"><small>Volver</small></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
);
}

export default RememberPassword;
