import React, {useEffect, useMemo, useRef, useState} from 'react';

import './CodesSKUProfiles.scss';
import {useDispatch, useSelector} from "react-redux";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
// @ts-ignore
import {confirmDialog} from "primereact/confirmdialog";
import {INewSKUProfileItem, ISKUItem, SKUStores} from "../../../../../service/models/codes";
import {storeItemTemplate} from "../CodesTemplateUtils";
import {
    addSkuProfileRequest, attachSkuToProfile, detachSkuFromProfile,
    getCodesProfilesList,
    removeSkuProfileRequest
} from "../../../../../service/codes";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import TreeNode from "primereact/treenode";
import {ITDPFormValidations} from "../../../../../hooks/tdp-form/tdp-form-types";
import {RequiredValidation} from "../../../../../hooks/tdp-form/tdp-form-validations";
import useTDPForm from "../../../../../hooks/tdp-form/tdp-form";
import {ProgressSpinner} from "primereact/progressspinner";
import {TreeTable} from "primereact/treetable";
import {codesSkuItemsListRequest} from "../../../../../redux/actions/Codes.actions";
import {DataTable} from "primereact/datatable";
import {IAppState} from "../../../../../redux/reducers";

const formInitialValues: INewSKUProfileItem = {
    name: '',
}

const formValidations: ITDPFormValidations<INewSKUProfileItem> = {
    name: [RequiredValidation({errorText: 'El nombre del perfil es obligatorio'})],
};

export function storeBodyTemplate(node: any) {
    if (node.data !== undefined && node.data.store) {
        const item = SKUStores.find(item => item.value === node.data.store);
        return storeItemTemplate(item);
    }
    if (node.store) {
        const item = SKUStores.find(item => item.value === node.store);
        return storeItemTemplate(item);
    }
    return null;
}

const CodesSKUProfiles : React.FC = (props) => {
    const dispatch = useDispatch();

    const itemsListState = useSelector((state:IAppState) => state.codes.itemslist);

    const [profiles, setProfiles] = useState<any>([]);
    const [expandedKeys, setExpandedKeys] = useState({});

    const [saving, setSaving] = useState<boolean>(false);

    const [newSKUItemState, setNewSKUItemState] = useState({
        profileId: 0,
        visible: false,
    });

    const [newProfileState, setNewProfileState] = useState({
        ...formInitialValues,
        visible: false,
    });

    useEffect(() => {
        dispatch(codesSkuItemsListRequest());
        getCodesProfilesList().then(res => {
            console.log('Profiles result', res);
            if (res.data) {
                setProfiles(res.data);
            }
        }).catch(err => {
            console.log('Error retrieving profiles', err);
        });
    }, [dispatch]);

    // process profile info
    const treeProfileItems = useMemo<TreeNode[]>(() => profiles.flatMap((profileItem: any) => ({
        key: 'prof'+profileItem.id,
        data: {
            id: profileItem.id,
            name: profileItem.name,
        },
        children: Object.entries(profileItem.items).flatMap(((storeItem: any) =>
            storeItem[1].map((skuItem: ISKUItem) => ({
                key: 'item'+skuItem.id,
                data: {
                    id: profileItem.id,
                    name: profileItem.name,
                    itemId: skuItem.id,
                    store: storeItem[0],
                    sku: skuItem.sku,
                    description: skuItem.description,
                }
            }))))
    })), [profiles]);

    const toastRef = useRef<Toast>(null);

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    const onSubmit = async (submittedValues:INewSKUProfileItem) => {
        const toastTitle = 'Error guardando perfil';

        setSaving(true);

        try {
            const saveProfileResponse = await addSkuProfileRequest({
                ...submittedValues,
            });

            setSaving(false);

            if (saveProfileResponse.error) {
                showToast(toastTitle, saveProfileResponse.error_message, 'error');
                return;
            }

            const itemId = saveProfileResponse.data?.id;
            if (!itemId) {
                showToast(toastTitle, 'Error obteniendo el nuevo perfil', 'error');
                return;
            }

            showToast('Guardar Perfil', 'OK!', 'success');
            setProfiles([...profiles, {
                ...submittedValues,
                id: itemId,
                items: []
            }]);

            form.resetValues(formInitialValues);
            hideNewProfileDialog();
        } catch (err) {
            setSaving(false);
            showToast(toastTitle, err.message,'error');
        }
    }
    const form = useTDPForm(formInitialValues, formValidations, onSubmit);

    function hideNewProfileDialog() {
        setNewProfileState({...newProfileState, visible: false});
    }

    const newProfileDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideNewProfileDialog} />
            <Button label="Guardar" icon="pi pi-save" className="p-button-text" disabled={saving} onClick={form.handleSubmit}>
                {saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
            </Button>
        </>
    );

    const actionHeaderTemplate = () => {
        return (
            <Button icon="pi pi-plus" className="p-button-rounded p-button-success" tooltip="Añadir un nuevo perfil"
                    onClick={() => {setNewProfileState({...newProfileState, visible: true})}} />
        );
    }

    async function removeProfile(profileId: number) {
        const toastTitle = 'Error borrando perfil';

        try {
            const removeProfileResponse = await removeSkuProfileRequest(profileId);

            if (removeProfileResponse.error) {
                showToast(toastTitle, removeProfileResponse.error_message, 'error');
                return;
            }

            showToast('Eliminar Perfil', 'OK!', 'success');
            setProfiles(profiles.filter((item: any) => item.id !== profileId));
        } catch (err) {
            showToast(toastTitle, err.message,'error');
        }
    }

    async function addSkuToProfile(profileId: number, itemData: ISKUItem) {
        const toastTitle = 'Error añadiendo item';

        try {
            const attachItemResponse = await attachSkuToProfile(profileId, itemData.id);

            if (attachItemResponse.error) {
                showToast(toastTitle, attachItemResponse.error_message, 'error');
                return;
            }

            showToast('Añadir Item', 'OK!', 'success');
            setProfiles(profiles.map((item: any) => {
                if (item.id === profileId) {
                    item.items[itemData.store] = [
                        ...item.items[itemData.store],
                        itemData
                    ];
                }
                return item;
            }));
            hideNewSkuItemDialog();
        } catch (err) {
            showToast(toastTitle, err.message,'error');
        }
    }

    async function removeSkuFromProfile(itemData: any) {
        const toastTitle = 'Error borrando item';

        try {
            const detachItemResponse = await detachSkuFromProfile(itemData.id, itemData.itemId);

            if (detachItemResponse.error) {
                showToast(toastTitle, detachItemResponse.error_message, 'error');
                return;
            }

            showToast('Eliminar Item', 'OK!', 'success');
            setProfiles(profiles.map((item: any) => {
                if (item.id === itemData.id) {
                    item.items[itemData.store] = item.items[itemData.store].filter((skuItem:ISKUItem) => skuItem.id !== itemData.itemId);
                }
                return item;
            }));
        } catch (err) {
            showToast(toastTitle, err.message,'error');
        }
    }

    function showNewSkuItemDialog(profileId: any) {
        setNewSKUItemState({
            ...newSKUItemState,
            profileId,
            visible: true
        });
    }

    function hideNewSkuItemDialog() {
        setNewSKUItemState({
            ...newSKUItemState,
            visible: false
        });
    }

    function actionBodyTemplate(node:any) {
        return (
            <>
                {node.data.itemId === undefined && <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2"
                        tooltip="Añadir un nuevo SKU a éste perfil" onClick={() => showNewSkuItemDialog(node.data.id) } />}
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
                    if (node.data.itemId === undefined) { // remove profile
                        confirmDialog({
                            message: '¿Estás seguro de eliminar el perfil "'+node.data.name+'"?  Se disociarán los SKU pero no se perderán, siguen disponibles para otros perfiles y se pueden consultar en la página SKU Items',
                            // @ts-ignore
                            header: 'Confirmación',
                            icon: 'pi pi-exclamation-triangle',
                            style: {maxWidth: '500px'},
                            accept: () => removeProfile(node.data.id),
                        });
                    } else { // remove item
                        confirmDialog({
                            message: '¿Estás seguro de eliminar el item "'+node.data.sku+'" del perfil "'+node.data.name+'"?',
                            // @ts-ignore
                            header: 'Confirmación',
                            icon: 'pi pi-exclamation-triangle',
                            style: {maxWidth: '500px'},
                            accept: () => removeSkuFromProfile(node.data)
                        });
                    }
                }} />
            </>
        );
    }

    function selectSKUItemTemplate(rowData:any, props:any) {
        return <Button icon="pi pi-check" className="p-button-rounded p-button-success"
                       tooltip="Seleccionar este item" onClick={() => addSkuToProfile(newSKUItemState.profileId, rowData)}/>
    }

    return (
        <div id="sku_profiles">
            <nav className="mt-5 ml-4">
                <h3 className="text-halen">SKU <span className="text-halen-bold">Profiles</span></h3>
            </nav>
            <div className="container-fluid">
                <Toast ref={toastRef} position="top-right" />
                <div className="row tables-row">
                    <div className="col-12 h-100">
                        <TreeTable className="h-100" tableClassName="h-100" value={treeProfileItems}
                                   paginator rows={20}
                                   expandedKeys={expandedKeys} onToggle={(e) => setExpandedKeys(e.value)}>
                            <Column field="name" header="Perfil" filter filterPlaceholder="Buscar por perfil" filterMatchMode="contains" expander style={{width:'25%'}}/>
                            <Column field="store" header="Store" filter filterPlaceholder="Buscar por store" filterMatchMode="contains" body={storeBodyTemplate} style={{width:'10%'}}/>
                            <Column field="sku" header="SKU" filter filterPlaceholder="Buscar por SKU" filterMatchMode="contains" style={{width:'20%'}}/>
                            <Column field="description" header="Descripción" filter filterPlaceholder="Buscar por descripción" filterMatchMode="contains"  style={{width:'30%'}}/>
                            <Column className="text-right" body={actionBodyTemplate} header={actionHeaderTemplate()} style={{width: '15%'}}/>
                        </TreeTable>
                    </div>
                </div>

                <Dialog visible={newProfileState.visible} style={{ width: '450px' }} header="Añadir nuevo perfil" modal
                        footer={newProfileDialogFooter} onHide={hideNewProfileDialog}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-control-label" htmlFor="input-name">Nombre del Perfil</label>
                                    <InputText className="form-control form-control-alternative"
                                               placeholder="Nombre" value={form.values.name}
                                               onChange={(e) =>
                                                   form.handleChange('name', e.currentTarget.value)
                                               }/>
                                    {form.errors.name.error ?
                                        <small className="p-invalid">{form.errors.name.errorText}</small>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={newSKUItemState.visible} style={{ width: '700px' }} header="Añadir nuevo SKU Item" modal
                        onHide={() => hideNewSkuItemDialog()}>
                    <div className="confirmation-content">
                        <div className="row">
                            <div className="col-12 h-100">
                                <DataTable className="h-100" paginator rows={5} tableClassName="h-100" value={itemsListState.data} dataKey="id">
                                    <Column field="store" header="Store" body={storeBodyTemplate} style={{width:'20%'}}/>
                                    <Column field="sku" header="SKU" style={{width: '30%'}}/>
                                    <Column field="description" header="Description" style={{width: '40%'}}/>
                                    <Column body={selectSKUItemTemplate} style={{width: '10%'}}/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default CodesSKUProfiles;
