import axios, {AxiosInstance} from "axios";

const ApiConfig = {
  REFRESH_ACCESS_TOKEN_ENDPOINT: '/auth/refresh',
};

export enum ApiErrors {
  ERROR_NO_ERROR = 0,
  ERROR_OK = ERROR_NO_ERROR,
  ERROR_JSON_REQUIRED = 101,
  ERROR_DATA_ATTRIBUTE_MISSING = 102,
  ERROR_INVALID_CREDENTIAL = 103,
  ERROR_MISSING_PARAMETERS = 104,
  ERROR_CANNOT_UPLOAD_FILE_TO_S3 = 105,
  ERROR_CANNOT_CREATE_PRESIGNED_URL = 106,
  ERROR_EMAIL_MISSING = 107,
  ERROR_PASSWORD_MISSING = 108,
  ERROR_AUTHENTICATION_FIELDS_MISSING = 109,
  ERROR_INVALID_ID = 110,
  ERROR_CREATING_USER = 111,
  ERROR_INVALID_ITEM = 112,
  ERROR_INVALID_EMAIL = 113,
  ERROR_EXISTING_IDENTITY = 114,
  ERROR_ADDING_SOCIAL_USER = 115,
  ERROR_INVALID_OAUTH_TOKEN = 116,
  ERROR_IDENTITY_NOT_SUPPORTED = 117,
  ERROR_RATE_LIMIT_APPLIED = 118,
  ERROR_UNKNOWN_RESPONSE = 119,
  ERROR_VALIDATOR_EMAIL_SERVICE_NOT_ENABLED = 120,
  ERROR_UNSUCCESSFUL_DEAUTH = 121,
  ERROR_REFRESH_TOKEN_REVOKED = 122,
  ERROR_ACCESS_TOKEN_REVOKED = 123,
  ERROR_TOKEN_REVOKED = 124,
  ERROR_UPDATING_USER_DATA = 125,
  ERROR_USER_NOT_FOUND = 126,
  ERROR_FREE_PERIOD_EXPIRED = 127,
  ERROR_FREE_PERIOD_ALREADY_STARTED = 128,
  ERROR_CANNOT_UPDATE_USER_STATUS_IN_DATABASE = 129,
  ERROR_CANNOT_RESET_USER_STATUS = 130,
  ERROR_SAVING_TO_DATABASE = 131,
  ERROR_ALREADY_EXISTED = 132,
  ERROR_NOT_EXISTS = 133,
  ERROR_RETRIEVING_INFO = 134,
  ERROR_TOO_MANY_USERS_FOLLOWED = 135,
  ERROR_SERVICE_UNAVAILABLE = 136,
  ERROR_ACESS_DENIED = 137,
}

class Api {
  private static _instance : Api;

  private _axiosInstance : AxiosInstance;
  private _accessToken : string = '';
  private _refreshToken : string = '';

  private constructor() {
    console.log('PROCESS API URL', process.env);
    this._axiosInstance = axios.create(
        {
          baseURL: process.env.REACT_APP_API_BASE_URL
        }
    );
  }

  public static get getInstance()
  {
    return this._instance || (this._instance = new this());
  }

  public buildApiUrl(path:string) {
    return this.apiUrl+path;
  }

  public setAccessToken(newToken:string) {
    this._accessToken = newToken;
  }

  public hasAccessToken() : boolean {
    return (this._accessToken != null) && (this._accessToken !== '');
  }

  public setRefreshToken(newToken:string) {
    this._refreshToken = newToken;
  }

  public hasRefreshToken() : boolean {
    return (this._refreshToken != null) && (this._refreshToken !== '');
  }

  public clearTokens() {
    this.setAccessToken('');
    this.setRefreshToken('');
  }

  /**
   * Gets the API main url
   */
  get apiUrl() {
  	  return process.env.REACT_APP_API_BASE_URL;
  }

  /**
   * Returns headers for API calls. If a token is provided, it will also include an "Authorization" header.
   */
  getHeaders = () => {
    return {
      'Content-Type': 'application/json',
      ...(this._accessToken ? { Authorization: `Bearer ${this._accessToken}` } : {}),
    };
  };

  protected doRequest(method: 'GET' | 'POST' | 'PUT' | 'DELETE', endpoint: string, requestData?: any, extraheaders?: any) : Promise<any> {
    return this._axiosInstance.request({
      url: endpoint,
      method,
      headers: {...this.getHeaders(), ...extraheaders},
      data: method!=='GET' ? requestData : null,
      params: method==='GET' ? requestData : null,
    }).then(result => {
      return Promise.resolve(result.data);
    }).catch(accessError => {
      // check token expired
      if (accessError.response && accessError.response.status === 401) {
        const response_data = accessError.response.data;
        if (response_data.sub_status === 42 && (response_data.error_message.indexOf('access token has expired') !== -1)) {
          // renew
          return this._axiosInstance.request({
            url: ApiConfig.REFRESH_ACCESS_TOKEN_ENDPOINT,
            method: 'GET',
            headers: {...this.getHeaders(), 'Authorization': `Bearer ${this._refreshToken}`},
            data: null,
            params: null
          }).then((refreshResponse) => {
            if (refreshResponse.data.error) {
              return Promise.reject(refreshResponse.data.error_message || 'Failed to refresh token');
            }

            const refresh_response_data = refreshResponse.data.data;
            if (refresh_response_data && refresh_response_data.access_token) {
              this.setAccessToken(refresh_response_data.access_token);
            } else {
              return Promise.reject('No new token received');
            }

            // retry request
            return this._axiosInstance.request({
              url: endpoint,
              method,
              headers: {...this.getHeaders(), ...extraheaders},
              data: method!=='GET' ? requestData : null,
              params: method==='GET' ? requestData : null,
            }).then(retryResponse => Promise.resolve(retryResponse.data))
                .catch(retryError => Promise.reject(retryError));
          }).catch(refreshError => {
            if (refreshError.response.status === 401) {
              // refresh token failed
            }

            return Promise.reject(accessError);
          });
        }
      }
      // here we usually get by an error other than token expired
      return Promise.reject(accessError);
    });
  }

  public doGet(endpoint:string, params?: any) {
    return this.doRequest('GET', endpoint, params);
  }

  public doPost(endpoint:string, params: any) {
    return this.doRequest('POST', endpoint, params);
  }

  public doPut(endpoint:string, params: any) {
    return this.doRequest('PUT', endpoint, params);
  }

  public doDelete(endpoint:string, params: any) {
    return this.doRequest('DELETE', endpoint, params);
  }
}

export default Api.getInstance;
