import {DEFAULT_API_STATE, IApiState} from "../types";
import {PROFILE_ERROR, PROFILE_REQUEST, PROFILE_SUCCESS} from "../actions/Profile.actions";

export interface IProfileState extends IApiState {
}

const DEFAULT_STATE : IProfileState = DEFAULT_API_STATE;

const ProfileReducers = (
    state = DEFAULT_STATE,
    action: any
) => {
    switch (action.type) {
        case PROFILE_REQUEST:
            return {
                ...state,
                waiting: true,
                error: '',
                success: false,
            }
        case PROFILE_SUCCESS:
            return {
                ...state,
                waiting: false,
                error: '',
                success: true,
                data: action.payload,
            }
        case PROFILE_ERROR:
            return {
                ...state,
                waiting: false,
                error: action.payload,
                success: false,
                data: null,
            }
        // Default
        default:
            return state;
    }
};

export default ProfileReducers;