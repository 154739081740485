import {put, call, select, takeLeading} from "redux-saga/effects";
import {IAppState} from "../reducers";
import {
    ALLERGIES_LIST_REQUEST,
    allergiesListError,
    allergiesListReceived,
    allergiesListRequesting
} from "../actions/Allergies.actions";
import {getAllergiesListRequest} from "../../service/allergies";

function* doGetAllergiesList(action: any): any {
    try {
        const allergiesList = yield select((state:IAppState) => state.allergies);

        // ask for list only once
        if (allergiesList.data && allergiesList.data.length>0) {
            return;
        }

        // signal requesting (set waiting=true... etc)
        yield put(allergiesListRequesting());
        const alResponse = yield call(getAllergiesListRequest);
        if (alResponse.error) {
            switch (alResponse.error_code) {
                default:
                    yield put(allergiesListError(`${alResponse.error_code}, ${alResponse.error_message}`));
                    return;
            }
        }

        yield put(allergiesListReceived(alResponse.data));
    } catch (error) {
        console.log("ALLERGIES LIST RETRIEVAL ERROR", error);
        const error_message = (error.message) ? error.message : error;
        yield put(allergiesListError(error_message));
    }
}

const AllergiesSagas = [
    takeLeading(ALLERGIES_LIST_REQUEST, doGetAllergiesList),
];

export default AllergiesSagas;
