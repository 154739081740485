import Login from "../pages/Login/Login";
import RememberPassword from "../pages/RememberPassword/RememberPassword";
import NotFound from "../pages/NotFound/NotFound";
import {Dashboard} from "../pages/Dashboard";

const routes = [
    {
        path: '/',
        exact: true,
        component: Login,
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/remember',
        component: RememberPassword,
    },
    {
        path: '/dashboard',
        component: Dashboard,
    },
    {
        component: NotFound,
    },
];

export default routes;
