import React, {useEffect, useMemo, useRef, useState} from 'react';

import './EditIngredient.scss';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {DataTable, DataTableRowEditParams} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {useDispatch, useSelector} from "react-redux";
import LoadingView from "../../../../../components/LoadingView/LoadingView";
import {ProgressSpinner} from "primereact/progressspinner";
import {Toast} from "primereact/toast";
import {ITDPFormValidations} from "../../../../../hooks/tdp-form/tdp-form-types";
import {
    ArrayValidation,
    PositiveNumberValidation,
    RequiredValidation
} from "../../../../../hooks/tdp-form/tdp-form-validations";
import useTDPForm from "../../../../../hooks/tdp-form/tdp-form";
import {
    AcidAlkalineValues, IDashboardIngredient,
    IIngredientTranslation, IIngredientUnitConversion,
    IngredientCategoriesValues, MeasureValues
} from "../../../../../service/models/ingredient";
import {ingredientsListAddItem, ingredientsListUpdateItem} from "../../../../../redux/actions/Ingredients.actions";
import {createIngredientRequest, getIngredientInfo, saveIngredientInfo} from "../../../../../service/ingredients";
import FlagsDropdown from "../../../../../components/FlagsDropdown/FlagsDropdown";
import {AvailableLanguages} from "../../../../../helpers/locales";
import {Dropdown} from "primereact/dropdown";
import {IAppState} from "../../../../../redux/reducers";
import {getFriendlyLabel} from "../../../../../helpers/forms";
import {MultiSelect} from "primereact/multiselect";
import {allergiesListRequest} from "../../../../../redux/actions/Allergies.actions";

const DEFAULT_TRANSLATION_STATE: IIngredientTranslation = {
    locale: '',
    name: '',
}

const DEFAULT_CONVERSION_STATE: IIngredientUnitConversion = {
    factor: 0, from: "", to: ""
}

function unitBodyTemplate(rowData: any, props: any) {
    const field = props?.field;
    if (rowData !== undefined && rowData[field]) {
        const item = MeasureValues.find(item => item.id === rowData[field]);
        if (item !== undefined) {
            return item.label;
        }
    }
    return '';
}

interface IIngredientForm extends IDashboardIngredient {
    conversions: IIngredientUnitConversion[],
    allergies: number[],
}

const formInitialValues: IIngredientForm = {
    acid_alkaline_forming: "",
    base_category: "",
    carbohydrate_starch: undefined,
    carbohydrate_sugar: undefined,
    cholesterol: undefined,
    glucemic_index: undefined,
    id: undefined,
    image: null,
    insoluble_fiber: undefined,
    iron: undefined,
    kcals: 0,
    main_alias: "",
    monounsaturated_fat: undefined,
    name: '',
    other_nutrients: undefined,
    polyunsaturated_fat: undefined,
    potassium: undefined,
    protein: undefined,
    saturated_fat: undefined,
    serving_size: 0,
    serving_unit: '',
    sodium: undefined,
    soluble_fiber: undefined,
    thumb: null,
    trans_fat: undefined,
    vitamin: undefined,
    vitamins: undefined,
    translations: [],
    conversions: [],
    allergies: [],
    preferred_display_unit: undefined,
}

const formValidations: ITDPFormValidations<IIngredientForm> = {
    acid_alkaline_forming: [RequiredValidation({errorText: 'La categorización de acido/alcalino es obligatoria'})],
    carbohydrate_starch: [],
    carbohydrate_sugar: [],
    cholesterol: [],
    glucemic_index: [],
    image: [],
    insoluble_fiber: [],
    iron: [],
    kcals: [PositiveNumberValidation({errorText: 'Las kilocalorias del servicio son obligatorias'})],
    main_alias: [],
    monounsaturated_fat: [],
    name: [],
    other_nutrients: [],
    polyunsaturated_fat: [],
    potassium: [],
    protein: [],
    saturated_fat: [],
    serving_size: [PositiveNumberValidation({errorText: 'La cantidad del servicio es obligatoria'})],
    serving_unit: [RequiredValidation({errorText: 'La medida del servicio es obligatoria'})],
    sodium: [],
    soluble_fiber: [],
    thumb: [],
    trans_fat: [],
    vitamin: [],
    vitamins: [],
    id: [],
    translations: [ArrayValidation({errorText: 'Al menos una traducción es obligatoria'})],
    conversions: [],
    allergies: [],
    base_category: [RequiredValidation({errorText: 'La categoría es obligatoria'})],
    preferred_display_unit: [],
};

interface EditIngredientProps {
    history: any;
}

function getLanguage(locale:string) {
    return AvailableLanguages.find(item => item.code === locale);
}

function getTranslationName(locale:string) {
    const item = AvailableLanguages.find(item => item.code === locale);
    return (item) ? item.name : '';
}

const EditIngredient: React.FC<EditIngredientProps> = (props) => {
    const dispatch = useDispatch();

    const ingredientsState = useSelector((state:IAppState) => state.ingredients);
    const allergiesState = useSelector((state:IAppState) => state.allergies);

    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [translationOriginalRow, setTranslationOriginalRow] = useState<IIngredientTranslation>(DEFAULT_TRANSLATION_STATE);
    const [conversionOriginalRow, setConversionOriginalRow] = useState<IIngredientUnitConversion>(DEFAULT_CONVERSION_STATE);

    const ingredientCategories = useMemo(() => {
        if (ingredientsState.categories?.data) {
            return ingredientsState.categories.data.map((item:string) => {
                return {
                    label: getFriendlyLabel(IngredientCategoriesValues, item),
                    value: item
                }
            });
        }
        return [];
    }, [ingredientsState.categories.data]);

    const onSubmit = async (submittedValues:IIngredientForm) => {
        const toastTitle = 'Error guardando ingrediente';

        // check translations
        const localeSeen = new Set();
        for (let i=0; i<submittedValues.translations.length; ++i) {
            const locale = submittedValues.translations[i]?.locale;
            if (!locale) {
                showToast(toastTitle, 'La información de la traducción número '+(i+1)+' no está completa', 'warn');
                return;
            }
            if (localeSeen.has(locale)) {
                const localeName = getTranslationName(locale);
                showToast(toastTitle, 'La información de la traducción para "'+(localeName ? localeName : locale)+'" está duplicada. Elimine una fila', 'warn');
                return;
            }
            localeSeen.add(locale);
        }

        // check conversions
        const conversionSeen = new Set();
        for (let i=0; i<submittedValues.conversions.length; ++i) {
            const errorString = 'La información de la conversión número '+(i+1)+' no está completa.';
            const from = submittedValues.conversions[i]?.from;
            if (!from) {
                showToast(toastTitle,  errorString+' Falta unidad origen.', 'warn');
                return;
            }
            const to = submittedValues.conversions[i]?.to;
            if (!to) {
                showToast(toastTitle,  errorString+' Falta unidad destino.', 'warn');
                return;
            }
            const factor = submittedValues.conversions[i]?.factor;
            if (!factor) {
                showToast(toastTitle,  errorString+' Falta unidad factor de conversión.', 'warn');
                return;
            }
            const conversionKey = from+'##'+to;
            if (conversionSeen.has(conversionKey)) {
                const fromName = getFriendlyLabel(MeasureValues, from);
                const toName = getFriendlyLabel(MeasureValues, to);
                showToast(toastTitle, 'La información de la conversión de '+fromName+' a '+toName+' está duplicada. Elimine una fila', 'warn');
                return;
            }
            conversionSeen.add(conversionKey);
        }

        const isNew = (submittedValues.id === undefined);
        const operation = (isNew) ? createIngredientRequest : saveIngredientInfo;

        setSaving(true);

        try {
            // @ts-ignore
            const saveIngredientResponse = await operation({
                ...submittedValues,
            });

            if (saveIngredientResponse.error) {
                setSaving(false);
                showToast(toastTitle, saveIngredientResponse.error_message, 'error');
                return;
            }

            if (isNew) {
                form.handleChange('id', saveIngredientResponse.data?.id);
                submittedValues.id = saveIngredientResponse.data?.id;
                dispatch(ingredientsListAddItem(submittedValues))
            } else {
                dispatch(ingredientsListUpdateItem(submittedValues));
            }

            setSaving(false);
            showToast('Guardar Ingrediente', 'OK!', 'success');
        } catch (err) {
            setSaving(false);
            showToast(toastTitle, err.message,'error');
        }
    }

    const form = useTDPForm(formInitialValues, formValidations, onSubmit);
    const [deleteTranslationState, setDeleteTranslationState] = useState({
        visible: false,
        index: -1,
        locale: '',
    });
    const [deleteConversionState, setDeleteConversionState] = useState({
        visible: false,
        index: -1,
        from: '',
        to: '',
    });

    useEffect(() => {
       dispatch(allergiesListRequest());

       // load ingredient info
       if (props.history.location.state) {
           const { id } = props.history.location.state;
           if (id !== undefined) {
               getIngredientInfo(id).then(res => {
                   setLoading(false);
                   if (res.error) {
                       return;
                   }
                   form.resetValues({...form.values, ...res.data});
               }).catch(err => {
                   setLoading(false);
               });
           } else {
               // new ingredient?
               setLoading(false);
           }
       }
        // eslint-disable-next-line
   }, [props.history.location.state]);

    const toastRef = useRef<Toast>(null);

    if (loading || allergiesState.waiting) {
        return <LoadingView />;
    }

    function onTranslationRowEditInit(ev: DataTableRowEditParams) {
        setTranslationOriginalRow({...form.values.translations[ev.index]});
    }

    function onTranslationRowEditCancel(ev: DataTableRowEditParams) {
        let translations = [...form.values.translations];
        translations[ev.index] = translationOriginalRow;
        setTranslationOriginalRow(DEFAULT_TRANSLATION_STATE);
        form.handleChange('translations', translations);
    }

    function onTranslationChangeValue(props: any, value: any) {
        let updatedTranslations = [...props.value];
        updatedTranslations[props.rowIndex][props.field] = value;
        form.handleChange('translations', updatedTranslations);
    }

    function translationNameEditor(props: any) {
        return <InputText type="text" className="w-100" value={props.rowData.name}
                          onChange={(e) => onTranslationChangeValue(props, e.currentTarget.value)}/>;
    }

    function translationLocaleEditor(props: any) {
        return (
            <FlagsDropdown value={getLanguage(props.rowData.locale)}
                           className="w-100 form-dropdown"
                           onChange={(e) => onTranslationChangeValue(props, e?.code)}
            />
        );
    }

    function localeBodyTemplate(rowData: any) {
        if (rowData !== undefined && rowData.locale) {
            return (
                <div className="language-item language-item-value">
                    <span className={`flag flag-${rowData.locale.toLowerCase()}`} />
                    <div>{getTranslationName(rowData.locale)}</div>
                </div>
            );
        }
        return '';
    }

    function actionTranslationBodyTemplate(rowData:any, props:any) {
        if (rowData?.isNew && !props.editing) {
            delete rowData.isNew;
            props.onRowEditInit({preventDefault: () => {}});
            return;
        }
        if (props.editing) {
            return (
                <>
                    <Button icon="pi pi-check" disabled={!rowData.locale || !rowData.name} className="p-button-rounded p-button-success mr-1" onClick={props.onRowEditSave}/>
                    <Button icon="pi pi-times" className="p-button-rounded p-button-warning" onClick={props.onRowEditCancel}/>
                </>
            );
        }
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={props.onRowEditInit}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
                    setDeleteTranslationState({
                        visible: true,
                        index: props.rowIndex,
                        locale: rowData.locale,
                    });
                }} />
            </>
        );
    }

    function deleteTranslation() {
        let newTranslations = form.values.translations;
        newTranslations.splice(deleteTranslationState.index, 1);
        form.handleChange('translations', newTranslations);
        setDeleteTranslationState({
            index: -1,
            locale: '',
            visible: false
        })
    }

    function hideDeleteTranslationDialog() {
        setDeleteTranslationState({...deleteTranslationState, visible: false});
    }

    const deleteTranslationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTranslationDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTranslation} />
        </>
    );

    function onConversionRowEditInit(ev: DataTableRowEditParams) {
        setConversionOriginalRow({...form.values.conversions[ev.index]});
    }

    function onConversionRowEditCancel(ev: DataTableRowEditParams) {
        let conversions = [...form.values.conversions];
        conversions[ev.index] = conversionOriginalRow;
        setConversionOriginalRow(DEFAULT_CONVERSION_STATE);
        form.handleChange('conversions', conversions);
    }

    function onConversionChangeValue(props: any, value: any) {
        let updatedConversions = [...props.value];
        updatedConversions[props.rowIndex][props.field] = value;
        form.handleChange('conversions', updatedConversions);
    }

    function conversionUnitEditor(props: any, field: string) {
        return (
            <Dropdown value={props.rowData[field]} options={MeasureValues} optionLabel="label" optionValue="id"
                      onChange={(e) => onConversionChangeValue(props, e.value)} style={{width: '100%'}}
                      placeholder="Escoge una unidad"
                      itemTemplate={(option) => {
                          return <span
                              className={`product-badge status-${option.id.toLowerCase()}`}>{option.label}</span>
                      }}/>
        );
    }

    function conversionFactorEditor(props: any) {
        return <InputText type="text" className="w-100" value={props.rowData.factor}
                          onChange={(e) => onConversionChangeValue(props, e.currentTarget.value)}/>;
    }

    function actionConversionBodyTemplate(rowData:any, props:any) {
        if (rowData?.isNew && !props.editing) {
            delete rowData.isNew;
            props.onRowEditInit({preventDefault: () => {}});
            return;
        }
        if (props.editing) {
            return (
                <>
                    <Button icon="pi pi-check" disabled={!rowData.to || !rowData.from || !rowData.factor} className="p-button-rounded p-button-success mr-1" onClick={props.onRowEditSave}/>
                    <Button icon="pi pi-times" className="p-button-rounded p-button-warning" onClick={props.onRowEditCancel}/>
                </>
            );
        }
        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={props.onRowEditInit}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
                    setDeleteConversionState({
                        visible: true,
                        index: props.rowIndex,
                        from: rowData.from,
                        to: rowData.to,
                    });
                }} />
            </>
        );
    }

    function deleteConversion() {
        let newConversions = form.values.conversions;
        newConversions.splice(deleteConversionState.index, 1);
        form.handleChange('conversions', newConversions);
        setDeleteConversionState({
            index: -1,
            from: '',
            to: '',
            visible: false
        })
    }

    function hideDeleteConversionDialog() {
        setDeleteConversionState({...deleteConversionState, visible: false});
    }

    const deleteConversionDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteConversionDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteConversion} />
        </>
    );

    function showToast(title:string, message:string, severity?: 'success' | 'info' | 'warn' | 'error') {
        if (toastRef.current) {
            toastRef.current.show({contentClassName: "", severity, summary: title, detail:message, life: 3000});
        } else {
            console.log(severity,":",title," - ",message);
        }
    }

    return (
        <div id="EditIngredient">
            <div className="container-fluid">
                <div className="col-xl-12 order-xl-1 m-3">
                    <Toast ref={toastRef} position="top-right" />
                    <div className="card bg-secondary shadow">
                        <div className="card-header bg-white border-0">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h3 className="mb-0">Ingrediente</h3>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="heading-small text-muted mb-4">Información básica</h6>
                            <div className="pl-lg-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-category">Categoría</label>
                                            <Dropdown id="input-category" value={form.values.base_category}
                                                      options={ingredientCategories} optionLabel="label"
                                                      optionValue="value"
                                                      onChange={(e) => form.handleChange('base_category', e.value)}
                                                      style={{width: '100%'}} placeholder="Escoge una categoría"
                                                      />
                                            {form.errors.base_category.error ?
                                                <small className="p-invalid">{form.errors.base_category.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="form-control-label" htmlFor="input-first-name">Alergias</label>
                                        <MultiSelect className="w-100" value={form.values.allergies} options={allergiesState.data || []}
                                                     optionLabel="name" optionValue="id"
                                                     onChange={(e) => form.handleChange('allergies', e.value)}
                                                     selectedItemsLabel="{0} elementos seleccionados"
                                                     placeholder="Alergias"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="form-control-label"
                                                   htmlFor="input-serving-unit">Unidad de medida preferida</label>
                                            <Dropdown id="input-preferred-unit"
                                                      value={form.values.preferred_display_unit}
                                                      options={MeasureValues} optionLabel="label"
                                                      optionValue="id"
                                                      showClear
                                                      onChange={(e) => {
                                                          const newVal = (e.value !== undefined) ? e.value : null;
                                                          form.handleChange('preferred_display_unit', newVal)
                                                      }}
                                                      style={{width: '100%'}} placeholder="Unidad preferida"
                                            />
                                            {form.errors.serving_unit.error ?
                                                <small className="p-invalid">{form.errors.serving_unit.errorText}</small>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h6 className="heading-small text-muted mb-4">Información Nutricional</h6>
                                <div className="pl-lg-4">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-serving-size">Cantidad Servicio</label>
                                                <InputText id="input-serving-size" className="form-control form-control-alternative"
                                                           placeholder="Serving Size" value={form.values.serving_size}
                                                           onChange={(e) =>
                                                               form.handleChange('serving_size', e.currentTarget.value)
                                                           }/>
                                                {form.errors.serving_size.error ?
                                                    <small className="p-invalid">{form.errors.serving_size.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-serving-unit">Medida Servicio</label>
                                                <Dropdown id="input-serving-unit"
                                                          value={form.values.serving_unit}
                                                          options={MeasureValues} optionLabel="label"
                                                          optionValue="id"
                                                          onChange={(e) => form.handleChange('serving_unit', e.value)}
                                                          style={{width: '100%'}} placeholder="Unidad de servicio"
                                                />
                                                {form.errors.serving_unit.error ?
                                                    <small className="p-invalid">{form.errors.serving_unit.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-kcals">KCals por Servicio</label>
                                                <InputText id="input-kcals" className="form-control form-control-alternative"
                                                           placeholder="KCals por Servicio" value={form.values.kcals}
                                                           onChange={(e) =>
                                                               form.handleChange('kcals', e.currentTarget.value)
                                                           }/>
                                                {form.errors.kcals.error ?
                                                    <small className="p-invalid">{form.errors.kcals.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-glucemic">Glucemic Index</label>
                                                <InputText id="input-glucemic" className="form-control form-control-alternative"
                                                           placeholder="Glucemic Index" value={form.values.glucemic_index}
                                                           onChange={(e) =>
                                                               form.handleChange('glucemic_index', e.currentTarget.value)
                                                           }/>
                                                {form.errors.glucemic_index?.error ?
                                                    <small className="p-invalid">{form.errors.glucemic_index?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-aaf">Acid Alkaline Forming</label>
                                                <Dropdown id="input-aaf" value={form.values.acid_alkaline_forming}
                                                          options={AcidAlkalineValues} optionLabel="label"
                                                          optionValue="id"
                                                          onChange={(e) => form.handleChange('acid_alkaline_forming', e.value)}
                                                          style={{width: '100%'}} placeholder="Escoge una etiqueta"
                                                />
                                                {form.errors.acid_alkaline_forming.error ?
                                                    <small className="p-invalid">{form.errors.acid_alkaline_forming.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-cholesterol">Colesterol (mg)</label>
                                                <InputText id="input-cholesterol" className="form-control form-control-alternative"
                                                           placeholder="Cholesterol" value={form.values.cholesterol}
                                                           onChange={(e) =>
                                                               form.handleChange('cholesterol', e.currentTarget.value)
                                                           }/>
                                                {form.errors.cholesterol?.error ?
                                                    <small className="p-invalid">{form.errors.cholesterol?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-chstarch">Carbohydrate Starch (g)</label>
                                                <InputText id="input-chstarch" className="form-control form-control-alternative"
                                                           placeholder="Carbohydrate Starch" value={form.values.carbohydrate_starch}
                                                           onChange={(e) =>
                                                               form.handleChange('carbohydrate_starch', e.currentTarget.value)
                                                           }/>
                                                {form.errors.carbohydrate_starch?.error ?
                                                    <small className="p-invalid">{form.errors.carbohydrate_starch?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-chsugar">Carbohydrate Sugar (g)</label>
                                                <InputText id="input-chsugar" className="form-control form-control-alternative"
                                                           placeholder="Carbohydrate Sugar" value={form.values.carbohydrate_sugar}
                                                           onChange={(e) =>
                                                               form.handleChange('carbohydrate_sugar', e.currentTarget.value)
                                                           }/>
                                                {form.errors.carbohydrate_sugar?.error ?
                                                    <small className="p-invalid">{form.errors.carbohydrate_sugar?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-soluble-fiber">Soluble Fiber (g)</label>
                                                <InputText id="input-soluble-fiber" className="form-control form-control-alternative"
                                                           placeholder="Soluble Fiber" value={form.values.soluble_fiber}
                                                           onChange={(e) =>
                                                               form.handleChange('soluble_fiber', e.currentTarget.value)
                                                           }/>
                                                {form.errors.soluble_fiber?.error ?
                                                    <small className="p-invalid">{form.errors.soluble_fiber?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-insoluble-fiber">Insoluble Fiber (g)</label>
                                                <InputText id="input-insoluble-fiber" className="form-control form-control-alternative"
                                                           placeholder="Insoluble Fiber" value={form.values.insoluble_fiber}
                                                           onChange={(e) =>
                                                               form.handleChange('insoluble_fiber', e.currentTarget.value)
                                                           }/>
                                                {form.errors.insoluble_fiber?.error ?
                                                    <small className="p-invalid">{form.errors.insoluble_fiber?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-mono-fat">Monounsaturated Fat (g)</label>
                                                <InputText id="input-mono-fat" className="form-control form-control-alternative"
                                                           placeholder="Monounsaturated fat" value={form.values.monounsaturated_fat}
                                                           onChange={(e) =>
                                                               form.handleChange('monounsaturated_fat', e.currentTarget.value)
                                                           }/>
                                                {form.errors.monounsaturated_fat?.error ?
                                                    <small className="p-invalid">{form.errors.monounsaturated_fat?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-poly-fat">Polyunsaturated Fat (g)</label>
                                                <InputText id="input-poly-fat" className="form-control form-control-alternative"
                                                           placeholder="Polyunsaturated Fat" value={form.values.polyunsaturated_fat}
                                                           onChange={(e) =>
                                                               form.handleChange('polyunsaturated_fat', e.currentTarget.value)
                                                           }/>
                                                {form.errors.polyunsaturated_fat?.error ?
                                                    <small className="p-invalid">{form.errors.polyunsaturated_fat?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-saturated-fat">Saturated Fat (g)</label>
                                                <InputText id="input-saturated-fat" className="form-control form-control-alternative"
                                                           placeholder="Saturated fat" value={form.values.saturated_fat}
                                                           onChange={(e) =>
                                                               form.handleChange('saturated_fat', e.currentTarget.value)
                                                           }/>
                                                {form.errors.saturated_fat?.error ?
                                                    <small className="p-invalid">{form.errors.saturated_fat?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-trans-fat">Trans Fat (g)</label>
                                                <InputText id="input-trans-fat" className="form-control form-control-alternative"
                                                           placeholder="Trans Fat" value={form.values.trans_fat}
                                                           onChange={(e) =>
                                                               form.handleChange('trans_fat', e.currentTarget.value)
                                                           }/>
                                                {form.errors.trans_fat?.error ?
                                                    <small className="p-invalid">{form.errors.trans_fat?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-proteins">Proteinas (g)</label>
                                                <InputText id="input-proteins" className="form-control form-control-alternative"
                                                           placeholder="Proteinas" value={form.values.protein}
                                                           onChange={(e) =>
                                                               form.handleChange('protein', e.currentTarget.value)
                                                           }/>
                                                {form.errors.protein?.error ?
                                                    <small className="p-invalid">{form.errors.protein?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-iron">Hierro (mg)</label>
                                                <InputText id="input-iron" className="form-control form-control-alternative"
                                                           placeholder="Hierro" value={form.values.iron}
                                                           onChange={(e) =>
                                                               form.handleChange('iron', e.currentTarget.value)
                                                           }/>
                                                {form.errors.iron?.error ?
                                                    <small className="p-invalid">{form.errors.iron?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-sodium">Sodio (mg)</label>
                                                <InputText id="input-sodium" className="form-control form-control-alternative"
                                                           placeholder="Sodio" value={form.values.sodium}
                                                           onChange={(e) =>
                                                               form.handleChange('sodium', e.currentTarget.value)
                                                           }/>
                                                {form.errors.sodium?.error ?
                                                    <small className="p-invalid">{form.errors.sodium?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-potassium">Potasio (mg)</label>
                                                <InputText id="input-potassium" className="form-control form-control-alternative"
                                                           placeholder="Potasio" value={form.values.potassium}
                                                           onChange={(e) =>
                                                               form.handleChange('potassium', e.currentTarget.value)
                                                           }/>
                                                {form.errors.potassium?.error ?
                                                    <small className="p-invalid">{form.errors.potassium?.errorText}</small>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h6 className="heading-small text-muted mb-4">Traducciones</h6>
                                <DataTable value={form.values.translations} editMode="row" dataKey="locale"
                                           onRowEditInit={onTranslationRowEditInit} onRowEditCancel={onTranslationRowEditCancel}>
                                    <Column field="locale" header="Idioma" style={{width:'25%'}} body={localeBodyTemplate} editor={(props) => translationLocaleEditor(props)}/>
                                    <Column field="name" header="Nombre" editor={(props) => translationNameEditor(props)} style={{width:'65%'}}/>
                                    <Column body={actionTranslationBodyTemplate} headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}} style={{width:'10%'}}/>
                                </DataTable>

                                {form.errors.translations.error ?
                                    <small className="p-invalid">{form.errors.translations.errorText}</small>
                                    : null
                                }

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center mt-3">
                                            <Button label="Añadir traducción"
                                                    className="p-button-warning p-button-raised p-button-rounded"
                                                    onClick={() =>
                                                        form.handleChange('translations', [...form.values.translations, {locale: undefined, name:'', isNew: true}])
                                                    }/>
                                        </div>
                                    </div>
                                </div>

                                <Dialog visible={deleteTranslationState.visible} style={{ width: '450px' }} header="Confirmar" modal footer={deleteTranslationDialogFooter} onHide={hideDeleteTranslationDialog}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                                        {deleteTranslationState.locale && <span>¿Estás seguro de borrar la traducción para <b>{deleteTranslationState.locale}</b>?</span>}
                                        {!deleteTranslationState.locale && <span>¿Estás seguro de borrar esta fila?</span>}
                                    </div>
                                </Dialog>
                            </div>
                            <div className="mt-4">
                                <h6 className="heading-small text-muted mb-4">Conversion de Unidades</h6>
                                <DataTable value={form.values.conversions} editMode="row" dataKey="locale"
                                           onRowEditInit={onConversionRowEditInit} onRowEditCancel={onConversionRowEditCancel}>
                                    <Column field="from" header="Unidad Origen" style={{width:'35%'}} body={unitBodyTemplate} editor={(props) => conversionUnitEditor(props, 'from')}/>
                                    <Column field="to" header="Unidad Destino" style={{width:'35%'}} body={unitBodyTemplate} editor={(props) => conversionUnitEditor(props, 'to')}/>
                                    <Column field="factor" header="Factor de Conversión" editor={(props) => conversionFactorEditor(props)} style={{width:'20%'}}/>
                                    <Column body={actionConversionBodyTemplate} headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}} style={{width:'10%'}}/>
                                </DataTable>

                                {form.errors.conversions.error ?
                                    <small className="p-invalid">{form.errors.conversions.errorText}</small>
                                    : null
                                }

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center mt-3">
                                            <Button label="Añadir conversión"
                                                    className="p-button-warning p-button-raised p-button-rounded"
                                                    onClick={() =>
                                                        form.handleChange('conversions', [...form.values.conversions, {factor: undefined, to: undefined, from: undefined, isNew: true}])
                                                    }/>
                                        </div>
                                    </div>
                                </div>

                                <Dialog visible={deleteConversionState.visible} style={{ width: '450px' }} header="Confirmar" modal footer={deleteConversionDialogFooter} onHide={hideDeleteConversionDialog}>
                                    <div className="confirmation-content">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                                        {deleteConversionState.to && <span>¿Estás seguro de borrar la conversión para <b>{getFriendlyLabel(MeasureValues, deleteConversionState.to)}</b>?</span>}
                                        {!deleteConversionState.to && <span>¿Estás seguro de borrar esta fila?</span>}
                                    </div>
                                </Dialog>
                            </div>
                            <div className="my-3">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-right">
                                            <Button label="Guardar ingrediente" className="p-button-raised p-button-rounded"
                                                disabled={saving} onClick={form.handleSubmit}>
                                                {saving && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '5px'}}/>}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditIngredient;
