export const NUTRITIONISTS_LIST_REQUEST = 'NUTRITIONISTS_LIST_REQUEST';
export function nutritionistsListRequest() {
    return {
        type: NUTRITIONISTS_LIST_REQUEST,
    };
}

export const NUTRITIONISTS_LIST_RECEIVED = 'NUTRITIONISTS_LIST_RECEIVED';
export function nutritionistsListReceived(payload:any) {
    return {
        type: NUTRITIONISTS_LIST_RECEIVED,
        payload,
    };
}

export const NUTRITIONISTS_LIST_ERROR = 'NUTRITIONISTS_LIST_ERROR';
export function nutritionistsListError(error:string) {
    return {
        type: NUTRITIONISTS_LIST_ERROR,
        payload: error
    };
}
