import {IIngredient} from "../../service/models/ingredient";

/**
 * Ingredients List retrieval
 */

export const INGREDIENTS_LIST_REQUEST = 'INGREDIENTS_LIST_REQUEST';
export function ingredientsListRequest() {
    return { type: INGREDIENTS_LIST_REQUEST };
}

export const INGREDIENTS_LIST_REQUESTING = 'INGREDIENTS_LIST_REQUESTING';
export function ingredientsListRequesting() {
    return { type: INGREDIENTS_LIST_REQUEST };
}

export const INGREDIENTS_LIST_RECEIVED = 'INGREDIENTS_LIST_RECEIVED';
export function ingredientsListReceived(ingredients: any) {
    return { type: INGREDIENTS_LIST_RECEIVED, payload: ingredients };
}

export const INGREDIENTS_LIST_ERROR = 'INGREDIENTS_LIST_ERROR';
export function ingredientsListError(error: string) {
    return { type: INGREDIENTS_LIST_ERROR, payload: error };
}

// Add Ingredient to list directly
export const INGREDIENTS_LIST_ADD_ITEM = 'INGREDIENTS_LIST_ADD_ITEM';
export function ingredientsListAddItem(payload:IIngredient) {
    return {
        type: INGREDIENTS_LIST_ADD_ITEM,
        payload
    }
}

// Update Ingredient in List
export const INGREDIENTS_LIST_UPDATE_ITEM = 'INGREDIENTS_LIST_UPDATE_ITEM';
export function ingredientsListUpdateItem(payload:IIngredient) {
    return {
        type: INGREDIENTS_LIST_UPDATE_ITEM,
        payload
    }
}

// Delete Ingredient from List
export const INGREDIENTS_LIST_REMOVE_ITEM = 'INGREDIENTS_LIST_REMOVE_ITEM';
export function ingredientsListRemoveItem(ingredient_id:number) {
    return {
        type: INGREDIENTS_LIST_REMOVE_ITEM,
        payload: ingredient_id
    }
}

export const INGREDIENTS_CATEGORIES_LIST_REQUEST = 'INGREDIENTS_CATEGORIES_LIST_REQUEST';
export function ingredientsCategoriesListRequest() {
    return { type: INGREDIENTS_CATEGORIES_LIST_REQUEST };
}

export const INGREDIENTS_CATEGORIES_LIST_REQUESTING = 'INGREDIENTS_CATEGORIES_LIST_REQUESTING';
export function ingredientsCategoriesListRequesting() {
    return { type: INGREDIENTS_CATEGORIES_LIST_REQUEST };
}

export const INGREDIENTS_CATEGORIES_LIST_RECEIVED = 'INGREDIENTS_CATEGORIES_LIST_RECEIVED';
export function ingredientsCategoriesListReceived(ingredients: any) {
    return { type: INGREDIENTS_CATEGORIES_LIST_RECEIVED, payload: ingredients };
}

export const INGREDIENTS_CATEGORIES_LIST_ERROR = 'INGREDIENTS_CATEGORIES_LIST_ERROR';
export function ingredientsCategoriesListError(error: string) {
    return { type: INGREDIENTS_CATEGORIES_LIST_ERROR, payload: error };
}
